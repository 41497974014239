export const usPorts = [
  {
    portCode: "0101",
    city: "Portland, Maine",
    coordinates: {
      lat: 43.6615,
      lng: -70.2553,
    },
  },
  {
    portCode: "0102",
    city: "Bangor, Maine",
    coordinates: {
      lat: 44.8012,
      lng: -68.7778,
    },
  },
  {
    portCode: "0103",
    city: "Eastport, Maine",
    coordinates: {
      lat: 44.9068,
      lng: -66.9895,
    },
  },
  {
    portCode: "0104",
    city: "Jackman, Maine",
    coordinates: {
      lat: 45.6239,
      lng: -70.2562,
    },
  },
  {
    portCode: "0105",
    city: "Vanceboro, Maine",
    coordinates: {
      lat: 45.5542,
      lng: -67.4294,
    },
  },
  {
    portCode: "0106",
    city: "Houlton, Maine",
    coordinates: {
      lat: 46.1265,
      lng: -67.8406,
    },
  },
  {
    portCode: "0107",
    city: "Fort Fairfield, Maine",
    coordinates: {
      lat: 46.7717,
      lng: -67.8343,
    },
  },
  {
    portCode: "0108",
    city: "Van Buren, Maine",
    coordinates: {
      lat: 47.1543,
      lng: -67.9431,
    },
  },
  {
    portCode: "0109",
    city: "Madawaska, Maine",
    coordinates: {
      lat: 47.3542,
      lng: -68.3217,
    },
  },
  {
    portCode: "0110",
    city: "Fort Kent, Maine",
    coordinates: {
      lat: 47.2587,
      lng: -68.5887,
    },
  },
  {
    portCode: "0111",
    city: "Bath, Maine",
    coordinates: {
      lat: 43.9125,
      lng: -69.8142,
    },
  },
  {
    portCode: "0112",
    city: "Bar Harbor, Maine",
    coordinates: {
      lat: 44.3876,
      lng: -68.2039,
    },
  },
  {
    portCode: "0114",
    city: "Manchester, New Hampshire",
    coordinates: {
      lat: 42.9956,
      lng: -71.4548,
    },
  },
  {
    portCode: "0115",
    city: "Calais, Maine",
    coordinates: {
      lat: 45.1895,
      lng: -67.2787,
    },
  },
  {
    portCode: "0118",
    city: "Limestone, Maine",
    coordinates: {
      lat: 46.9126,
      lng: -67.8255,
    },
  },
  {
    portCode: "0121",
    city: "Rockland, Maine",
    coordinates: {
      lat: 44.1037,
      lng: -69.1089,
    },
  },
  {
    portCode: "0122",
    city: "Jonesport, Maine",
    coordinates: {
      lat: 44.5303,
      lng: -67.5991,
    },
  },
  {
    portCode: "0127",
    city: "Bridgewater, Maine",
    coordinates: {
      lat: 46.4332,
      lng: -67.8442,
    },
  },
  {
    portCode: "0131",
    city: "Portsmouth, New Hampshire",
    coordinates: {
      lat: 43.0718,
      lng: -70.7626,
    },
  },
  {
    portCode: "0150",
    city: "Border Patrol Sector, Hodgdon, Maine",
    coordinates: {
      lat: 46.0548,
      lng: -67.8419,
    },
  },
  {
    portCode: "0151",
    city: "Houlton Border Patrol, Houlton, Maine",
    coordinates: {
      lat: 46.1265,
      lng: -67.8406,
    },
  },
  {
    portCode: "0152",
    city: "Searsport, Maine",
    coordinates: {
      lat: 44.4584,
      lng: -68.9167,
    },
  },
  {
    portCode: "0153",
    city: "Jackman Border Patrol, Jackman, Maine",
    coordinates: {
      lat: 45.6239,
      lng: -70.2562,
    },
  },
  {
    portCode: "0154",
    city: "Rangeley Border Patrol, Rangeley, Maine",
    coordinates: {
      lat: 44.9667,
      lng: -70.645,
    },
  },
  {
    portCode: "0155",
    city: "Van Buren Border Patrol, Van Buren, Maine",
    coordinates: {
      lat: 47.1543,
      lng: -67.9431,
    },
  },
  {
    portCode: "0156",
    city: "Fort Fairfield Border Patrol, Fort Fairfield, Maine",
    coordinates: {
      lat: 46.7717,
      lng: -67.8343,
    },
  },
  {
    portCode: "0157",
    city: "Calais Border Patrol, Calais, Maine",
    coordinates: {
      lat: 45.1895,
      lng: -67.2787,
    },
  },
  {
    portCode: "0182",
    city: "Manchester User Fee Airport, New Hampshire",
    coordinates: {
      lat: 42.9326,
      lng: -71.4357,
    },
  },
  {
    portCode: "0201",
    city: "St. Albans, Vermont",
    coordinates: {
      lat: 44.8104,
      lng: -73.0833,
    },
  },
  {
    portCode: "0203",
    city: "Richford, Vermont",
    coordinates: {
      lat: 44.9959,
      lng: -72.6701,
    },
  },
  {
    portCode: "0206",
    city: "Beecher Falls, Vermont",
    coordinates: {
      lat: 44.9986,
      lng: -71.5034,
    },
  },
  {
    portCode: "0207",
    city: "Burlington, Vermont",
    coordinates: {
      lat: 44.4759,
      lng: -73.2121,
    },
  },
  {
    portCode: "0209",
    city: "Derby Line, Vermont",
    coordinates: {
      lat: 45.0051,
      lng: -72.0968,
    },
  },
  {
    portCode: "0210",
    city: "Newport, Vermont",
    coordinates: {
      lat: 44.9364,
      lng: -72.2054,
    },
  },
  {
    portCode: "0211",
    city: "Norton, Vermont",
    coordinates: {
      lat: 44.9717,
      lng: -71.7973,
    },
  },
  {
    portCode: "0212",
    city: "Highgate Springs/Alburg, Vermont",
    coordinates: {
      lat: 44.9914,
      lng: -73.0924,
    },
  },
  {
    portCode: "0214",
    city: "Swanton, Vermont",
    coordinates: {
      lat: 44.9167,
      lng: -73.1265,
    },
  },
  {
    portCode: "0221",
    city: "Northeast Region, Boston, Massachusetts",
    coordinates: {
      lat: 42.3601,
      lng: -71.0589,
    },
  },
  {
    portCode: "0250",
    city: "Border Patrol Sector, Swanton, Vermont",
    coordinates: {
      lat: 44.9167,
      lng: -73.1265,
    },
  },
  {
    portCode: "0251",
    city: "Swanton Border Patrol, Swanton, Vermont",
    coordinates: {
      lat: 44.9167,
      lng: -73.1265,
    },
  },
  {
    portCode: "0252",
    city: "Richford Border Patrol, Richford, Vermont",
    coordinates: {
      lat: 44.9959,
      lng: -72.6701,
    },
  },
  {
    portCode: "0253",
    city: "Newport Vermont Border Patrol, Newport, Vermont",
    coordinates: {
      lat: 44.9364,
      lng: -72.2054,
    },
  },
  {
    portCode: "0254",
    city: "Beecher Falls Border Patrol, Beecher Falls, Vermont",
    coordinates: {
      lat: 44.9986,
      lng: -71.5034,
    },
  },
  {
    portCode: "0255",
    city: "Champlain Border Patrol, Champlain, New York",
    coordinates: {
      lat: 44.9906,
      lng: -73.4462,
    },
  },
  {
    portCode: "0256",
    city: "Ogdensburg Border Patrol, Ogdensburg, New York",
    coordinates: {
      lat: 44.6948,
      lng: -75.4866,
    },
  },
  {
    portCode: "0257",
    city: "Massena Border Patrol, Massena, New York",
    coordinates: {
      lat: 44.9282,
      lng: -74.891,
    },
  },
  {
    portCode: "0258",
    city: "Burke New York Border Patrol, Burke, New York",
    coordinates: {
      lat: 44.8965,
      lng: -74.1704,
    },
  },
  {
    portCode: "0401",
    city: "Boston, Massachusetts",
    coordinates: {
      lat: 42.3601,
      lng: -71.0589,
    },
  },
  {
    portCode: "0402",
    city: "Springfield, Massachusetts",
    coordinates: {
      lat: 42.1015,
      lng: -72.5898,
    },
  },
  {
    portCode: "0403",
    city: "Worcester, Massachusetts",
    coordinates: {
      lat: 42.2626,
      lng: -71.8023,
    },
  },
  {
    portCode: "0404",
    city: "Gloucester, Massachusetts",
    coordinates: {
      lat: 42.6159,
      lng: -70.661,
    },
  },
  {
    portCode: "0405",
    city: "New Bedford, Massachusetts",
    coordinates: {
      lat: 41.6362,
      lng: -70.9342,
    },
  },
  {
    portCode: "0406",
    city: "Plymouth, Massachusetts",
    coordinates: {
      lat: 41.9584,
      lng: -70.6673,
    },
  },
  {
    portCode: "0407",
    city: "Fall River, Massachusetts",
    coordinates: {
      lat: 41.7015,
      lng: -71.155,
    },
  },
  {
    portCode: "0408",
    city: "Beverly, Massachusetts",
    coordinates: {
      lat: 42.5584,
      lng: -70.88,
    },
  },
  {
    portCode: "0410",
    city: "Bridgeport, Connecticut",
    coordinates: {
      lat: 41.1865,
      lng: -73.1952,
    },
  },
  {
    portCode: "0411",
    city: "Hartford, Connecticut",
    coordinates: {
      lat: 41.7658,
      lng: -72.6734,
    },
  },
  {
    portCode: "0412",
    city: "New Haven, Connecticut",
    coordinates: {
      lat: 41.3083,
      lng: -72.9279,
    },
  },
  {
    portCode: "0413",
    city: "New London, Connecticut",
    coordinates: {
      lat: 41.3557,
      lng: -72.0995,
    },
  },
  {
    portCode: "0416",
    city: "Lawrence, Massachusetts",
    coordinates: {
      lat: 42.707,
      lng: -71.1631,
    },
  },
  {
    portCode: "0417",
    city: "Logan Airport, East Boston, Massachusetts",
    coordinates: {
      lat: 42.3656,
      lng: -71.0096,
    },
  },
  {
    portCode: "0481",
    city: "Hanscom User Fee Airport, Bedford, Massachusetts",
    coordinates: {
      lat: 42.461,
      lng: -71.2883,
    },
  },
  {
    portCode: "0501",
    city: "Newport, Rhode Island",
    coordinates: {
      lat: 41.4901,
      lng: -71.3128,
    },
  },
  {
    portCode: "0502",
    city: "Providence, Rhode Island",
    coordinates: {
      lat: 41.8239,
      lng: -71.4128,
    },
  },
  {
    portCode: "0503",
    city: "Mellville, Rhode Island",
    coordinates: {
      lat: 41.5379,
      lng: -71.3134,
    },
  },
  {
    portCode: "0701",
    city: "Ogdensburg, New York",
    coordinates: {
      lat: 44.6948,
      lng: -75.4866,
    },
  },
  {
    portCode: "0704",
    city: "Massena, New York",
    coordinates: {
      lat: 44.9282,
      lng: -74.891,
    },
  },
  {
    portCode: "0706",
    city: "Cape Vincent, New York",
    coordinates: {
      lat: 44.1304,
      lng: -76.3349,
    },
  },
  {
    portCode: "0708",
    city: "Alexandria Bay, New York",
    coordinates: {
      lat: 44.335,
      lng: -75.9224,
    },
  },
  {
    portCode: "0709",
    city: "Burke, New York",
    coordinates: {
      lat: 44.8965,
      lng: -74.1704,
    },
  },
  {
    portCode: "0712",
    city: "Champlain-Rouses Point, New York",
    coordinates: {
      lat: 44.9906,
      lng: -73.4462,
    },
  },
  {
    portCode: "0714",
    city: "Clayton, New York",
    coordinates: {
      lat: 44.2337,
      lng: -76.0869,
    },
  },
  {
    portCode: "0715",
    city: "Trout River, New York",
    coordinates: {
      lat: 44.9901,
      lng: -74.4972,
    },
  },
  {
    portCode: "0781",
    city: "Plattsburgh International Airport, Plattsburgh, New York",
    coordinates: {
      lat: 44.6508,
      lng: -73.4683,
    },
  },
  {
    portCode: "0799",
    city: "Champlain-Cartage Cont, Champlain, New York",
    coordinates: {
      lat: 44.9906,
      lng: -73.4462,
    },
  },
  {
    portCode: "0901",
    city: "Buffalo-Niagara Falls, New York",
    coordinates: {
      lat: 43.0962,
      lng: -79.0377,
    },
  },
  {
    portCode: "0903",
    city: "Rochester, New York",
    coordinates: {
      lat: 43.1566,
      lng: -77.6088,
    },
  },
  {
    portCode: "0904",
    city: "Oswego, New York",
    coordinates: {
      lat: 43.4553,
      lng: -76.5105,
    },
  },
  {
    portCode: "0905",
    city: "Sodus Point, New York",
    coordinates: {
      lat: 43.2728,
      lng: -76.9916,
    },
  },
  {
    portCode: "0906",
    city: "Syracuse, New York",
    coordinates: {
      lat: 43.0481,
      lng: -76.1474,
    },
  },
  {
    portCode: "0921",
    city: "Northeast Region, Boston Massachusetts",
    coordinates: {
      lat: 42.3601,
      lng: -71.0589,
    },
  },
  {
    portCode: "0950",
    city: "Border Patrol Sector, Tonawanda, New York",
    coordinates: {
      lat: 43.0203,
      lng: -78.8803,
    },
  },
  {
    portCode: "0951",
    city: "Buffalo Border Patrol, Tonawanda, New York",
    coordinates: {
      lat: 43.0203,
      lng: -78.8803,
    },
  },
  {
    portCode: "0952",
    city: "Niagara Falls Border Patrol Station, Niagara Falls, New York",
    coordinates: {
      lat: 43.0962,
      lng: -79.0377,
    },
  },
  {
    portCode: "0953",
    city: "Watertown Border Patrol, Wellesley Island, New York",
    coordinates: {
      lat: 44.303,
      lng: -76.0401,
    },
  },

  {
    portCode: "0954",
    city: "Fulton New York Border Patrol, Fulton, New York",
    coordinates: {
      lat: 43.3223,
      lng: -76.4177,
    },
  },
  {
    portCode: "0981",
    city: "Binghamton Regional Airport, New York",
    coordinates: {
      lat: 42.2085,
      lng: -75.9796,
    },
  },
  {
    portCode: "0982",
    city: "Griffiss International User Fee Airport, Rome, New York",
    coordinates: {
      lat: 43.2333,
      lng: -75.4063,
    },
  },
  {
    portCode: "1101",
    city: "Philadelphia, Pennsylvania",
    coordinates: {
      lat: 39.9526,
      lng: -75.1652,
    },
  },
  {
    portCode: "1102",
    city: "Chester, Pennsylvania",
    coordinates: {
      lat: 39.8496,
      lng: -75.3557,
    },
  },
  {
    portCode: "1103",
    city: "Wilmington, Delaware",
    coordinates: {
      lat: 39.7391,
      lng: -75.5398,
    },
  },
  {
    portCode: "1104",
    city: "Pittsburgh, Pennsylvania",
    coordinates: {
      lat: 40.4406,
      lng: -79.9959,
    },
  },
  {
    portCode: "1106",
    city: "Wilkes-Barre/Scranton, Pennsylvania",
    coordinates: {
      lat: 41.2459,
      lng: -75.8813,
    },
  },
  {
    portCode: "1108",
    city: "Philadelphia International Airport, Philadelphia, Pennsylvania",
    coordinates: {
      lat: 39.8744,
      lng: -75.2424,
    },
  },
  {
    portCode: "1109",
    city: "Harrisburg, Pennsylvania",
    coordinates: {
      lat: 40.2737,
      lng: -76.8844,
    },
  },
  {
    portCode: "1110",
    city: "Cherry Hill, New Jersey",
    coordinates: {
      lat: 39.9268,
      lng: -75.0246,
    },
  },
  {
    portCode: "1119",
    city: "Allentown, Pennsylvania",
    coordinates: {
      lat: 40.6084,
      lng: -75.4902,
    },
  },
  {
    portCode: "1182",
    city: "Atlantic City User Fee Airport, New Jersey",
    coordinates: {
      lat: 39.4576,
      lng: -74.5772,
    },
  },
  {
    portCode: "1183",
    city: "Trenton/Mercer County User Fee Airport, New Jersey",
    coordinates: {
      lat: 40.276,
      lng: -74.8135,
    },
  },
  {
    portCode: "1195",
    city: "UPS Courier Hub Facility, Philadelphia, Pennsylvania",
    coordinates: {
      lat: 39.9526,
      lng: -75.1652,
    },
  },
  {
    portCode: "1301",
    city: "Annapolis, Maryland",
    coordinates: {
      lat: 38.9784,
      lng: -76.4922,
    },
  },
  {
    portCode: "1302",
    city: "Cambridge, Maryland",
    coordinates: {
      lat: 38.5632,
      lng: -76.0788,
    },
  },
  {
    portCode: "1303",
    city: "Baltimore, Maryland",
    coordinates: {
      lat: 39.2904,
      lng: -76.6122,
    },
  },
  {
    portCode: "1304",
    city: "Crisfield, Maryland",
    coordinates: {
      lat: 37.9835,
      lng: -75.8547,
    },
  },
  {
    portCode: "1305",
    city: "Baltimore-Washington International Airport, Baltimore, Maryland",
    coordinates: {
      lat: 39.1754,
      lng: -76.6684,
    },
  },
  {
    portCode: "1307",
    city: "Dover, Delaware",
    coordinates: {
      lat: 39.1582,
      lng: -75.5244,
    },
  },
  {
    portCode: "7100",
    city: "Northeast Region, Boston, Massachusetts",
    coordinates: {
      lat: 42.3601,
      lng: -71.0589,
    },
  },
  {
    portCode: "7121",
    city: "Montreal Quebec Canada, Montreal, Canada",
    coordinates: {
      lat: 45.5017,
      lng: -73.5673,
    },
  },
  {
    portCode: "7122",
    city: "Toronto Ontario Canada, Toronto, Canada",
    coordinates: {
      lat: 43.6532,
      lng: -79.3832,
    },
  },
  {
    portCode: "1001",
    city: "New York, New York",
    coordinates: {
      lat: 40.7128,
      lng: -74.006,
    },
  },
  {
    portCode: "1002",
    city: "Albany, New York",
    coordinates: {
      lat: 42.6526,
      lng: -73.7562,
    },
  },
  {
    portCode: "1005",
    city: "Long Island, New York",
    coordinates: {
      lat: 40.7891,
      lng: -73.135,
    },
  },
  {
    portCode: "1006",
    city: "Fishkill, New York",
    coordinates: {
      lat: 41.5357,
      lng: -73.8996,
    },
  },
  {
    portCode: "1007",
    city: "Napanoch, New York",
    coordinates: {
      lat: 41.7437,
      lng: -74.3774,
    },
  },
  {
    portCode: "4601",
    city: "New York/Newark Area, Newark, New Jersey",
    coordinates: {
      lat: 40.7357,
      lng: -74.1724,
    },
  },
  {
    portCode: "4602",
    city: "Perth Amboy, New Jersey",
    coordinates: {
      lat: 40.5068,
      lng: -74.2654,
    },
  },
  {
    portCode: "4670",
    city: "UPS ECCF, Newark, New Jersey",
    coordinates: {
      lat: 40.7357,
      lng: -74.1724,
    },
  },
  {
    portCode: "4671",
    city: "Federal Express ECCF, Newark, New Jersey",
    coordinates: {
      lat: 40.7357,
      lng: -74.1724,
    },
  },
  {
    portCode: "4681",
    city: "Morristown Airport, Morristown, New Jersey",
    coordinates: {
      lat: 40.799,
      lng: -74.414,
    },
  },
  {
    portCode: "4682",
    city: "Stewart International Airport UFF, New Windsor, New York",
    coordinates: {
      lat: 41.5041,
      lng: -74.1048,
    },
  },
  {
    portCode: "4701",
    city: "John F. Kennedy Airport, Jamaica, New York",
    coordinates: {
      lat: 40.6413,
      lng: -73.7781,
    },
  },
  {
    portCode: "4771",
    city: "NYACC ECCF, Jamaica, New York",
    coordinates: {
      lat: 40.6413,
      lng: -73.7781,
    },
  },
  {
    portCode: "4772",
    city: "DHL Airways, Jamaica, New York",
    coordinates: {
      lat: 40.6413,
      lng: -73.7781,
    },
  },
  {
    portCode: "4773",
    city: "Micom ECCF, Jamaica, New York",
    coordinates: {
      lat: 40.6413,
      lng: -73.7781,
    },
  },
  {
    portCode: "4774",
    city: "IBC ECCF, Jamaica, New York",
    coordinates: {
      lat: 40.6413,
      lng: -73.7781,
    },
  },
  {
    portCode: "4778",
    city: "Fedex Corp JFK ECCF, Jamaica, New York",
    coordinates: {
      lat: 40.6413,
      lng: -73.7781,
    },
  },
  {
    portCode: "3301",
    city: "Raymond, Montana",
    coordinates: {
      lat: 48.9985,
      lng: -104.5663,
    },
  },
  {
    portCode: "3302",
    city: "Eastport, Idaho",
    coordinates: {
      lat: 48.9999,
      lng: -116.1839,
    },
  },
  {
    portCode: "3303",
    city: "Salt Lake City, Utah",
    coordinates: {
      lat: 40.7608,
      lng: -111.891,
    },
  },
  {
    portCode: "3304",
    city: "Great Falls, Montana",
    coordinates: {
      lat: 47.5056,
      lng: -111.3008,
    },
  },
  {
    portCode: "3305",
    city: "Butte, Montana",
    coordinates: {
      lat: 46.0038,
      lng: -112.5348,
    },
  },
  {
    portCode: "3306",
    city: "Turner, Montana",
    coordinates: {
      lat: 48.8564,
      lng: -108.4001,
    },
  },
  {
    portCode: "3307",
    city: "Denver, Colorado",
    coordinates: {
      lat: 39.7392,
      lng: -104.9903,
    },
  },
  {
    portCode: "3308",
    city: "Porthill, Idaho",
    coordinates: {
      lat: 48.9988,
      lng: -116.4996,
    },
  },
  {
    portCode: "3309",
    city: "Scobey, Montana",
    coordinates: {
      lat: 48.7936,
      lng: -105.4253,
    },
  },
  {
    portCode: "3310",
    city: "Sweetgrass, Montana",
    coordinates: {
      lat: 48.9984,
      lng: -111.9703,
    },
  },
  {
    portCode: "3316",
    city: "Piegan, Montana",
    coordinates: {
      lat: 48.9988,
      lng: -113.3596,
    },
  },
  {
    portCode: "3317",
    city: "Opheim, Montana",
    coordinates: {
      lat: 48.8578,
      lng: -106.4137,
    },
  },
  {
    portCode: "3318",
    city: "Roosville, Montana",
    coordinates: {
      lat: 48.9979,
      lng: -114.0865,
    },
  },
  {
    portCode: "3319",
    city: "Morgan, Montana",
    coordinates: {
      lat: 48.9783,
      lng: -108.5466,
    },
  },
  {
    portCode: "3321",
    city: "Whitlash, Montana",
    coordinates: {
      lat: 48.957,
      lng: -111.3327,
    },
  },
  {
    portCode: "3322",
    city: "Del Bonita, Montana",
    coordinates: {
      lat: 48.9973,
      lng: -112.7403,
    },
  },
  {
    portCode: "3323",
    city: "Wildhorse, Montana",
    coordinates: {
      lat: 48.9998,
      lng: -110.4631,
    },
  },
  {
    portCode: "3324",
    city: "Kalispell Airport, Montana",
    coordinates: {
      lat: 48.3103,
      lng: -114.2551,
    },
  },
  {
    portCode: "3325",
    city: "Willow Creek, Montana",
    coordinates: {
      lat: 45.8396,
      lng: -111.6789,
    },
  },
  {
    portCode: "3326",
    city: "Billings, Montana",
    coordinates: {
      lat: 45.7833,
      lng: -108.5007,
    },
  },
  {
    portCode: "3327",
    city: "Vancouver, BC, Canada",
    coordinates: {
      lat: 49.2827,
      lng: -123.1207,
    },
  },
  {
    portCode: "3328",
    city: "Calgary, Alberta, Canada",
    coordinates: {
      lat: 51.0447,
      lng: -114.0719,
    },
  },
  {
    portCode: "3329",
    city: "Edmonton, Alberta, Canada",
    coordinates: {
      lat: 53.5461,
      lng: -113.4938,
    },
  },
  {
    portCode: "3348",
    city: "Helena, Montana",
    coordinates: {
      lat: 46.5884,
      lng: -112.0245,
    },
  },
  {
    portCode: "3350",
    city: "Border Patrol Sector HQ, Havre, Montana",
    coordinates: {
      lat: 48.5444,
      lng: -109.6841,
    },
  },
  {
    portCode: "3351",
    city: "Havre Montana Border Patrol, Havre, Montana",
    coordinates: {
      lat: 48.5444,
      lng: -109.6841,
    },
  },
  {
    portCode: "3352",
    city: "Malta Montana Border Patrol, Malta, Montana",
    coordinates: {
      lat: 48.3591,
      lng: -107.8745,
    },
  },
  {
    portCode: "3353",
    city: "Billings Border Patrol, Billings Montana",
    coordinates: {
      lat: 45.7833,
      lng: -108.5007,
    },
  },
  {
    portCode: "3354",
    city: "Twin Falls Border Patrol, Twin Falls, Idaho",
    coordinates: {
      lat: 42.5629,
      lng: -114.4609,
    },
  },
  {
    portCode: "3355",
    city: "Shelby Montana Border Patrol, Shelby, Montana",
    coordinates: {
      lat: 48.5053,
      lng: -111.8583,
    },
  },
  {
    portCode: "3356",
    city: "Sweetgrass Montana Border Patrol, Sweetgrass, Montana",
    coordinates: {
      lat: 48.9984,
      lng: -111.9703,
    },
  },
  {
    portCode: "3357",
    city: "St. Mary Montana Border Patrol, Babb, Montana",
    coordinates: {
      lat: 48.7827,
      lng: -113.4382,
    },
  },
  {
    portCode: "3358",
    city: "Plentywood Montana Border Patrol, Plentywood, Montana",
    coordinates: {
      lat: 48.7739,
      lng: -104.5583,
    },
  },
  {
    portCode: "3359",
    city: "Scobey Montana Border Patrol, Scobey, Montana",
    coordinates: {
      lat: 48.7936,
      lng: -105.4253,
    },
  },

  {
    portCode: "3381",
    city: "Missoula User Fee Airport, Missoula, Montana",
    coordinates: {
      lat: 46.9163,
      lng: -114.0911,
    },
  },
  {
    portCode: "3383",
    city: "Jeffco User Fee Airport, Broomfield, Colorado",
    coordinates: {
      lat: 39.9082,
      lng: -105.1162,
    },
  },
  {
    portCode: "3384",
    city: "Centennial Airport, Englewood, Colorado",
    coordinates: {
      lat: 39.571,
      lng: -104.8483,
    },
  },
  {
    portCode: "3385",
    city: "Eagle County Regional Airport, Gypsum, Colorado",
    coordinates: {
      lat: 39.6426,
      lng: -106.9177,
    },
  },
  {
    portCode: "3386",
    city: "Bozeman Yellowstone User Fee Airport, Belgrade, Montana",
    coordinates: {
      lat: 45.7776,
      lng: -111.16,
    },
  },
  {
    portCode: "3401",
    city: "Pembina, North Dakota",
    coordinates: {
      lat: 48.9669,
      lng: -97.2445,
    },
  },
  {
    portCode: "3403",
    city: "Portal, North Dakota",
    coordinates: {
      lat: 48.9962,
      lng: -102.5565,
    },
  },
  {
    portCode: "3404",
    city: "Neche, North Dakota",
    coordinates: {
      lat: 48.9825,
      lng: -97.5548,
    },
  },
  {
    portCode: "3405",
    city: "St. John, North Dakota",
    coordinates: {
      lat: 48.9428,
      lng: -99.6865,
    },
  },
  {
    portCode: "3406",
    city: "Northgate, North Dakota",
    coordinates: {
      lat: 48.999,
      lng: -102.4774,
    },
  },
  {
    portCode: "3407",
    city: "Walhalla, North Dakota",
    coordinates: {
      lat: 48.9253,
      lng: -97.9187,
    },
  },
  {
    portCode: "3408",
    city: "Hannah, North Dakota",
    coordinates: {
      lat: 48.9789,
      lng: -98.7015,
    },
  },
  {
    portCode: "3409",
    city: "Sarles, North Dakota",
    coordinates: {
      lat: 48.9453,
      lng: -98.9915,
    },
  },
  {
    portCode: "3410",
    city: "Ambrose, North Dakota",
    coordinates: {
      lat: 48.999,
      lng: -103.4696,
    },
  },
  {
    portCode: "3411",
    city: "Fargo International Airport, North Dakota",
    coordinates: {
      lat: 46.9207,
      lng: -96.8158,
    },
  },
  {
    portCode: "3412",
    city: "Fargo, Grand Forks, North Dakota",
    coordinates: {
      lat: 46.8772,
      lng: -96.7898,
    },
  },
  {
    portCode: "3413",
    city: "Antler, North Dakota",
    coordinates: {
      lat: 48.973,
      lng: -101.2781,
    },
  },
  {
    portCode: "3414",
    city: "Sherwood, North Dakota",
    coordinates: {
      lat: 48.9648,
      lng: -101.6304,
    },
  },
  {
    portCode: "3415",
    city: "Hansboro, North Dakota",
    coordinates: {
      lat: 48.9464,
      lng: -99.366,
    },
  },
  {
    portCode: "3416",
    city: "Maida, North Dakota",
    coordinates: {
      lat: 48.9925,
      lng: -98.3637,
    },
  },
  {
    portCode: "3417",
    city: "Fortuna, North Dakota",
    coordinates: {
      lat: 48.9722,
      lng: -103.7835,
    },
  },
  {
    portCode: "3419",
    city: "Westhope, North Dakota",
    coordinates: {
      lat: 48.9064,
      lng: -101.0148,
    },
  },
  {
    portCode: "3420",
    city: "Noonan, North Dakota",
    coordinates: {
      lat: 48.8897,
      lng: -103.0028,
    },
  },
  {
    portCode: "3421",
    city: "Carbury, North Dakota",
    coordinates: {
      lat: 48.8981,
      lng: -100.6115,
    },
  },
  {
    portCode: "3422",
    city: "Dunseith, North Dakota",
    coordinates: {
      lat: 48.8092,
      lng: -100.0615,
    },
  },
  {
    portCode: "3423",
    city: "Warroad, Minnesota",
    coordinates: {
      lat: 48.9052,
      lng: -95.3242,
    },
  },
  {
    portCode: "3424",
    city: "Baudette, Minnesota",
    coordinates: {
      lat: 48.7122,
      lng: -94.6028,
    },
  },
  {
    portCode: "3425",
    city: "Pinecreek, Minnesota",
    coordinates: {
      lat: 48.999,
      lng: -95.9783,
    },
  },
  {
    portCode: "3426",
    city: "Roseau, Minnesota",
    coordinates: {
      lat: 48.8469,
      lng: -95.7622,
    },
  },
  {
    portCode: "3427",
    city: "Grand Forks Airport, Grand Forks, North Dakota",
    coordinates: {
      lat: 47.9492,
      lng: -97.1761,
    },
  },
  {
    portCode: "3428",
    city: "Winnipeg International Airport, Winnipeg, Canada",
    coordinates: {
      lat: 49.8951,
      lng: -97.1384,
    },
  },
  {
    portCode: "3429",
    city: "Crane Lake, Minnesota",
    coordinates: {
      lat: 48.2677,
      lng: -92.4952,
    },
  },
  {
    portCode: "3430",
    city: "Lancaster, Minnesota",
    coordinates: {
      lat: 48.8597,
      lng: -96.8015,
    },
  },
  {
    portCode: "3431",
    city: "Minot, North Dakota",
    coordinates: {
      lat: 48.2325,
      lng: -101.2963,
    },
  },
  {
    portCode: "3432",
    city: "Grand Forks, North Dakota",
    coordinates: {
      lat: 47.9253,
      lng: -97.0329,
    },
  },
  {
    portCode: "3433",
    city: "Williston Airport, Williston, North Dakota",
    coordinates: {
      lat: 48.1776,
      lng: -103.6423,
    },
  },
  {
    portCode: "3434",
    city: "Minot Airport, Minot, North Dakota",
    coordinates: {
      lat: 48.2586,
      lng: -101.2752,
    },
  },
  {
    portCode: "3450",
    city: "Border Patrol Sector HQ, Grand Forks, North Dakota",
    coordinates: {
      lat: 47.9253,
      lng: -97.0329,
    },
  },
  {
    portCode: "3452",
    city: "Portal North Dakota Border Patrol, Portal, North Dakota",
    coordinates: {
      lat: 48.9962,
      lng: -102.5565,
    },
  },
  {
    portCode: "3453",
    city: "Bottineau Border Patrol, Bottineau, North Dakota",
    coordinates: {
      lat: 48.8289,
      lng: -100.4423,
    },
  },
  {
    portCode: "3454",
    city: "Pembina (Noyes) Border Patrol, Hallock, Minnesota",
    coordinates: {
      lat: 48.7747,
      lng: -96.9457,
    },
  },
  {
    portCode: "3455",
    city: "International Falls Border Patrol, International Falls, Minnesota",
    coordinates: {
      lat: 48.6023,
      lng: -93.4044,
    },
  },
  {
    portCode: "3456",
    city: "Warroad Border Patrol, Warroad, Minnesota",
    coordinates: {
      lat: 48.9052,
      lng: -95.3242,
    },
  },
  {
    portCode: "3457",
    city: "Duluth Border Patrol, Duluth, Minnesota",
    coordinates: {
      lat: 46.7867,
      lng: -92.1005,
    },
  },
  {
    portCode: "3458",
    city: "Grand Marais Border Patrol, Grand Marais, Minnesota",
    coordinates: {
      lat: 47.7505,
      lng: -90.3345,
    },
  },
  {
    portCode: "3499",
    city: "Pembina Cartage Control, Pembina, North Dakota",
    coordinates: {
      lat: 48.9669,
      lng: -97.2445,
    },
  },
  {
    portCode: "3501",
    city: "Minneapolis-St. Paul, Minnesota",
    coordinates: {
      lat: 44.9778,
      lng: -93.265,
    },
  },
  {
    portCode: "3502",
    city: "Sioux Falls, South Dakota",
    coordinates: {
      lat: 43.5446,
      lng: -96.7311,
    },
  },
  {
    portCode: "3503",
    city: "Rapid City, South Dakota",
    coordinates: {
      lat: 44.0805,
      lng: -103.231,
    },
  },
  {
    portCode: "3505",
    city: "Cedar Rapids, Iowa",
    coordinates: {
      lat: 41.9779,
      lng: -91.6656,
    },
  },
  {
    portCode: "3507",
    city: "Grand Island, Nebraska",
    coordinates: {
      lat: 40.9212,
      lng: -98.342,
    },
  },
  {
    portCode: "3508",
    city: "North Platte, Nebraska",
    coordinates: {
      lat: 41.1403,
      lng: -100.7601,
    },
  },
  {
    portCode: "3510",
    city: "Duluth, Wisconsin",
    coordinates: {
      lat: 46.7867,
      lng: -92.1005,
    },
  },
  {
    portCode: "3511",
    city: "Ashland, Wisconsin",
    coordinates: {
      lat: 46.5927,
      lng: -90.8835,
    },
  },
  {
    portCode: "3512",
    city: "Omaha, Nebraska",
    coordinates: {
      lat: 41.2565,
      lng: -95.9345,
    },
  },
  {
    portCode: "3513",
    city: "Des Moines, Iowa",
    coordinates: {
      lat: 41.5868,
      lng: -93.625,
    },
  },
  {
    portCode: "3581",
    city: "Rochester, Minnesota User Fee Airport",
    coordinates: {
      lat: 43.9083,
      lng: -92.5002,
    },
  },
  {
    portCode: "3604",
    city: "International Falls, Minnesota",
    coordinates: {
      lat: 48.6023,
      lng: -93.4044,
    },
  },
  {
    portCode: "3613",
    city: "Grand Portage, Minnesota",
    coordinates: {
      lat: 47.9717,
      lng: -89.6927,
    },
  },
  {
    portCode: "3701",
    city: "Milwaukee, Wisconsin",
    coordinates: {
      lat: 43.0389,
      lng: -87.9065,
    },
  },
  {
    portCode: "3702",
    city: "Marinette, Wisconsin",
    coordinates: {
      lat: 45.0991,
      lng: -87.6306,
    },
  },
  {
    portCode: "3703",
    city: "Green Bay, Wisconsin",
    coordinates: {
      lat: 44.5133,
      lng: -88.0133,
    },
  },
  {
    portCode: "3706",
    city: "Manitowoc, Wisconsin",
    coordinates: {
      lat: 44.0886,
      lng: -87.6576,
    },
  },
  {
    portCode: "3707",
    city: "Sheboygan, Wisconsin",
    coordinates: {
      lat: 43.7508,
      lng: -87.7145,
    },
  },
  {
    portCode: "3708",
    city: "Racine, Wisconsin",
    coordinates: {
      lat: 42.7261,
      lng: -87.7829,
    },
  },
  {
    portCode: "3781",
    city: "Appleton International Airport, Appleton, Wisconsin",
    coordinates: {
      lat: 44.2581,
      lng: -88.5191,
    },
  },
  {
    portCode: "3801",
    city: "Detroit, Michigan",
    coordinates: {
      lat: 42.3314,
      lng: -83.0458,
    },
  },
  {
    portCode: "3802",
    city: "Port Huron, Michigan",
    coordinates: {
      lat: 42.9709,
      lng: -82.4249,
    },
  },
  {
    portCode: "3803",
    city: "Sault Ste Marie, Michigan",
    coordinates: {
      lat: 46.4953,
      lng: -84.3453,
    },
  },
  {
    portCode: "3804",
    city: "Saginaw/Bay City, Michigan",
    coordinates: {
      lat: 43.4195,
      lng: -83.9508,
    },
  },
  {
    portCode: "3805",
    city: "Battle Creek, Michigan",
    coordinates: {
      lat: 42.3212,
      lng: -85.1797,
    },
  },
  {
    portCode: "3806",
    city: "Grand Rapids, Michigan",
    coordinates: {
      lat: 42.9634,
      lng: -85.6681,
    },
  },
  {
    portCode: "3807",
    city: "Detroit Metropolitan Airport, Michigan",
    coordinates: {
      lat: 42.2124,
      lng: -83.3534,
    },
  },
  {
    portCode: "3808",
    city: "Escanaba, Michigan",
    coordinates: {
      lat: 45.7458,
      lng: -87.0646,
    },
  },
  {
    portCode: "3809",
    city: "Marquette, Michigan",
    coordinates: {
      lat: 46.5476,
      lng: -87.3954,
    },
  },
  {
    portCode: "3814",
    city: "Algonac, Michigan",
    coordinates: {
      lat: 42.6189,
      lng: -82.5313,
    },
  },
  {
    portCode: "3815",
    city: "Muskegon, Michigan",
    coordinates: {
      lat: 43.2342,
      lng: -86.2484,
    },
  },
  {
    portCode: "3816",
    city: "Grand Haven, Michigan",
    coordinates: {
      lat: 43.0631,
      lng: -86.2284,
    },
  },
  {
    portCode: "3818",
    city: "Rogers City, Michigan",
    coordinates: {
      lat: 45.4217,
      lng: -83.8205,
    },
  },
  {
    portCode: "3819",
    city: "Detour City, Michigan",
    coordinates: {
      lat: 45.994,
      lng: -83.8998,
    },
  },
  {
    portCode: "3822",
    city: "South Haven, Michigan",
    coordinates: {
      lat: 42.4034,
      lng: -86.2739,
    },
  },
  {
    portCode: "3842",
    city: "Presque Island, Michigan",
    coordinates: {
      lat: 45.3164,
      lng: -83.4877,
    },
  },
  {
    portCode: "3843",
    city: "Alpena, Michigan",
    coordinates: {
      lat: 45.0617,
      lng: -83.4327,
    },
  },
  {
    portCode: "3850",
    city: "Border Patrol Sector HQ, Selfridge Angb, Michigan",
    coordinates: {
      lat: 42.6276,
      lng: -82.8207,
    },
  },
  {
    portCode: "3851",
    city: "Detroit Border Patrol, Detroit, Michigan",
    coordinates: {
      lat: 42.3314,
      lng: -83.0458,
    },
  },
  {
    portCode: "3852",
    city: "Sault Ste Marie Border Patrol, Sault Ste Marie, Michigan",
    coordinates: {
      lat: 46.4953,
      lng: -84.3453,
    },
  },
  {
    portCode: "3853",
    city: "Trenton Border Patrol, Trenton, Michigan",
    coordinates: {
      lat: 42.1392,
      lng: -83.1788,
    },
  },
  {
    portCode: "3854",
    city: "Port Huron Border Patrol, Marysville, Michigan",
    coordinates: {
      lat: 42.9709,
      lng: -82.4696,
    },
  },
  {
    portCode: "3855",
    city: "Grand Rapids Border Patrol, Holland, Michigan",
    coordinates: {
      lat: 42.7875,
      lng: -86.1089,
    },
  },
  {
    portCode: "3881",
    city: "Oakland County International Airport, Detroit, Michigan",
    coordinates: {
      lat: 42.6655,
      lng: -83.4201,
    },
  },
  {
    portCode: "3882",
    city: "Willow Run Airport, Ypsilanti, Michigan",
    coordinates: {
      lat: 42.2379,
      lng: -83.5304,
    },
  },
  {
    portCode: "3901",
    city: "Chicago, Illinois, including O'Hare International Airport",
    coordinates: {
      lat: 41.8781,
      lng: -87.6298,
    },
  },
  {
    portCode: "3902",
    city: "Peoria, Illinois",
    coordinates: {
      lat: 40.6936,
      lng: -89.589,
    },
  },
  {
    portCode: "3905",
    city: "Gary, Indiana",
    coordinates: {
      lat: 41.5934,
      lng: -87.3464,
    },
  },
  {
    portCode: "3908",
    city: "Davenport, Iowa - Rock Island, Milan, Illinois",
    coordinates: {
      lat: 41.5236,
      lng: -90.5776,
    },
  },
  {
    portCode: "3909",
    city: "Rockford Airport, Rockford, Illinois",
    coordinates: {
      lat: 42.2711,
      lng: -89.093,
    },
  },
  {
    portCode: "3910",
    city: "Midway International Airport, Chicago, Illinois",
    coordinates: {
      lat: 41.7868,
      lng: -87.7522,
    },
  },
  {
    portCode: "3911",
    city: "Springfield, Illinois",
    coordinates: {
      lat: 39.7817,
      lng: -89.6501,
    },
  },
  {
    portCode: "3971",
    city: "Fedex ECCF, Chicago, Illinois",
    coordinates: {
      lat: 41.8781,
      lng: -87.6298,
    },
  },
  {
    portCode: "3972",
    city: "IBC ECCF, Chicago, Illinois",
    coordinates: {
      lat: 41.8781,
      lng: -87.6298,
    },
  },
  {
    portCode: "3984",
    city: "Dupage Airport Authority, West Chicago, Illinois",
    coordinates: {
      lat: 41.8964,
      lng: -88.2087,
    },
  },
  {
    portCode: "3991",
    city: "DHL Chicago Hub, Chicago, Illinois",
    coordinates: {
      lat: 41.8781,
      lng: -87.6298,
    },
  },
  {
    portCode: "4101",
    city: "Cleveland, Ohio",
    coordinates: {
      lat: 41.4993,
      lng: -81.6944,
    },
  },
  {
    portCode: "4102",
    city: "Cincinnati, Ohio",
    coordinates: {
      lat: 39.1031,
      lng: -84.512,
    },
  },
  {
    portCode: "4103",
    city: "Columbus, Ohio",
    coordinates: {
      lat: 39.9612,
      lng: -82.9988,
    },
  },
  {
    portCode: "4104",
    city: "Dayton, Ohio",
    coordinates: {
      lat: 39.7589,
      lng: -84.1916,
    },
  },
  {
    portCode: "4105",
    city: "Toledo, Ohio",
    coordinates: {
      lat: 41.6639,
      lng: -83.5552,
    },
  },
  {
    portCode: "4106",
    city: "Erie, Pennsylvania",
    coordinates: {
      lat: 42.1292,
      lng: -80.0851,
    },
  },
  {
    portCode: "4107",
    city: "Northern Kentucky, Fort Mitchell, KY",
    coordinates: {
      lat: 39.047,
      lng: -84.5599,
    },
  },
  {
    portCode: "4110",
    city: "Indianapolis, Indiana",
    coordinates: {
      lat: 39.7684,
      lng: -86.1581,
    },
  },
  {
    portCode: "4115",
    city: "Louisville, Kentucky",
    coordinates: {
      lat: 38.2527,
      lng: -85.7585,
    },
  },
  {
    portCode: "4116",
    city: "Owensboro-Evansville, Indiana",
    coordinates: {
      lat: 37.7742,
      lng: -87.1133,
    },
  },
  {
    portCode: "4122",
    city: "Ashtabula/Conneaut, Ohio",
    coordinates: {
      lat: 41.8651,
      lng: -80.7649,
    },
  },
  {
    portCode: "4130",
    city: "London, Kentucky",
    coordinates: {
      lat: 37.128,
      lng: -84.0833,
    },
  },
  {
    portCode: "4131",
    city: "Bowling Green, Kentucky",
    coordinates: {
      lat: 36.9685,
      lng: -86.4808,
    },
  },
  {
    portCode: "4183",
    city: "Fort Wayne Airport, Fort Wayne, Indiana",
    coordinates: {
      lat: 40.9785,
      lng: -85.1951,
    },
  },
  {
    portCode: "4184",
    city: "Blue Grass Airport, Lexington, Kentucky",
    coordinates: {
      lat: 38.0365,
      lng: -84.6059,
    },
  },
  {
    portCode: "4196",
    city: "UPS Courier Hub, Louisville, Kentucky",
    coordinates: {
      lat: 38.2527,
      lng: -85.7585,
    },
  },
  {
    portCode: "4197",
    city: "DHL Courier Hub, Cincinnati, Ohio",
    coordinates: {
      lat: 39.1031,
      lng: -84.512,
    },
  },
  {
    portCode: "4198",
    city: "Federal Express Hub, Indianapolis, Indiana",
    coordinates: {
      lat: 39.7684,
      lng: -86.1581,
    },
  },
  {
    portCode: "4501",
    city: "Kansas City, Missouri",
    coordinates: {
      lat: 39.0997,
      lng: -94.5786,
    },
  },
  {
    portCode: "4502",
    city: "St. Joseph, Missouri",
    coordinates: {
      lat: 39.7675,
      lng: -94.8467,
    },
  },
  {
    portCode: "4503",
    city: "St. Louis, Missouri",
    coordinates: {
      lat: 38.627,
      lng: -90.1994,
    },
  },
  {
    portCode: "4504",
    city: "Wichita, Kansas",
    coordinates: {
      lat: 37.6872,
      lng: -97.3301,
    },
  },
  {
    portCode: "4505",
    city: "Springfield, Missouri",
    coordinates: {
      lat: 37.2089,
      lng: -93.2923,
    },
  },
  {
    portCode: "4507",
    city: "Kansas City Smart Port, Kansas City, Kansas",
    coordinates: {
      lat: 39.1142,
      lng: -94.6275,
    },
  },
  {
    portCode: "4581",
    city: "MidAmerica Airport, Mascoutah, Illinois",
    coordinates: {
      lat: 38.544,
      lng: -89.8357,
    },
  },
  {
    portCode: "7900",
    city: "CBP North Central Region, Chicago, Illinois",
    coordinates: {
      lat: 41.8781,
      lng: -87.6298,
    },
  },
  {
    portCode: "7922",
    city: "CBP Vancouver Preclearance, Washington, DC",
    coordinates: {
      lat: 49.2827,
      lng: -123.1207,
    },
  },
  {
    portCode: "7923",
    city: "CBP Calgary Preclearance, Washington, DC",
    coordinates: {
      lat: 51.0447,
      lng: -114.0719,
    },
  },
  {
    portCode: "7924",
    city: "CBP Edmonton Preclearance, Washington, DC",
    coordinates: {
      lat: 53.5461,
      lng: -113.4938,
    },
  },
  {
    portCode: "7925",
    city: "CBP Montreal Preclearance, Washington, DC",
    coordinates: {
      lat: 45.5017,
      lng: -73.5673,
    },
  },
  {
    portCode: "7926",
    city: "CBP Toronto Preclearance, Washington, DC",
    coordinates: {
      lat: 43.6532,
      lng: -79.3832,
    },
  },
  {
    portCode: "7928",
    city: "CBP Winnipeg Preclearance, Washington, DC",
    coordinates: {
      lat: 49.8951,
      lng: -97.1384,
    },
  },
  {
    portCode: "7929",
    city: "CBP Ottawa Preclearance, Washington, DC",
    coordinates: {
      lat: 45.4215,
      lng: -75.6972,
    },
  },
  {
    portCode: "7930",
    city: "CBP Victoria Preclearance, Washington, DC",
    coordinates: {
      lat: 48.4284,
      lng: -123.3656,
    },
  },
  {
    portCode: "7931",
    city: "CBP Halifax Nova Scotia Preclearance, Washington, DC",
    coordinates: {
      lat: 44.6488,
      lng: -63.5752,
    },
  },
  {
    portCode: "1401",
    city: "Norfolk, Virginia",
    coordinates: {
      lat: 36.8508,
      lng: -76.2859,
    },
  },
  {
    portCode: "1404",
    city: "Richmond-Petersburg, Virginia",
    coordinates: {
      lat: 37.5407,
      lng: -77.436,
    },
  },
  {
    portCode: "1405",
    city: "Harrisonburg, Virginia",
    coordinates: {
      lat: 38.4496,
      lng: -78.8689,
    },
  },
  {
    portCode: "1409",
    city: "Charleston, West Virginia",
    coordinates: {
      lat: 38.3498,
      lng: -81.6326,
    },
  },
  {
    portCode: "1410",
    city: "Front Royal, Virginia",
    coordinates: {
      lat: 38.9182,
      lng: -78.1944,
    },
  },
  {
    portCode: "1411",
    city: "Fraudulent Document Lab, McLean, Virginia",
    coordinates: {
      lat: 38.9339,
      lng: -77.1775,
    },
  },
  {
    portCode: "1501",
    city: "Wilmington, North Carolina",
    coordinates: {
      lat: 34.2257,
      lng: -77.9447,
    },
  },
  {
    portCode: "1502",
    city: "Winston-Salem, North Carolina",
    coordinates: {
      lat: 36.0999,
      lng: -80.2442,
    },
  },
  {
    portCode: "1503",
    city: "Durham, North Carolina",
    coordinates: {
      lat: 35.994,
      lng: -78.8986,
    },
  },
  {
    portCode: "1511",
    city: "Beaufort-Morehead City, North Carolina",
    coordinates: {
      lat: 34.7229,
      lng: -76.6523,
    },
  },
  {
    portCode: "1512",
    city: "Charlotte, North Carolina",
    coordinates: {
      lat: 35.2271,
      lng: -80.8431,
    },
  },
  {
    portCode: "1581",
    city: "Charlotte-Monroe User Fee Airport, Monroe, North Carolina",
    coordinates: {
      lat: 34.9804,
      lng: -80.5495,
    },
  },
  {
    portCode: "1601",
    city: "Charleston, South Carolina",
    coordinates: {
      lat: 32.7765,
      lng: -79.9311,
    },
  },
  {
    portCode: "1602",
    city: "Georgetown, South Carolina",
    coordinates: {
      lat: 33.3768,
      lng: -79.2945,
    },
  },
  {
    portCode: "1603",
    city: "Greenville-Spartanburg, South Carolina",
    coordinates: {
      lat: 34.8597,
      lng: -82.319,
    },
  },
  {
    portCode: "1604",
    city: "Columbia, South Carolina",
    coordinates: {
      lat: 34.0007,
      lng: -81.0348,
    },
  },
  {
    portCode: "1608",
    city: "Greer, South Carolina",
    coordinates: {
      lat: 34.9387,
      lng: -82.2271,
    },
  },
  {
    portCode: "1681",
    city: "Myrtle Beach International Airport, Myrtle Beach, South Carolina",
    coordinates: {
      lat: 33.6794,
      lng: -78.9283,
    },
  },
  {
    portCode: "1701",
    city: "Brunswick, Georgia",
    coordinates: {
      lat: 31.1499,
      lng: -81.4915,
    },
  },
  {
    portCode: "1703",
    city: "Savannah, Georgia",
    coordinates: {
      lat: 32.0809,
      lng: -81.0912,
    },
  },
  {
    portCode: "1704",
    city: "Atlanta, Georgia",
    coordinates: {
      lat: 33.749,
      lng: -84.388,
    },
  },
  {
    portCode: "1705",
    city: "Albany, Georgia",
    coordinates: {
      lat: 31.5785,
      lng: -84.1557,
    },
  },
  {
    portCode: "1706",
    city: "Dalton, Georgia",
    coordinates: {
      lat: 34.7698,
      lng: -84.9702,
    },
  },
  {
    portCode: "1709",
    city: "Hartsfield Airport, Atlanta, Georgia",
    coordinates: {
      lat: 33.6407,
      lng: -84.4277,
    },
  },
  {
    portCode: "1755",
    city: "Port for Tracking, Savannah, Georgia",
    coordinates: {
      lat: 32.0809,
      lng: -81.0912,
    },
  },
  {
    portCode: "1758",
    city: "Olympics Security, Atlanta, Georgia",
    coordinates: {
      lat: 33.749,
      lng: -84.388,
    },
  },
  {
    portCode: "1759",
    city: "Atlanta-Olympics, Atlanta, Georgia",
    coordinates: {
      lat: 33.749,
      lng: -84.388,
    },
  },
  {
    portCode: "1781",
    city: "Cobb County International Airport, Kenshaw, Georgia",
    coordinates: {
      lat: 34.014,
      lng: -84.5981,
    },
  },
  {
    portCode: "1801",
    city: "Tampa, Florida",
    coordinates: {
      lat: 27.9506,
      lng: -82.4572,
    },
  },
  {
    portCode: "1803",
    city: "Jacksonville, Florida",
    coordinates: {
      lat: 30.3322,
      lng: -81.6557,
    },
  },
  {
    portCode: "1804",
    city: "Tallahassee, Florida",
    coordinates: {
      lat: 30.4383,
      lng: -84.2807,
    },
  },
  {
    portCode: "1805",
    city: "Fernandina Beach, Florida",
    coordinates: {
      lat: 30.6697,
      lng: -81.4626,
    },
  },
  {
    portCode: "1807",
    city: "Boca Grande, Florida",
    coordinates: {
      lat: 26.7492,
      lng: -82.2637,
    },
  },
  {
    portCode: "1808",
    city: "Orlando, Florida",
    coordinates: {
      lat: 28.5383,
      lng: -81.3792,
    },
  },
  {
    portCode: "1809",
    city: "Orlando-Sanford Airport, Sanford, Florida",
    coordinates: {
      lat: 28.7778,
      lng: -81.2372,
    },
  },
  {
    portCode: "1811",
    city: "Sarasota/Bradenton, Manatee, Florida",
    coordinates: {
      lat: 27.4799,
      lng: -82.5748,
    },
  },
  {
    portCode: "1814",
    city: "St. Petersburg, Florida",
    coordinates: {
      lat: 27.7676,
      lng: -82.6403,
    },
  },
  {
    portCode: "1816",
    city: "Port Canaveral, Florida",
    coordinates: {
      lat: 28.4088,
      lng: -80.6183,
    },
  },
  {
    portCode: "1818",
    city: "Panama City, Florida",
    coordinates: {
      lat: 30.1588,
      lng: -85.6602,
    },
  },
  {
    portCode: "1819",
    city: "Pensacola, Florida",
    coordinates: {
      lat: 30.4213,
      lng: -87.2169,
    },
  },
  {
    portCode: "1820",
    city: "Port St. Joe, Florida",
    coordinates: {
      lat: 29.813,
      lng: -85.3027,
    },
  },
  {
    portCode: "1821",
    city: "Port Manatee, Florida",
    coordinates: {
      lat: 27.6423,
      lng: -82.5629,
    },
  },
  {
    portCode: "1822",
    city: "Fort Myers, Florida",
    coordinates: {
      lat: 26.6406,
      lng: -81.8723,
    },
  },
  {
    portCode: "1851",
    city: "Tampa Border Patrol, Tampa, Florida",
    coordinates: {
      lat: 27.9506,
      lng: -82.4572,
    },
  },
  {
    portCode: "1852",
    city: "Jacksonville Border Patrol, Jacksonville, Florida",
    coordinates: {
      lat: 30.3322,
      lng: -81.6557,
    },
  },
  {
    portCode: "1853",
    city: "Orlando Border Patrol, Orlando, Florida",
    coordinates: {
      lat: 28.5383,
      lng: -81.3792,
    },
  },
  {
    portCode: "1880",
    city: "Naples Municipal Airport, Naples, Florida",
    coordinates: {
      lat: 26.1526,
      lng: -81.7752,
    },
  },
  {
    portCode: "1881",
    city: "Lakeland Linder Airport, Lakeland, Florida",
    coordinates: {
      lat: 27.9886,
      lng: -82.0181,
    },
  },
  {
    portCode: "1883",
    city: "Sarasota-Bradenton Airport, Sarasota, Florida",
    coordinates: {
      lat: 27.3954,
      lng: -82.5544,
    },
  },
  {
    portCode: "1884",
    city: "Daytona Beach International Airport, Daytona Beach, Florida",
    coordinates: {
      lat: 29.1799,
      lng: -81.058,
    },
  },
  {
    portCode: "1885",
    city: "Melbourne Regional Airport, Melbourne, Florida",
    coordinates: {
      lat: 28.102,
      lng: -80.6453,
    },
  },
  {
    portCode: "1887",
    city: "Leesburg Regional Airport, Leesburg, Florida",
    coordinates: {
      lat: 28.8231,
      lng: -81.8087,
    },
  },
  {
    portCode: "1888",
    city: "Orlando Executive Airport, Orlando, Florida",
    coordinates: {
      lat: 28.5455,
      lng: -81.3329,
    },
  },
  {
    portCode: "1889",
    city: "St. Augustine Airport (User Fee Airport), St Augustine, Florida",
    coordinates: {
      lat: 29.9596,
      lng: -81.3396,
    },
  },
  {
    portCode: "4901",
    city: "Aguadilla, Puerto Rico",
    coordinates: {
      lat: 18.4273,
      lng: -67.1541,
    },
  },
  {
    portCode: "4904",
    city: "Fajardo, Puerto Rico",
    coordinates: {
      lat: 18.3258,
      lng: -65.6523,
    },
  },
  {
    portCode: "4907",
    city: "Mayaguez, Puerto Rico",
    coordinates: {
      lat: 18.2017,
      lng: -67.1397,
    },
  },
  {
    portCode: "4908",
    city: "Ponce, Puerto Rico",
    coordinates: {
      lat: 18.0111,
      lng: -66.6141,
    },
  },
  {
    portCode: "4909",
    city: "San Juan, Puerto Rico",
    coordinates: {
      lat: 18.4655,
      lng: -66.1057,
    },
  },
  {
    portCode: "4913",
    city: "San Juan International Airport, Old San Juan, Puerto Rico",
    coordinates: {
      lat: 18.4653,
      lng: -66.1143,
    },
  },
  {
    portCode: "4950",
    city: "Border Patrol Sector HQ, Aguadilla, Puerto Rico",
    coordinates: {
      lat: 18.4273,
      lng: -67.1541,
    },
  },
  {
    portCode: "4951",
    city: "Ramey Puerto Rico Border Patrol, Aguadilla, Puerto Rico",
    coordinates: {
      lat: 18.4273,
      lng: -67.1541,
    },
  },
  {
    portCode: "5101",
    city: "Charlotte Amalie, St. Thomas, Virgin Islands",
    coordinates: {
      lat: 18.3419,
      lng: -64.9307,
    },
  },
  {
    portCode: "5102",
    city: "Cruz Bay, St. John, Virgin Islands",
    coordinates: {
      lat: 18.33,
      lng: -64.7942,
    },
  },
  {
    portCode: "5104",
    city: "Christiansted, St. Croix, Virgin Islands",
    coordinates: {
      lat: 17.7466,
      lng: -64.7032,
    },
  },
  {
    portCode: "5105",
    city: "Frederiksted, St. Croix, Virgin Islands",
    coordinates: {
      lat: 17.711,
      lng: -64.8834,
    },
  },
  {
    portCode: "5201",
    city: "Miami, Florida",
    coordinates: {
      lat: 25.7617,
      lng: -80.1918,
    },
  },
  {
    portCode: "5202",
    city: "Key West, Florida",
    coordinates: {
      lat: 24.5551,
      lng: -81.78,
    },
  },
  {
    portCode: "5203",
    city: "Port Everglades, Florida",
    coordinates: {
      lat: 26.0983,
      lng: -80.1238,
    },
  },
  {
    portCode: "5204",
    city: "West Palm Beach, Florida",
    coordinates: {
      lat: 26.7153,
      lng: -80.0534,
    },
  },
  {
    portCode: "5205",
    city: "Fort Pierce, Florida",
    coordinates: {
      lat: 27.4467,
      lng: -80.3256,
    },
  },
  {
    portCode: "5206",
    city: "Miami International Airport, Miami, Florida",
    coordinates: {
      lat: 25.7959,
      lng: -80.287,
    },
  },
  {
    portCode: "5210",
    city: "Fort Lauderdale International Airport, Fort Lauderdale, Florida",
    coordinates: {
      lat: 26.0726,
      lng: -80.1527,
    },
  },
  {
    portCode: "5211",
    city: "Key Largo, Florida",
    coordinates: {
      lat: 25.0865,
      lng: -80.4473,
    },
  },
  {
    portCode: "5212",
    city: "Pembroke Pines, Florida",
    coordinates: {
      lat: 26.0032,
      lng: -80.2239,
    },
  },
  {
    portCode: "5250",
    city: "Border Patrol Sector HQ, Pembroke Pines, Florida",
    coordinates: {
      lat: 26.0032,
      lng: -80.2239,
    },
  },
  {
    portCode: "5251",
    city: "Miami Border Patrol, Pembroke Pines, Florida",
    coordinates: {
      lat: 26.0032,
      lng: -80.2239,
    },
  },
  {
    portCode: "5252",
    city: "West Palm Beach Border Patrol, West Palm Beach, Florida",
    coordinates: {
      lat: 26.7153,
      lng: -80.0534,
    },
  },
  {
    portCode: "5253",
    city: "Marathon Border Patrol, Marathon, Florida",
    coordinates: {
      lat: 24.726,
      lng: -81.0514,
    },
  },
  {
    portCode: "5255",
    city: "Miami Alternate, Miami, Florida",
    coordinates: {
      lat: 25.7617,
      lng: -80.1918,
    },
  },
  {
    portCode: "5274",
    city: "Fedex Corp Miami ECCF, Miami, Florida",
    coordinates: {
      lat: 25.7617,
      lng: -80.1918,
    },
  },
  {
    portCode: "5281",
    city: "Marathon International Airport, Marathon, Florida",
    coordinates: {
      lat: 24.726,
      lng: -81.0514,
    },
  },
  {
    portCode: "5282",
    city: "Boca Raton Airport, Boca Raton, Florida",
    coordinates: {
      lat: 26.3778,
      lng: -80.107,
    },
  },
  {
    portCode: "5283",
    city: "Witham Field GAF, Stuart, Florida",
    coordinates: {
      lat: 27.1817,
      lng: -80.2212,
    },
  },
  {
    portCode: "5295",
    city: "UPS Hub Miami International Airport, Miami, Florida",
    coordinates: {
      lat: 25.7617,
      lng: -80.1918,
    },
  },
  {
    portCode: "5296",
    city: "DHL Worldwide Express Hub, Miami, Florida",
    coordinates: {
      lat: 25.7617,
      lng: -80.1918,
    },
  },
  {
    portCode: "5297",
    city: "Fedex Courier Hub, Miami, Florida",
    coordinates: {
      lat: 25.7617,
      lng: -80.1918,
    },
  },
  {
    portCode: "5298",
    city: "IBC Courier Hub, Miami, Florida",
    coordinates: {
      lat: 25.7617,
      lng: -80.1918,
    },
  },
  {
    portCode: "5401",
    city: "Washington, District of Columbia",
    coordinates: {
      lat: 38.9072,
      lng: -77.0369,
    },
  },
  {
    portCode: "5402",
    city: "Reagan National (DCA), Arlington, Virginia",
    coordinates: {
      lat: 38.8512,
      lng: -77.0402,
    },
  },
  {
    portCode: "7400",
    city: "Southeast Region, Miami, Florida",
    coordinates: {
      lat: 25.7617,
      lng: -80.1918,
    },
  },
  {
    portCode: "7401",
    city: "CBP St. Thomas Preclearance, St. Thomas, Virgin Islands",
    coordinates: {
      lat: 18.3419,
      lng: -64.9307,
    },
  },
  {
    portCode: "7404",
    city: "CBP St. Croix Preclearance, St. Croix, Virgin Islands",
    coordinates: {
      lat: 17.7466,
      lng: -64.7032,
    },
  },
  {
    portCode: "7421",
    city: "CBP Bermuda Preclearance, Washington, DC",
    coordinates: {
      lat: 32.3078,
      lng: -64.7505,
    },
  },
  {
    portCode: "7422",
    city: "CBP Freeport Preclearance, Washington, DC",
    coordinates: {
      lat: 26.5333,
      lng: -78.7,
    },
  },
  {
    portCode: "7423",
    city: "CBP, Nassau Preclearance, Washington, DC",
    coordinates: {
      lat: 25.0343,
      lng: -77.3963,
    },
  },
  {
    portCode: "7424",
    city: "CBP, Aruba Preclearance, Washington, DC",
    coordinates: {
      lat: 12.5211,
      lng: -69.9683,
    },
  },
  {
    portCode: "7425",
    city: "CBP, Punta Cana International Preclearance, Washington, DC",
    coordinates: {
      lat: 18.5601,
      lng: -68.3725,
    },
  },
  {
    portCode: "7541",
    city: "Dublin, IE Preclearance, Washington, DC",
    coordinates: {
      lat: 53.3498,
      lng: -6.2603,
    },
  },
  {
    portCode: "7542",
    city: "Shannon IE Preclearance, Washington, DC",
    coordinates: {
      lat: 52.7039,
      lng: -8.8642,
    },
  },
  {
    portCode: "7543",
    city: "CBP Abu Dhabi Preclearance, Washington, DC",
    coordinates: {
      lat: 24.4539,
      lng: 54.3773,
    },
  },
  {
    portCode: "2101",
    city: "Port Arthur, Texas",
    coordinates: {
      lat: 29.8849,
      lng: -93.9399,
    },
  },
  {
    portCode: "2102",
    city: "Sabine, Texas",
    coordinates: {
      lat: 29.9707,
      lng: -93.9031,
    },
  },
  {
    portCode: "2103",
    city: "Orange, Texas",
    coordinates: {
      lat: 30.092,
      lng: -93.7366,
    },
  },
  {
    portCode: "2104",
    city: "Beaumont, Texas",
    coordinates: {
      lat: 30.0802,
      lng: -94.1266,
    },
  },
  {
    portCode: "2150",
    city: "Border Patrol Sector HQ, Yuma, Arizona",
    coordinates: {
      lat: 32.6927,
      lng: -114.6277,
    },
  },
  {
    portCode: "2151",
    city: "Blythe California Border Patrol, Blythe, California",
    coordinates: {
      lat: 33.6103,
      lng: -114.5964,
    },
  },
  {
    portCode: "2152",
    city: "Yuma Arizona Border Patrol, Yuma, Arizona",
    coordinates: {
      lat: 32.6927,
      lng: -114.6277,
    },
  },
  {
    portCode: "2153",
    city: "Wellton Arizona Border Patrol, Wellton, Arizona",
    coordinates: {
      lat: 32.672,
      lng: -114.1424,
    },
  },
  {
    portCode: "2250",
    city: "Border Patrol Sector HQ, Laredo, Texas",
    coordinates: {
      lat: 27.5064,
      lng: -99.5075,
    },
  },
  {
    portCode: "2251",
    city: "Laredo Del Mar Border Patrol, Laredo, Texas",
    coordinates: {
      lat: 27.5064,
      lng: -99.5075,
    },
  },
  {
    portCode: "2252",
    city: "Cotulla Texas Border Patrol, Cotulla, Texas",
    coordinates: {
      lat: 28.4369,
      lng: -99.2359,
    },
  },
  {
    portCode: "2253",
    city: "Hebbronville Texas Border Patrol, Hebbronville, Texas",
    coordinates: {
      lat: 27.3142,
      lng: -98.6789,
    },
  },
  {
    portCode: "2254",
    city: "Laredo South Border Patrol, Laredo, Texas",
    coordinates: {
      lat: 27.5064,
      lng: -99.5075,
    },
  },
  {
    portCode: "2255",
    city: "Dallas Border Patrol, Euless, Texas",
    coordinates: {
      lat: 32.8371,
      lng: -97.0819,
    },
  },
  {
    portCode: "2256",
    city: "Freer Texas Border Patrol, Freer, Texas",
    coordinates: {
      lat: 27.8836,
      lng: -98.617,
    },
  },
  {
    portCode: "2257",
    city: "San Antonio Border Patrol, San Antonio, Texas",
    coordinates: {
      lat: 29.4241,
      lng: -98.4936,
    },
  },
  {
    portCode: "2258",
    city: "Zapata Texas Border Patrol, Zapata, Texas",
    coordinates: {
      lat: 26.9078,
      lng: -99.2717,
    },
  },
  {
    portCode: "2259",
    city: "Laredo West Station, Laredo, Texas",
    coordinates: {
      lat: 27.5064,
      lng: -99.5075,
    },
  },
  {
    portCode: "2301",
    city: "Brownsville, Texas",
    coordinates: {
      lat: 25.9017,
      lng: -97.4975,
    },
  },
  {
    portCode: "2302",
    city: "Del Rio, Texas",
    coordinates: {
      lat: 29.3709,
      lng: -100.8951,
    },
  },
  {
    portCode: "2303",
    city: "Eagle Pass, Texas",
    coordinates: {
      lat: 28.7091,
      lng: -100.4995,
    },
  },
  {
    portCode: "2304",
    city: "Laredo, Texas",
    coordinates: {
      lat: 27.5064,
      lng: -99.5075,
    },
  },
  {
    portCode: "2305",
    city: "Hidalgo, Texas",
    coordinates: {
      lat: 26.1004,
      lng: -98.2636,
    },
  },
  {
    portCode: "2307",
    city: "Rio Grande City, Texas",
    coordinates: {
      lat: 26.3792,
      lng: -98.8209,
    },
  },
  {
    portCode: "2309",
    city: "Progreso, Texas",
    coordinates: {
      lat: 26.0901,
      lng: -97.9578,
    },
  },
  {
    portCode: "2310",
    city: "Roma, Texas",
    coordinates: {
      lat: 26.4068,
      lng: -99.0125,
    },
  },
  {
    portCode: "2330",
    city: "Harlingen, Texas",
    coordinates: {
      lat: 26.1906,
      lng: -97.6961,
    },
  },
  {
    portCode: "2350",
    city: "Border Patrol Sector HQ, Del Rio, Texas",
    coordinates: {
      lat: 29.3709,
      lng: -100.8951,
    },
  },
  {
    portCode: "2351",
    city: "Comstock Border Patrol, Comstock, Texas",
    coordinates: {
      lat: 29.6944,
      lng: -101.1776,
    },
  },
  {
    portCode: "2352",
    city: "Carrizo Springs Border Patrol, Carrizo Springs, Texas",
    coordinates: {
      lat: 28.5225,
      lng: -99.8603,
    },
  },
  {
    portCode: "2353",
    city: "Del Rio Border Patrol, Del Rio, Texas",
    coordinates: {
      lat: 29.3709,
      lng: -100.8951,
    },
  },
  {
    portCode: "2354",
    city: "Eagle Pass Border Patrol, Eagle Pass, Texas",
    coordinates: {
      lat: 28.7091,
      lng: -100.4995,
    },
  },
  {
    portCode: "2355",
    city: "Brackettville Border Patrol, Brackettville, Texas",
    coordinates: {
      lat: 29.3107,
      lng: -100.4165,
    },
  },
  {
    portCode: "2356",
    city: "Uvalde Texas Border Patrol, Uvalde, Texas",
    coordinates: {
      lat: 29.2097,
      lng: -99.7862,
    },
  },
  {
    portCode: "2357",
    city: "Rocksprings Border Patrol, Rocksprings, Texas",
    coordinates: {
      lat: 30.0152,
      lng: -100.2084,
    },
  },
  {
    portCode: "2358",
    city: "Llano Texas Border Patrol, Llano, Texas",
    coordinates: {
      lat: 30.7599,
      lng: -98.6759,
    },
  },
  {
    portCode: "2359",
    city: "San Angelo Border Patrol, San Angelo, Texas",
    coordinates: {
      lat: 31.4638,
      lng: -100.437,
    },
  },
  {
    portCode: "2360",
    city: "Abilene Texas Border Patrol, Abilene, Texas",
    coordinates: {
      lat: 32.4487,
      lng: -99.7331,
    },
  },
  {
    portCode: "2361",
    city: "Border Patrol Sector HQ, McAllen, Texas",
    coordinates: {
      lat: 26.2034,
      lng: -98.23,
    },
  },
  {
    portCode: "2362",
    city: "Mercedes Border Patrol, Weslaco, Texas",
    coordinates: {
      lat: 26.1595,
      lng: -97.9903,
    },
  },
  {
    portCode: "2363",
    city: "Falfurrias Border Patrol, Falfurrias, Texas",
    coordinates: {
      lat: 27.2259,
      lng: -98.1442,
    },
  },
  {
    portCode: "2364",
    city: "Rio Grande City Border Patrol, Rio Grande City, Texas",
    coordinates: {
      lat: 26.3792,
      lng: -98.8209,
    },
  },
  {
    portCode: "2365",
    city: "McAllen Border Patrol, McAllen, Texas",
    coordinates: {
      lat: 26.2034,
      lng: -98.23,
    },
  },
  {
    portCode: "2366",
    city: "Brownsville Border Patrol, Brownsville, Texas",
    coordinates: {
      lat: 25.9017,
      lng: -97.4975,
    },
  },
  {
    portCode: "2367",
    city: "Harlingen Border Patrol, Harlingen, Texas",
    coordinates: {
      lat: 26.1906,
      lng: -97.6961,
    },
  },
  {
    portCode: "2368",
    city: "Kingsville Border Patrol, Kingsville, Texas",
    coordinates: {
      lat: 27.5159,
      lng: -97.8561,
    },
  },
  {
    portCode: "2369",
    city: "Fort Brown Border Patrol, Brownsville, Texas",
    coordinates: {
      lat: 25.9017,
      lng: -97.4975,
    },
  },
  {
    portCode: "2370",
    city: "Corpus Christie Border Patrol, Corpus Christie, Texas",
    coordinates: {
      lat: 27.8006,
      lng: -97.3964,
    },
  },
  {
    portCode: "2377",
    city: "Harlingen # Valley International, Harlingen, Texas",
    coordinates: {
      lat: 26.2285,
      lng: -97.6544,
    },
  },
  {
    portCode: "2383",
    city: "Valley International User Fee Airport, Harlingen, Texas",
    coordinates: {
      lat: 26.2285,
      lng: -97.6544,
    },
  },
  {
    portCode: "2393",
    city: "Brownsville-Cartage Control, Brownsville, Texas",
    coordinates: {
      lat: 25.9017,
      lng: -97.4975,
    },
  },
  {
    portCode: "2396",
    city: "San Antonio-Cartage Control, San Antonio, Texas",
    coordinates: {
      lat: 29.4241,
      lng: -98.4936,
    },
  },
  {
    portCode: "2397",
    city: "Roma-Cartage Control, Roma, Texas",
    coordinates: {
      lat: 26.4068,
      lng: -99.0125,
    },
  },
  {
    portCode: "2398",
    city: "Hidalgo Cartage Control, Pharr, Texas",
    coordinates: {
      lat: 26.1948,
      lng: -98.1836,
    },
  },
  {
    portCode: "2401",
    city: "Area Port of Ysleta, Texas",
    coordinates: {
      lat: 31.6927,
      lng: -106.3359,
    },
  },
  {
    portCode: "2402",
    city: "El Paso, Texas",
    coordinates: {
      lat: 31.7619,
      lng: -106.485,
    },
  },
  {
    portCode: "2403",
    city: "Presidio, Texas",
    coordinates: {
      lat: 29.5607,
      lng: -104.3727,
    },
  },
  {
    portCode: "2404",
    city: "Tornillo, Texas",
    coordinates: {
      lat: 31.4493,
      lng: -106.0941,
    },
  },
  {
    portCode: "2406",
    city: "Columbus, New Mexico",
    coordinates: {
      lat: 31.827,
      lng: -107.6406,
    },
  },
  {
    portCode: "2407",
    city: "Albuquerque, New Mexico",
    coordinates: {
      lat: 35.0844,
      lng: -106.6504,
    },
  },
  {
    portCode: "2408",
    city: "Santa Teresa, New Mexico",
    coordinates: {
      lat: 31.8579,
      lng: -106.639,
    },
  },
  {
    portCode: "2409",
    city: "Fort Hancock, Texas",
    coordinates: {
      lat: 31.2987,
      lng: -105.8511,
    },
  },
  {
    portCode: "2410",
    city: "Boquillas, Texas",
    coordinates: {
      lat: 29.1844,
      lng: -102.9446,
    },
  },
  {
    portCode: "2420",
    city: "Deming, New Mexico",
    coordinates: {
      lat: 32.2687,
      lng: -107.7586,
    },
  },
  {
    portCode: "2421",
    city: "Las Cruces, New Mexico",
    coordinates: {
      lat: 32.3199,
      lng: -106.7637,
    },
  },
  {
    portCode: "2422",
    city: "Alamogordo, New Mexico",
    coordinates: {
      lat: 32.8995,
      lng: -105.9603,
    },
  },
  {
    portCode: "2423",
    city: "Carlsbad, New Mexico",
    coordinates: {
      lat: 32.4207,
      lng: -104.2288,
    },
  },
  {
    portCode: "2424",
    city: "Alpine, Texas",
    coordinates: {
      lat: 30.3585,
      lng: -103.661,
    },
  },
  {
    portCode: "2425",
    city: "Midland, Texas",
    coordinates: {
      lat: 31.9973,
      lng: -102.0779,
    },
  },
  {
    portCode: "2426",
    city: "Van Horn, Texas",
    coordinates: {
      lat: 31.0399,
      lng: -104.8316,
    },
  },
  {
    portCode: "2427",
    city: "Marfa, Texas",
    coordinates: {
      lat: 30.3091,
      lng: -104.0206,
    },
  },
  {
    portCode: "2450",
    city: "Border Patrol Sector HQ, El Paso, Texas",
    coordinates: {
      lat: 31.7619,
      lng: -106.485,
    },
  },
  {
    portCode: "2451",
    city: "Lordsburg New Mexico Border Patrol, Lordsburg, New Mexico",
    coordinates: {
      lat: 32.3504,
      lng: -108.7073,
    },
  },
  {
    portCode: "2452",
    city: "Truth or Cons Border Patrol, Truth or Consequences, New Mexico",
    coordinates: {
      lat: 33.1284,
      lng: -107.2528,
    },
  },
  {
    portCode: "2453",
    city: "Las Cruces Border Patrol, Las Cruces, New Mexico",
    coordinates: {
      lat: 32.3199,
      lng: -106.7637,
    },
  },
  {
    portCode: "2454",
    city: "El Paso Border Patrol, El Paso, Texas",
    coordinates: {
      lat: 31.7619,
      lng: -106.485,
    },
  },
  {
    portCode: "2455",
    city: "Fabens Texas Border Patrol, Fabens, Texas",
    coordinates: {
      lat: 31.5076,
      lng: -106.1564,
    },
  },
  {
    portCode: "2456",
    city: "Alamogordo Border Patrol, Alamogordo, New Mexico",
    coordinates: {
      lat: 32.8995,
      lng: -105.9603,
    },
  },
  {
    portCode: "2457",
    city: "Fort Hancock Texas Border Patrol, Fort Hancock, Texas",
    coordinates: {
      lat: 31.2987,
      lng: -105.8511,
    },
  },
  {
    portCode: "2458",
    city: "Carlsbad New Mexico Border Patrol, Carlsbad, New Mexico",
    coordinates: {
      lat: 32.4207,
      lng: -104.2288,
    },
  },
  {
    portCode: "2459",
    city: "Deming New Mexico Border Patrol, Deming, New Mexico",
    coordinates: {
      lat: 32.2687,
      lng: -107.7586,
    },
  },
  {
    portCode: "2460",
    city: "Silver City New Mexico Border Patrol, Silver City, New Mexico",
    coordinates: {
      lat: 32.7701,
      lng: -108.2803,
    },
  },
  {
    portCode: "2461",
    city: "Ysleta Border Patrol, El Paso, Texas",
    coordinates: {
      lat: 31.6927,
      lng: -106.3359,
    },
  },
  {
    portCode: "2462",
    city: "Albuquerque Border Patrol, Albuquerque, New Mexico",
    coordinates: {
      lat: 35.0844,
      lng: -106.6504,
    },
  },
  {
    portCode: "2463",
    city: "Santa Teresa Border Patrol, Santa Teresa, New Mexico",
    coordinates: {
      lat: 31.8579,
      lng: -106.639,
    },
  },
  {
    portCode: "2481",
    city: "Santa Teresa Airport, Santa Teresa, New Mexico",
    coordinates: {
      lat: 31.8579,
      lng: -106.639,
    },
  },
  {
    portCode: "2482",
    city: "Roswell Industrial Airport, Roswell, New Mexico",
    coordinates: {
      lat: 33.3016,
      lng: -104.5304,
    },
  },
  {
    portCode: "2499",
    city: "El Paso Cartage Control, El Paso, Texas",
    coordinates: {
      lat: 31.7619,
      lng: -106.485,
    },
  },
  {
    portCode: "2601",
    city: "Douglas, Arizona",
    coordinates: {
      lat: 31.3445,
      lng: -109.5453,
    },
  },
  {
    portCode: "2602",
    city: "Lukeville, Arizona",
    coordinates: {
      lat: 31.8762,
      lng: -112.8068,
    },
  },
  {
    portCode: "2603",
    city: "Naco, Arizona",
    coordinates: {
      lat: 31.3333,
      lng: -109.9461,
    },
  },
  {
    portCode: "2604",
    city: "Nogales, Arizona",
    coordinates: {
      lat: 31.3404,
      lng: -110.9343,
    },
  },
  {
    portCode: "2605",
    city: "Phoenix, Arizona",
    coordinates: {
      lat: 33.4484,
      lng: -112.074,
    },
  },
  {
    portCode: "2606",
    city: "Sasabe, Arizona",
    coordinates: {
      lat: 31.4917,
      lng: -111.5413,
    },
  },
  {
    portCode: "2607",
    city: "Sells, Arizona",
    coordinates: {
      lat: 31.9164,
      lng: -111.8765,
    },
  },
  {
    portCode: "2608",
    city: "San Luis, Arizona",
    coordinates: {
      lat: 32.4867,
      lng: -114.7828,
    },
  },
  {
    portCode: "2609",
    city: "Tucson, Arizona",
    coordinates: {
      lat: 32.2226,
      lng: -110.9747,
    },
  },
  {
    portCode: "2610",
    city: "Sierra Vista, Arizona",
    coordinates: {
      lat: 31.5455,
      lng: -110.2773,
    },
  },
  {
    portCode: "2611",
    city: "Yuma, Arizona",
    coordinates: {
      lat: 32.6927,
      lng: -114.6277,
    },
  },
  {
    portCode: "2621",
    city: "Kingman, Arizona",
    coordinates: {
      lat: 35.1894,
      lng: -114.053,
    },
  },
  {
    portCode: "2650",
    city: "Border Patrol Sector HQ, Tucson, Arizona",
    coordinates: {
      lat: 32.2226,
      lng: -110.9747,
    },
  },
  {
    portCode: "2651",
    city: "Casa Grande Arizona Border Patrol, Casa Grande, Arizona",
    coordinates: {
      lat: 32.8795,
      lng: -111.7574,
    },
  },
  {
    portCode: "2652",
    city: "Tucson Arizona Border Patrol, Tucson, Arizona",
    coordinates: {
      lat: 32.2226,
      lng: -110.9747,
    },
  },
  {
    portCode: "2653",
    city: "Nogales Arizona Border Patrol, Nogales, Arizona",
    coordinates: {
      lat: 31.3404,
      lng: -110.9343,
    },
  },
  {
    portCode: "2654",
    city: "Willcox Arizona Border Patrol, Willcox, Arizona",
    coordinates: {
      lat: 32.252,
      lng: -109.8329,
    },
  },
  {
    portCode: "2655",
    city: "Douglas Arizona Border Patrol, Douglas, Arizona",
    coordinates: {
      lat: 31.3445,
      lng: -109.5453,
    },
  },
  {
    portCode: "2656",
    city: "Ajo Arizona Border Patrol, Why, Arizona",
    coordinates: {
      lat: 32.2652,
      lng: -112.741,
    },
  },
  {
    portCode: "2657",
    city: "Naco Border Patrol, Bisbee, Arizona",
    coordinates: {
      lat: 31.4482,
      lng: -109.9284,
    },
  },
  {
    portCode: "2658",
    city: "Sonoita Arizona Border Patrol, Sonoita, Arizona",
    coordinates: {
      lat: 31.6815,
      lng: -110.6543,
    },
  },
  {
    portCode: "2681",
    city: "Scottsdale User Fee Airport, Scottsdale, Arizona",
    coordinates: {
      lat: 33.4942,
      lng: -111.9261,
    },
  },
  {
    portCode: "2682",
    city: "Phoenix-Mesa Gateway Airport, Mesa, Arizona",
    coordinates: {
      lat: 33.3078,
      lng: -111.655,
    },
  },
  {
    portCode: "5301",
    city: "Houston, Texas",
    coordinates: {
      lat: 29.7604,
      lng: -95.3698,
    },
  },
  {
    portCode: "5306",
    city: "Texas City, Texas",
    coordinates: {
      lat: 29.3838,
      lng: -94.9027,
    },
  },
  {
    portCode: "5309",
    city: "Houston George Bush Intercontinental Airport, Houston, Texas",
    coordinates: {
      lat: 29.9902,
      lng: -95.3368,
    },
  },
  {
    portCode: "5310",
    city: "Galveston, Texas",
    coordinates: {
      lat: 29.3013,
      lng: -94.7977,
    },
  },
  {
    portCode: "5311",
    city: "Freeport, Texas",
    coordinates: {
      lat: 28.9543,
      lng: -95.3594,
    },
  },
  {
    portCode: "5312",
    city: "Corpus Christi, Texas",
    coordinates: {
      lat: 27.8006,
      lng: -97.3964,
    },
  },
  {
    portCode: "5313",
    city: "Port Lavaca, Texas",
    coordinates: {
      lat: 28.6144,
      lng: -96.6266,
    },
  },
  {
    portCode: "5314",
    city: "Hobby International Airport, Houston, Texas",
    coordinates: {
      lat: 29.6454,
      lng: -95.2789,
    },
  },
  {
    portCode: "5315",
    city: "Ellington Field, Houston, Texas",
    coordinates: {
      lat: 29.6073,
      lng: -95.1588,
    },
  },
  {
    portCode: "5381",
    city: "Sugar Land Regional Airport, Sugar Land, Texas",
    coordinates: {
      lat: 29.6223,
      lng: -95.6564,
    },
  },
  {
    portCode: "5382",
    city: "Conroe-North, Conroe, Texas",
    coordinates: {
      lat: 30.3163,
      lng: -95.4586,
    },
  },
  {
    portCode: "5501",
    city: "Dallas/Ft. Worth Airport, Texas",
    coordinates: {
      lat: 32.8998,
      lng: -97.0403,
    },
  },
  {
    portCode: "5502",
    city: "Amarillo, Texas",
    coordinates: {
      lat: 35.222,
      lng: -101.8313,
    },
  },
  {
    portCode: "5503",
    city: "Lubbock, Texas",
    coordinates: {
      lat: 33.5779,
      lng: -101.8552,
    },
  },
  {
    portCode: "5504",
    city: "Oklahoma City, Oklahoma",
    coordinates: {
      lat: 35.4676,
      lng: -97.5164,
    },
  },
  {
    portCode: "5505",
    city: "Tulsa, Oklahoma",
    coordinates: {
      lat: 36.1539,
      lng: -95.9928,
    },
  },
  {
    portCode: "5506",
    city: "Austin, Texas",
    coordinates: {
      lat: 30.2672,
      lng: -97.7431,
    },
  },
  {
    portCode: "5507",
    city: "San Antonio, Texas",
    coordinates: {
      lat: 29.4241,
      lng: -98.4936,
    },
  },
  {
    portCode: "5520",
    city: "Abilene, Texas",
    coordinates: {
      lat: 32.4487,
      lng: -99.7331,
    },
  },
  {
    portCode: "5521",
    city: "San Angelo, Texas",
    coordinates: {
      lat: 31.4638,
      lng: -100.437,
    },
  },
  {
    portCode: "5550",
    city: "Border Patrol Sector HQ, Marfa, Texas",
    coordinates: {
      lat: 30.3091,
      lng: -104.0206,
    },
  },
  {
    portCode: "5551",
    city: "Lubbock Border Patrol, Lubbock, Texas",
    coordinates: {
      lat: 33.5779,
      lng: -101.8552,
    },
  },
  {
    portCode: "5552",
    city: "Amarillo Border Patrol, Amarillo, Texas",
    coordinates: {
      lat: 35.222,
      lng: -101.8313,
    },
  },
  {
    portCode: "5553",
    city: "Presidio Texas Border Patrol, Presidio, Texas",
    coordinates: {
      lat: 29.5607,
      lng: -104.3727,
    },
  },
  {
    portCode: "5554",
    city: "Marfa Texas Border Patrol, Marfa, Texas",
    coordinates: {
      lat: 30.3091,
      lng: -104.0206,
    },
  },
  {
    portCode: "5555",
    city: "Pecos Texas Border Patrol, Pecos, Texas",
    coordinates: {
      lat: 31.4229,
      lng: -103.4932,
    },
  },
  {
    portCode: "5556",
    city: "Fort Stockton Border Patrol, Fort Stockton, Texas",
    coordinates: {
      lat: 30.8946,
      lng: -102.8791,
    },
  },
  {
    portCode: "5557",
    city: "Sanderson Texas Border Patrol, Sanderson, Texas",
    coordinates: {
      lat: 30.1421,
      lng: -102.3937,
    },
  },
  {
    portCode: "5558",
    city: "Alpine Texas Border Patrol, Alpine, Texas",
    coordinates: {
      lat: 30.3585,
      lng: -103.661,
    },
  },
  {
    portCode: "5559",
    city: "Big Bend Border Patrol, Big Bend, Texas",
    coordinates: {
      lat: 29.3315,
      lng: -103.1482,
    },
  },
  {
    portCode: "5560",
    city: "Sierra Blanca Border Patrol, Sierra Blanca, Texas",
    coordinates: {
      lat: 31.1818,
      lng: -105.3583,
    },
  },
  {
    portCode: "5561",
    city: "Van Horn Texas Border Patrol, Van Horn, Texas",
    coordinates: {
      lat: 31.0399,
      lng: -104.8316,
    },
  },
  {
    portCode: "5562",
    city: "Midland Texas Border Patrol, Midland, Texas",
    coordinates: {
      lat: 31.9973,
      lng: -102.0779,
    },
  },
  {
    portCode: "5582",
    city: "Midland International Airport, Midland, Texas",
    coordinates: {
      lat: 31.9422,
      lng: -102.2019,
    },
  },
  {
    portCode: "5583",
    city: "Fort Worth Alliance Airport, Fort Worth, Texas",
    coordinates: {
      lat: 32.9879,
      lng: -97.3188,
    },
  },
  {
    portCode: "5584",
    city: "Addison Airport, Addison, Texas",
    coordinates: {
      lat: 32.9686,
      lng: -96.8363,
    },
  },
  {
    portCode: "5585",
    city: "Collin County Regional Airport, McKinney, Texas",
    coordinates: {
      lat: 33.1959,
      lng: -96.6141,
    },
  },
  {
    portCode: "5586",
    city: "Ardmore Municipal Airport, Ardmore, Oklahoma",
    coordinates: {
      lat: 34.3032,
      lng: -97.0195,
    },
  },
  {
    portCode: "5587",
    city: "Kelly Field Annex, San Antonio, Texas",
    coordinates: {
      lat: 29.3842,
      lng: -98.5811,
    },
  },
  {
    portCode: "5588",
    city: "Dallas Love Field, Dallas, Texas",
    coordinates: {
      lat: 32.8471,
      lng: -96.8517,
    },
  },
  {
    portCode: "5589",
    city: "Meacham International Airport, Fort Worth, Texas",
    coordinates: {
      lat: 32.819,
      lng: -97.3623,
    },
  },
  {
    portCode: "7600",
    city: "Southwest Region, Houston, Texas",
    coordinates: {
      lat: 29.7604,
      lng: -95.3698,
    },
  },
  {
    portCode: "2501",
    city: "San Diego, California",
    coordinates: {
      lat: 32.7157,
      lng: -117.1611,
    },
  },
  {
    portCode: "2502",
    city: "Andrade, California",
    coordinates: {
      lat: 32.7195,
      lng: -114.7155,
    },
  },
  {
    portCode: "2503",
    city: "Calexico, California",
    coordinates: {
      lat: 32.6789,
      lng: -115.4989,
    },
  },
  {
    portCode: "2504",
    city: "San Ysidro, California",
    coordinates: {
      lat: 32.5521,
      lng: -117.0431,
    },
  },
  {
    portCode: "2505",
    city: "Tecate, California",
    coordinates: {
      lat: 32.5783,
      lng: -116.626,
    },
  },
  {
    portCode: "2506",
    city: "Otay Mesa, California",
    coordinates: {
      lat: 32.5573,
      lng: -116.9581,
    },
  },
  {
    portCode: "2507",
    city: "Calexico-East, California",
    coordinates: {
      lat: 32.6789,
      lng: -115.4989,
    },
  },
  {
    portCode: "2520",
    city: "Oceanside, California",
    coordinates: {
      lat: 33.1959,
      lng: -117.3795,
    },
  },
  {
    portCode: "2521",
    city: "El Centro, Oceanside, California",
    coordinates: {
      lat: 32.792,
      lng: -115.5631,
    },
  },
  {
    portCode: "2550",
    city: "Border Patrol Sector HQ, El Centro, California",
    coordinates: {
      lat: 32.792,
      lng: -115.5631,
    },
  },
  {
    portCode: "2551",
    city: "Indio California Border Patrol, Indio, California",
    coordinates: {
      lat: 33.7206,
      lng: -116.2156,
    },
  },
  {
    portCode: "2552",
    city: "El Centro California Border Patrol, El Centro, California",
    coordinates: {
      lat: 32.792,
      lng: -115.5631,
    },
  },
  {
    portCode: "2553",
    city: "Riverside California Border Patrol, Riverside, California",
    coordinates: {
      lat: 33.9806,
      lng: -117.3755,
    },
  },
  {
    portCode: "2554",
    city: "Calexico California Border Patrol, Calexico, California",
    coordinates: {
      lat: 32.6789,
      lng: -115.4989,
    },
  },
  {
    portCode: "2582",
    city: "Otay-Cross Border User Fee Airport, San Diego, California",
    coordinates: {
      lat: 32.7157,
      lng: -117.1611,
    },
  },
  {
    portCode: "2704",
    city: "Los Angeles, California",
    coordinates: {
      lat: 34.0522,
      lng: -118.2437,
    },
  },
  {
    portCode: "2705",
    city: "Santa Ana/Orange, Santa Ana, California",
    coordinates: {
      lat: 33.7455,
      lng: -117.8677,
    },
  },
  {
    portCode: "2706",
    city: "Bakersfield, California",
    coordinates: {
      lat: 35.3733,
      lng: -119.0187,
    },
  },
  {
    portCode: "2707",
    city: "Port San Luis Harbor, Avila Beach, California",
    coordinates: {
      lat: 35.1805,
      lng: -120.7321,
    },
  },
  {
    portCode: "2709",
    city: "Long Beach, California",
    coordinates: {
      lat: 33.7701,
      lng: -118.1937,
    },
  },
  {
    portCode: "2712",
    city: "Ventura, California",
    coordinates: {
      lat: 34.2746,
      lng: -119.229,
    },
  },
  {
    portCode: "2713",
    city: "Port Hueneme, California",
    coordinates: {
      lat: 34.1478,
      lng: -119.1951,
    },
  },
  {
    portCode: "2714",
    city: "San Bernardino, California",
    coordinates: {
      lat: 34.1083,
      lng: -117.2898,
    },
  },
  {
    portCode: "2715",
    city: "Capitan, California",
    coordinates: {
      lat: 35.4847,
      lng: -121.1438,
    },
  },
  {
    portCode: "2719",
    city: "Morro Bay, California",
    coordinates: {
      lat: 35.3658,
      lng: -120.8499,
    },
  },
  {
    portCode: "2720",
    city: "Los Angeles International Airport, Los Angeles, California",
    coordinates: {
      lat: 33.9416,
      lng: -118.4085,
    },
  },
  {
    portCode: "2721",
    city: "Ontario International Airport, Ontario, California",
    coordinates: {
      lat: 34.056,
      lng: -117.6012,
    },
  },
  {
    portCode: "2722",
    city: "Las Vegas, NV",
    coordinates: {
      lat: 36.1699,
      lng: -115.1398,
    },
  },
  {
    portCode: "2755",
    city: "Los Angeles Alternate, Los Angeles, California",
    coordinates: {
      lat: 34.0522,
      lng: -118.2437,
    },
  },
  {
    portCode: "2775",
    city: "Fedex Corp, Los Angeles, California",
    coordinates: {
      lat: 34.0522,
      lng: -118.2437,
    },
  },
  {
    portCode: "2776",
    city: "International Bonded Couriers (IBC), Los Angeles, California",
    coordinates: {
      lat: 34.0522,
      lng: -118.2437,
    },
  },
  {
    portCode: "2777",
    city: "Micom, Inglewood, California",
    coordinates: {
      lat: 33.9617,
      lng: -118.3531,
    },
  },
  {
    portCode: "2781",
    city: "Palm Springs User Fee, Palm Springs, California",
    coordinates: {
      lat: 33.8303,
      lng: -116.5453,
    },
  },
  {
    portCode: "2782",
    city: "San Bernardino International Airport, San Bernardino, California",
    coordinates: {
      lat: 34.1083,
      lng: -117.2898,
    },
  },
  {
    portCode: "2783",
    city: "Southern California Logistics Airport, Victorville, California",
    coordinates: {
      lat: 34.5972,
      lng: -117.3825,
    },
  },
  {
    portCode: "2786",
    city: "Meadows Field Airport, Bakersfield, California",
    coordinates: {
      lat: 35.433,
      lng: -119.0566,
    },
  },
  {
    portCode: "2787",
    city: "John Wayne User Fee Airport, Santa Ana, California",
    coordinates: {
      lat: 33.6757,
      lng: -117.8682,
    },
  },
  {
    portCode: "2788",
    city: "Van Nuys User Fee Airport, Van Nuys, California",
    coordinates: {
      lat: 34.209,
      lng: -118.4897,
    },
  },
  {
    portCode: "2791",
    city: "DHL Hub, Los Angeles, California",
    coordinates: {
      lat: 34.0522,
      lng: -118.2437,
    },
  },
  {
    portCode: "2795",
    city: "UPS, Ontario, California",
    coordinates: {
      lat: 34.056,
      lng: -117.6012,
    },
  },
  {
    portCode: "2801",
    city: "San Francisco International Airport, San Francisco, California",
    coordinates: {
      lat: 37.6213,
      lng: -122.379,
    },
  },
  {
    portCode: "2802",
    city: "Eureka, California",
    coordinates: {
      lat: 40.8021,
      lng: -124.1637,
    },
  },
  {
    portCode: "2803",
    city: "Fresno, California",
    coordinates: {
      lat: 36.7378,
      lng: -119.7871,
    },
  },
  {
    portCode: "2805",
    city: "Monterey, California",
    coordinates: {
      lat: 36.6002,
      lng: -121.8947,
    },
  },
  {
    portCode: "2809",
    city: "San Francisco, California",
    coordinates: {
      lat: 37.7749,
      lng: -122.4194,
    },
  },
  {
    portCode: "2811",
    city: "Oakland, California",
    coordinates: {
      lat: 37.8044,
      lng: -122.2712,
    },
  },
  {
    portCode: "2812",
    city: "Richmond, California",
    coordinates: {
      lat: 37.9358,
      lng: -122.3478,
    },
  },
  {
    portCode: "2815",
    city: "Crockett, California",
    coordinates: {
      lat: 38.0538,
      lng: -122.2172,
    },
  },
  {
    portCode: "2817",
    city: "Redding, California",
    coordinates: {
      lat: 40.5865,
      lng: -122.3917,
    },
  },
  {
    portCode: "2818",
    city: "San Francisco IAP, San Francisco, California",
    coordinates: {
      lat: 37.6213,
      lng: -122.379,
    },
  },
  {
    portCode: "2819",
    city: "Ogden, Utah",
    coordinates: {
      lat: 41.223,
      lng: -111.9738,
    },
  },
  {
    portCode: "2820",
    city: "Martinez, California",
    coordinates: {
      lat: 38.0194,
      lng: -122.1341,
    },
  },
  {
    portCode: "2821",
    city: "Redwood City, California",
    coordinates: {
      lat: 37.4852,
      lng: -122.2364,
    },
  },
  {
    portCode: "2822",
    city: "Provo, Utah",
    coordinates: {
      lat: 40.2338,
      lng: -111.6585,
    },
  },
  {
    portCode: "2823",
    city: "St. George, Utah",
    coordinates: {
      lat: 37.0965,
      lng: -113.5684,
    },
  },
  {
    portCode: "2827",
    city: "Selby, California",
    coordinates: {
      lat: 38.0514,
      lng: -122.2366,
    },
  },
  {
    portCode: "2828",
    city: "San Joaquin River, California",
    coordinates: {
      lat: 37.9514,
      lng: -121.2843,
    },
  },
  {
    portCode: "2829",
    city: "San Pablo Bay, California",
    coordinates: {
      lat: 38.094,
      lng: -122.3812,
    },
  },
  {
    portCode: "2830",
    city: "Carquinez Strait, California",
    coordinates: {
      lat: 38.0524,
      lng: -122.225,
    },
  },
  {
    portCode: "2831",
    city: "Suisin Bay, California",
    coordinates: {
      lat: 38.066,
      lng: -122.086,
    },
  },
  {
    portCode: "2833",
    city: "Reno, Nevada",
    coordinates: {
      lat: 39.5296,
      lng: -119.8138,
    },
  },
  {
    portCode: "2834",
    city: "San Jose International Airport, California",
    coordinates: {
      lat: 37.3639,
      lng: -121.9289,
    },
  },
  {
    portCode: "2835",
    city: "Sacramento International Airport, Sacramento, California",
    coordinates: {
      lat: 38.6951,
      lng: -121.5901,
    },
  },
  {
    portCode: "2850",
    city: "Border Patrol Sector HQ, Dublin, California",
    coordinates: {
      lat: 37.7022,
      lng: -121.9358,
    },
  },
  {
    portCode: "2851",
    city: "Sacramento Border Patrol, Sacramento, California",
    coordinates: {
      lat: 38.5816,
      lng: -121.4944,
    },
  },
  {
    portCode: "2852",
    city: "Stockton California Border Patrol, Stockton, California",
    coordinates: {
      lat: 37.9577,
      lng: -121.2908,
    },
  },
  {
    portCode: "2853",
    city: "Fresno California Border Patrol, Fresno, California",
    coordinates: {
      lat: 36.7378,
      lng: -119.7871,
    },
  },
  {
    portCode: "2854",
    city: "Bakersfield California Border Patrol, Bakersfield, California",
    coordinates: {
      lat: 35.3733,
      lng: -119.0187,
    },
  },
  {
    portCode: "2855",
    city: "San Luis Obispo Border Patrol, San Luis Obispo, California",
    coordinates: {
      lat: 35.2828,
      lng: -120.6596,
    },
  },
  {
    portCode: "2895",
    city: "Fedex Courier Hub Facility, Oakland, California",
    coordinates: {
      lat: 37.8044,
      lng: -122.2712,
    },
  },
  {
    portCode: "2901",
    city: "Astoria, Oregon",
    coordinates: {
      lat: 46.1879,
      lng: -123.8313,
    },
  },
  {
    portCode: "2902",
    city: "Newport, Oregon",
    coordinates: {
      lat: 44.6368,
      lng: -124.0534,
    },
  },
  {
    portCode: "2903",
    city: "Coos Bay, Oregon",
    coordinates: {
      lat: 43.3665,
      lng: -124.2179,
    },
  },
  {
    portCode: "2904",
    city: "Portland, Oregon",
    coordinates: {
      lat: 45.5051,
      lng: -122.675,
    },
  },
  {
    portCode: "2905",
    city: "Longview, Washington",
    coordinates: {
      lat: 46.1382,
      lng: -122.9382,
    },
  },
  {
    portCode: "2907",
    city: "Boise, Idaho",
    coordinates: {
      lat: 43.615,
      lng: -116.2023,
    },
  },
  {
    portCode: "2908",
    city: "Vancouver, Washington",
    coordinates: {
      lat: 45.6387,
      lng: -122.6615,
    },
  },
  {
    portCode: "2909",
    city: "Kalama, Washington",
    coordinates: {
      lat: 46.0082,
      lng: -122.8446,
    },
  },
  {
    portCode: "2910",
    city: "Portland International Airport, Portland, Oregon",
    coordinates: {
      lat: 45.5898,
      lng: -122.5951,
    },
  },
  {
    portCode: "2911",
    city: "Twin Falls, Idaho",
    coordinates: {
      lat: 42.5629,
      lng: -114.4609,
    },
  },
  {
    portCode: "2912",
    city: "Idaho Falls, Idaho",
    coordinates: {
      lat: 43.4917,
      lng: -112.0337,
    },
  },
  {
    portCode: "2913",
    city: "Pueblo, Colorado",
    coordinates: {
      lat: 38.2544,
      lng: -104.6091,
    },
  },
  {
    portCode: "2914",
    city: "Durango, Colorado",
    coordinates: {
      lat: 37.2753,
      lng: -107.8801,
    },
  },
  {
    portCode: "2915",
    city: "Alamosa, Colorado",
    coordinates: {
      lat: 37.4694,
      lng: -105.87,
    },
  },
  {
    portCode: "2916",
    city: "Grand Junction, Colorado",
    coordinates: {
      lat: 39.0639,
      lng: -108.5506,
    },
  },
  {
    portCode: "2917",
    city: "Glenwood Springs, Colorado",
    coordinates: {
      lat: 39.5505,
      lng: -107.3248,
    },
  },
  {
    portCode: "2918",
    city: "Craig, Colorado",
    coordinates: {
      lat: 40.5153,
      lng: -107.5469,
    },
  },
  {
    portCode: "2919",
    city: "Brush, Colorado",
    coordinates: {
      lat: 40.258,
      lng: -103.6213,
    },
  },
  {
    portCode: "2920",
    city: "Cheyenne, Wyoming",
    coordinates: {
      lat: 41.14,
      lng: -104.8202,
    },
  },
  {
    portCode: "2921",
    city: "Casper, Wyoming",
    coordinates: {
      lat: 42.8501,
      lng: -106.3252,
    },
  },
  {
    portCode: "2983",
    city: "Hillsboro Airport, Hillsboro, Oregon",
    coordinates: {
      lat: 45.5408,
      lng: -122.9495,
    },
  },
  {
    portCode: "3001",
    city: "Seattle, Washington",
    coordinates: {
      lat: 47.6062,
      lng: -122.3321,
    },
  },
  {
    portCode: "3002",
    city: "Tacoma, Washington",
    coordinates: {
      lat: 47.2529,
      lng: -122.4443,
    },
  },
  {
    portCode: "3003",
    city: "Aberdeen, Washington",
    coordinates: {
      lat: 46.9754,
      lng: -123.8157,
    },
  },
  {
    portCode: "3004",
    city: "Blaine, Washington",
    coordinates: {
      lat: 48.9932,
      lng: -122.7473,
    },
  },
  {
    portCode: "3005",
    city: "Bellingham, Washington",
    coordinates: {
      lat: 48.7519,
      lng: -122.4787,
    },
  },
  {
    portCode: "3006",
    city: "Everett, Washington",
    coordinates: {
      lat: 47.9789,
      lng: -122.2021,
    },
  },
  {
    portCode: "3007",
    city: "Port Angeles, Washington",
    coordinates: {
      lat: 48.1181,
      lng: -123.4307,
    },
  },
  {
    portCode: "3008",
    city: "Port Townsend, Washington",
    coordinates: {
      lat: 48.117,
      lng: -122.7604,
    },
  },
  {
    portCode: "3009",
    city: "Sumas, Washington",
    coordinates: {
      lat: 49.0034,
      lng: -122.2646,
    },
  },
  {
    portCode: "3010",
    city: "Anacortes, Washington",
    coordinates: {
      lat: 48.5126,
      lng: -122.6127,
    },
  },
  {
    portCode: "3011",
    city: "Nighthawk, Washington",
    coordinates: {
      lat: 48.9112,
      lng: -119.6162,
    },
  },
  {
    portCode: "3012",
    city: "Danville, Washington",
    coordinates: {
      lat: 48.9989,
      lng: -118.5085,
    },
  },
  {
    portCode: "3013",
    city: "Ferry, Washington",
    coordinates: {
      lat: 48.5883,
      lng: -118.4338,
    },
  },
  {
    portCode: "3014",
    city: "Friday Harbor, Washington",
    coordinates: {
      lat: 48.5357,
      lng: -123.0363,
    },
  },
  {
    portCode: "3015",
    city: "Boundary, Washington",
    coordinates: {
      lat: 48.9955,
      lng: -116.503,
    },
  },
  {
    portCode: "3016",
    city: "Laurier, Washington",
    coordinates: {
      lat: 48.9813,
      lng: -118.2194,
    },
  },
  {
    portCode: "3017",
    city: "Point Roberts, Washington",
    coordinates: {
      lat: 48.9856,
      lng: -123.0739,
    },
  },
  {
    portCode: "3018",
    city: "Kenmore Air Harbor, Washington",
    coordinates: {
      lat: 47.7543,
      lng: -122.247,
    },
  },
  {
    portCode: "3019",
    city: "Oroville, Washington",
    coordinates: {
      lat: 48.9409,
      lng: -119.4331,
    },
  },
  {
    portCode: "3020",
    city: "Frontier, Washington",
    coordinates: {
      lat: 48.9872,
      lng: -116.5002,
    },
  },
  {
    portCode: "3022",
    city: "Spokane, Washington",
    coordinates: {
      lat: 47.6588,
      lng: -117.426,
    },
  },
  {
    portCode: "3023",
    city: "Lynden, Washington",
    coordinates: {
      lat: 48.9464,
      lng: -122.4521,
    },
  },
  {
    portCode: "3024",
    city: "Wenatchee, Washington",
    coordinates: {
      lat: 47.4235,
      lng: -120.3103,
    },
  },
  {
    portCode: "3025",
    city: "Metaline Falls, Washington",
    coordinates: {
      lat: 48.8607,
      lng: -117.368,
    },
  },
  {
    portCode: "3026",
    city: "Olympia, Washington",
    coordinates: {
      lat: 47.0379,
      lng: -122.9007,
    },
  },
  {
    portCode: "3028",
    city: "Yakima, Washington",
    coordinates: {
      lat: 46.6021,
      lng: -120.5059,
    },
  },
  {
    portCode: "3029",
    city: "Seattle-Tacoma International Airport, Seattle, Washington",
    coordinates: {
      lat: 47.4502,
      lng: -122.3088,
    },
  },
  {
    portCode: "3030",
    city: "Pasco, Washington",
    coordinates: {
      lat: 46.2396,
      lng: -119.1006,
    },
  },
  {
    portCode: "3050",
    city: "Border Patrol Sector HQ, Blaine, Washington",
    coordinates: {
      lat: 48.9932,
      lng: -122.7473,
    },
  },
  {
    portCode: "3051",
    city: "Blaine Washington Border Patrol, Blaine, Washington",
    coordinates: {
      lat: 48.9932,
      lng: -122.7473,
    },
  },
  {
    portCode: "3052",
    city: "Lynden Washington Border Patrol, Lynden, Washington",
    coordinates: {
      lat: 48.9464,
      lng: -122.4521,
    },
  },
  {
    portCode: "3053",
    city: "Bellingham Border Patrol, Bellingham, Washington",
    coordinates: {
      lat: 48.7519,
      lng: -122.4787,
    },
  },
  {
    portCode: "3054",
    city: "Port Angeles Border Patrol, Port Angeles, Washington",
    coordinates: {
      lat: 48.1181,
      lng: -123.4307,
    },
  },
  {
    portCode: "3071",
    city: "UPS, Seattle, Washington",
    coordinates: {
      lat: 47.6062,
      lng: -122.3321,
    },
  },
  {
    portCode: "3072",
    city: "Avion Brokers @ Seatac, Seatac, Washington",
    coordinates: {
      lat: 47.4502,
      lng: -122.3088,
    },
  },
  {
    portCode: "3073",
    city: "DHL Worldwide Express, Seatac, Washington",
    coordinates: {
      lat: 47.4502,
      lng: -122.3088,
    },
  },
  {
    portCode: "3074",
    city: "Airborne Express @ Seatac, Seatac, Washington",
    coordinates: {
      lat: 47.4502,
      lng: -122.3088,
    },
  },
  {
    portCode: "3082",
    city: "Grant County Airport, Moses Lake, Washington",
    coordinates: {
      lat: 47.2075,
      lng: -119.3192,
    },
  },
  {
    portCode: "3095",
    city: "UPS Courier Hub, Seattle, Washington",
    coordinates: {
      lat: 47.6062,
      lng: -122.3321,
    },
  },
  {
    portCode: "3099",
    city: "Port of Puget Sound, Seattle, Washington",
    coordinates: {
      lat: 47.6062,
      lng: -122.3321,
    },
  },
  {
    portCode: "3101",
    city: "Juneau, Alaska",
    coordinates: {
      lat: 58.3019,
      lng: -134.4197,
    },
  },
  {
    portCode: "3102",
    city: "Ketchikan, Alaska",
    coordinates: {
      lat: 55.3422,
      lng: -131.6461,
    },
  },
  {
    portCode: "3103",
    city: "Skagway, Alaska",
    coordinates: {
      lat: 59.4583,
      lng: -135.3139,
    },
  },
  {
    portCode: "3104",
    city: "Alcan, Alaska",
    coordinates: {
      lat: 62.6534,
      lng: -141.1516,
    },
  },
  {
    portCode: "3105",
    city: "Wrangell, Alaska",
    coordinates: {
      lat: 56.4719,
      lng: -132.376,
    },
  },
  {
    portCode: "3106",
    city: "Dalton Cache, Alaska",
    coordinates: {
      lat: 59.3558,
      lng: -136.3546,
    },
  },
  {
    portCode: "3111",
    city: "Fairbanks, Alaska",
    coordinates: {
      lat: 64.8378,
      lng: -147.7164,
    },
  },
  {
    portCode: "3115",
    city: "Sitka, Alaska",
    coordinates: {
      lat: 57.0531,
      lng: -135.33,
    },
  },
  {
    portCode: "3124",
    city: "Pelican, Alaska",
    coordinates: {
      lat: 57.9553,
      lng: -136.2275,
    },
  },
  {
    portCode: "3126",
    city: "Anchorage, Alaska",
    coordinates: {
      lat: 61.2181,
      lng: -149.9003,
    },
  },
  {
    portCode: "3181",
    city: "Saint Paul Airport, Anchorage, Alaska",
    coordinates: {
      lat: 61.2181,
      lng: -149.9003,
    },
  },
  {
    portCode: "3195",
    city: "Federal Express Courier Hub, Anchorage, Alaska",
    coordinates: {
      lat: 61.2181,
      lng: -149.9003,
    },
  },
  {
    portCode: "3196",
    city: "UPS Courier Hub, Anchorage, Alaska",
    coordinates: {
      lat: 61.2181,
      lng: -149.9003,
    },
  },
  {
    portCode: "3202",
    city: "Hilo, Hawaii",
    coordinates: {
      lat: 19.7074,
      lng: -155.0885,
    },
  },
  {
    portCode: "3203",
    city: "Kahului, Hawaii",
    coordinates: {
      lat: 20.8947,
      lng: -156.47,
    },
  },
  {
    portCode: "3204",
    city: "Nawiliwili-Port Allen, Hawaii",
    coordinates: {
      lat: 21.9605,
      lng: -159.35,
    },
  },
  {
    portCode: "3205",
    city: "Honolulu International Airport, Honolulu, Hawaii",
    coordinates: {
      lat: 21.3187,
      lng: -157.922,
    },
  },
  {
    portCode: "3206",
    city: "Kona, Hawaii",
    coordinates: {
      lat: 19.6393,
      lng: -155.9969,
    },
  },
  {
    portCode: "3207",
    city: "Guam Preclearance, Tamuning, Guam",
    coordinates: {
      lat: 13.5004,
      lng: 144.7961,
    },
  },
  {
    portCode: "3208",
    city: "North Mariana Island, Guam",
    coordinates: {
      lat: 15.0979,
      lng: 145.6739,
    },
  },
  {
    portCode: "3209",
    city: "American Samoa, Guam",
    coordinates: {
      lat: -14.2781,
      lng: -170.702,
    },
  },
  {
    portCode: "3210",
    city: "Guam, Maite, Guam",
    coordinates: {
      lat: 13.5004,
      lng: 144.7961,
    },
  },
  {
    portCode: "3211",
    city: "Saipan, Tamuning, Guam",
    coordinates: {
      lat: 15.1778,
      lng: 145.7509,
    },
  },
  {
    portCode: "3212",
    city: "Tinian, Tamuning, Guam",
    coordinates: {
      lat: 15.0,
      lng: 145.6176,
    },
  },
  {
    portCode: "3213",
    city: "Rota, Tamuning, Guam",
    coordinates: {
      lat: 14.1425,
      lng: 145.2046,
    },
  },
  {
    portCode: "3279",
    city: "FedEx ECCF, Honolulu, Hawaii",
    coordinates: {
      lat: 21.3069,
      lng: -157.8583,
    },
  },
  {
    portCode: "5650",
    city: "Border Patrol Sector HQ, Chula Vista, California",
    coordinates: {
      lat: 32.6401,
      lng: -117.0842,
    },
  },
  {
    portCode: "5651",
    city: "San Clemente Border Patrol, California",
    coordinates: {
      lat: 33.4269,
      lng: -117.6119,
    },
  },
  {
    portCode: "5652",
    city: "Temecula Border Patrol, California",
    coordinates: {
      lat: 33.4936,
      lng: -117.1484,
    },
  },
  {
    portCode: "5653",
    city: "Brown Field Border Patrol, San Diego, California",
    coordinates: {
      lat: 32.57,
      lng: -116.9806,
    },
  },
  {
    portCode: "5654",
    city: "Chula Vista Border Patrol, California",
    coordinates: {
      lat: 32.6401,
      lng: -117.0842,
    },
  },
  {
    portCode: "5655",
    city: "Campo (Area II) Border Patrol, Campo, California",
    coordinates: {
      lat: 32.6051,
      lng: -116.4684,
    },
  },
  {
    portCode: "5656",
    city: "Boulevard California Border Patrol, Boulevard, California",
    coordinates: {
      lat: 32.6678,
      lng: -116.2782,
    },
  },
  {
    portCode: "5657",
    city: "El Cajon California Border Patrol, El Cajon, California",
    coordinates: {
      lat: 32.7948,
      lng: -116.9625,
    },
  },
  {
    portCode: "5658",
    city: "Imperial Beach Border Patrol, Imperial Beach, California",
    coordinates: {
      lat: 32.5839,
      lng: -117.1131,
    },
  },
  {
    portCode: "5659",
    city: "North County Border Patrol, San Diego, California",
    coordinates: {
      lat: 32.7157,
      lng: -117.1611,
    },
  },
  {
    portCode: "5660",
    city: "Old Town San Diego Border Patrol, San Diego, California",
    coordinates: {
      lat: 32.7542,
      lng: -117.1965,
    },
  },
  {
    portCode: "5661",
    city: "Laredo Field Office, Chula Vista, California",
    coordinates: {
      lat: 32.6401,
      lng: -117.0842,
    },
  },
];
