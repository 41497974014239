import React from 'react';
import { DateRange, DateRangeProps } from 'react-date-range';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';

// @ts-ignore
const DateRangeWrapper = (props: DateRangeProps) => {
  return (
    <div className="date-range-wrapper">
      {/* @ts-ignore */}
      <DateRange {...props} />
    </div>
  );
};

export default DateRangeWrapper; 