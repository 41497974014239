import React, { useState } from "react";
import {
  Box,
  CircularProgress,
  FormControlLabel,
  Grid,
  IconButton,
  InputAdornment,
  LinearProgress,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { DataTableProps } from "../../@types/Data";
import { DataGrid, GridColDef, GridPaginationModel } from "@mui/x-data-grid";
import Upload from "../upload/UploadFile";
import DownloadFileIcon from "./CbpDownload";
import { useForm } from "react-hook-form";
import { FormInputText } from "../form/FormInputText";
import { IoSearch } from "react-icons/io5";
import { Control, Controller } from "react-hook-form";
import { FormSearchInputText } from "../form/SearchInput";
import NoDataImage from "../../assets/images/no-data.png";

const styles = () => ({
  title: {
    margin: "0px 0px 4px 0px",
  },
  header: {
    margin: "0px 0px 16px 0px",
  },
  loader: { width: "100%", marginTop: 10, textAlign: "center" },
});
type SearchInput = {
  keyword: string;
};

const CbpTable: React.FC<DataTableProps> = ({
  data,
  page,
  totalData,
  dataPerPage,
  fetchData,
  isLoading,
}) => {
  const classes = styles();
  const methods = useForm<SearchInput>({ defaultValues: { keyword: "" } });
  const { handleSubmit, control } = methods;
  const onPageChange = (newPage: number) => {
    fetchData({ offset: newPage });
  };

  const [loading, setLoading] = useState<boolean>(false);

  const handleLoading = (value: boolean) => setLoading(value);

  const columns: GridColDef[] = [
    {
      field: "filerCodeAndEntryNo",
      headerName: "Filer Code / Entry Number",
      flex: 1,
    },
    {
      field: "File",
      headerName: "Download PDF",
      flex: 1,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => {
        return (
          <Grid
            className="d-flex justify-content-between align-items-center"
            style={{ cursor: "pointer" }}
          >
            <DownloadFileIcon
              filerCodeAndEntryNo={params.row.filerCodeAndEntryNo}
              userId={params.row.userId}
            />
          </Grid>
        );
      },
    },
  ];

  const rows =
    data?.map((row: any) => {
      return {
        id: row["_id"]["$oid"],
        ...row,
      };
    }) ?? [];

  return (
    <>
      <Stack
        direction={{ sm: "column", md: "row" }}
        alignItems={"center"}
        justifyContent={"space-between"}
        sx={{ mb: 3 }}
      >
        <Typography
          color="#4E535A"
          fontWeight={500}
          fontSize={26}
          mb={{ xs: 2, md: 0 }}
        >
          Document Link
        </Typography>
        <Stack
          direction={{ xs: "column", md: "row" }}
          alignItems={"center"}
          justifyContent={"space-between"}
          spacing={1}
        >
          <Upload
            fetchData={fetchData}
            loading={loading}
            handleLoading={handleLoading}
            ext={["pdf"]}
            api="pdf/upload"
            fileDescriptions={[
              {
                name: "CBP 7501",
                id: "file1",
              },
            ]}
          />
          <FormSearchInputText
            type="text"
            size="medium"
            placeholder="Search"
            onChange={(e: any) => {
              fetchData({ offset: 0, searchKeyword: e.target.value });
            }}
          />
        </Stack>
      </Stack>
      {isLoading ? (
        <Box sx={classes.loader}>
          <CircularProgress />
        </Box>
      ) : rows?.length > 0 ? (
        <Box style={{ height: 111 + 52 * (rows?.length + 1), width: "100%" }}>
          <DataGrid
            rows={rows}
            rowCount={totalData}
            loading={isLoading}
            disableRowSelectionOnClick
            columns={columns}
            slots={{
              noRowsOverlay: () => (
                <Stack
                  height="100%"
                  alignItems="center"
                  justifyContent="center"
                >
                  <img src={NoDataImage} alt="" width="200px" />
                  <Typography
                    color="#4E535A"
                    fontSize={32}
                    fontWeight={500}
                    mt={2}
                  >
                    No Rows Found!
                  </Typography>
                </Stack>
              ),
              noResultsOverlay: () => (
                <Stack
                  height="100%"
                  alignItems="center"
                  justifyContent="center"
                >
                  <img src={NoDataImage} alt="" width="200px" />
                  <Typography
                    color="#4E535A"
                    fontSize={32}
                    fontWeight={500}
                    mt={2}
                  >
                    No Result Found!
                  </Typography>
                </Stack>
              ),
            }}
            sx={{
              border: "1px solid #F4F4F4",
              borderRadius: "10px 10px 0px 0px",
              ".MuiDataGrid-columnSeparator": {
                display: "none",
              },
              ".MuiDataGrid-columnHeader": {
                background: "rgba(246, 246, 248, 0.4)",
                fontWeight: 500,
                color: "#4E535A",
                fontSize: 14,
                px: 2.5,
              },
              ".MuiDataGrid-cell": {
                borderBottom: "1px solid #F4F4F4",
                fontSize: 14,
                color: "#4E535A",
                fontWeight: 400,
                px: 2.5,
              },
              ".MuiDataGrid-columnHeaders": {
                borderBottom: "1px solid #F4F4F4",
              },
              ".MuiTablePagination-displayedRows": {
                fontSize: 14,
                color: "#4E535A",
                fontWeight: 400,
              },
            }}
          />
        </Box>
      ) : (
        <Stack
          height="100%"
          alignItems="center"
          justifyContent="center"
          sx={{ border: "1px solid #F4F4F4", py: 3, borderRadius: "10px" }}
        >
          <img src={NoDataImage} alt="" width="200px" />
          <Typography color="#4E535A" fontSize={32} fontWeight={500} mt={2}>
            No Rows Found!
          </Typography>
        </Stack>
      )}
    </>
  );
};

export default CbpTable;
