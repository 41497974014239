import { Box, Button, MenuProps } from "@mui/material";
import React, { ReactNode, useState } from "react";
import { IoMdCalendar } from "react-icons/io";
import GlobalText from "../../../typography";
import { MdKeyboardArrowDown } from "react-icons/md";
import { DateRangePicker } from "react-date-range";
import { IKeyValue } from "../../../../@types/GlobalMessageContext";
import {
  addDays,
  endOfMonth,
  endOfWeek,
  startOfMonth,
  startOfWeek,
} from "date-fns";
import moment from "moment";
import DateRangePickerWrapper from '../../../date-picker/DateRangePickerWrapper';

interface IDateRangePickerFilter {
  StyledMenu: any;
  handleFilterLabel: (value: string) => void;
  handleDateFilter: (value: IKeyValue) => void;
  openDateFilter: null | HTMLElement;
  setOpenDateFilter: (value: null | HTMLElement) => void;
}

const DateRangePickerFilter = ({
  StyledMenu,
  handleFilterLabel,
  handleDateFilter,
  openDateFilter,
  setOpenDateFilter,
}: IDateRangePickerFilter) => {
  const [label, setLabel] = useState<string>("Today");
  const [state, setState] = useState<IKeyValue[]>([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: "selection",
    },
  ]);

  const isOpenDateFilter = Boolean(openDateFilter);

  const handleCloseDateFilter = () => {
    setOpenDateFilter(null);
  };
  const handleSelect = (ranges: any) => {
    const selectedRange = ranges.selection;

    console.log("Selected Range:", selectedRange);
    setState([selectedRange]);

    const matchedRange = customStaticRanges.find(
      (range) =>
        range.range().startDate.getTime() ===
          selectedRange.startDate.getTime() &&
        range.range().endDate.getTime() === selectedRange.endDate.getTime()
    );

    if (matchedRange) {
      setLabel(matchedRange.label);
      handleFilterLabel(matchedRange.label);
      console.log("Selected predefined range:", matchedRange.label);
    }
    return;
  };

  const customStaticRanges = [
    {
      label: "Today",
      range: () => ({
        startDate: new Date(),
        endDate: new Date(),
      }),
      isSelected: (range: any) =>
        range.startDate.getTime() === new Date().getTime() &&
        range.endDate.getTime() === new Date().getTime(),
    },
    {
      label: "Yesterday",
      range: () => ({
        startDate: addDays(new Date(), -1),
        endDate: addDays(new Date(), -1),
      }),
      isSelected: (range: any) =>
        range.startDate.getTime() === addDays(new Date(), -1).getTime() &&
        range.endDate.getTime() === addDays(new Date(), -1).getTime(),
    },
    {
      label: "This Week",
      range: () => ({
        startDate: startOfWeek(new Date()),
        endDate: endOfWeek(new Date()),
      }),
      isSelected: (range: any) =>
        range.startDate.getTime() === startOfWeek(new Date()).getTime() &&
        range.endDate.getTime() === endOfWeek(new Date()).getTime(),
    },
    {
      label: "Last Week",
      range: () => ({
        startDate: startOfWeek(addDays(new Date(), -7)),
        endDate: endOfWeek(addDays(new Date(), -7)),
      }),
      isSelected: (range: any) =>
        range.startDate.getTime() ===
          startOfWeek(addDays(new Date(), -7)).getTime() &&
        range.endDate.getTime() ===
          endOfWeek(addDays(new Date(), -7)).getTime(),
    },
    {
      label: "This Month",
      range: () => ({
        startDate: startOfMonth(new Date()),
        endDate: endOfMonth(new Date()),
      }),
      isSelected: (range: any) =>
        range.startDate.getTime() === startOfMonth(new Date()).getTime() &&
        range.endDate.getTime() === endOfMonth(new Date()).getTime(),
    },
    {
      label: "Last Month",
      range: () => ({
        startDate: startOfMonth(addDays(new Date(), -30)),
        endDate: endOfMonth(addDays(new Date(), -30)),
      }),
      isSelected: (range: any) =>
        range.startDate.getTime() ===
          startOfMonth(addDays(new Date(), -30)).getTime() &&
        range.endDate.getTime() ===
          endOfMonth(addDays(new Date(), -30)).getTime(),
    },
  ];

  const handleSubmitDateFilter = () => {
    const startDate = moment(state[0]?.startDate).format("YYYY-MM-DD");
    const endDate = moment(state[0]?.endDate).format("YYYY-MM-DD");
    handleDateFilter({ startDate, endDate });
    handleDateFilterClick({ currentTarget: false });
  };

  const handleDateFilterClick = (
    event?: React.MouseEvent<HTMLElement> | any
  ) => {
    setOpenDateFilter(event!.currentTarget);
  };

  return (
    <>
      <Button
        variant="contained"
        sx={{
          background: "#fff",
          textTransform: "none",
          padding: "12px 10px",
          display: "flex",
          alignItems: "center",
          gap: 1,
        }}
        onClick={handleDateFilterClick}
      >
        <IoMdCalendar color="#8E9494" size={"16px"} />{" "}
        <GlobalText name={"Show :"} color="#8E9494" fontSize={14} />
        <GlobalText
          name={label}
          color={"#000"}
          fontSize={14}
          fontWeight={500}
        />
        <MdKeyboardArrowDown color="#8E9494" size={"20px"} />
      </Button>

      <StyledMenu
        id="demo-customized-menu-new"
        MenuListProps={{
          "aria-labelledby": "demo-customized-button-new",
        }}
        keepMounted
        anchorEl={openDateFilter}
        open={isOpenDateFilter}
        onClose={handleCloseDateFilter}
        PaperProps={{ sx: { background: "#fff" } }}
      >
        <Box sx={{ background: "#fff", width: "100%" }}>
          <DateRangePickerWrapper
            onChange={handleSelect}
            moveRangeOnFirstSelection={false}
            months={2}
            ranges={state}
            direction="horizontal"
            preventSnapRefocus={true}
            calendarFocus="backwards"
            showMonthAndYearPickers
            rangeColors={["#052223"]}
            staticRanges={customStaticRanges}
            inputRanges={[]}
          />
          <Box
            // width="100%"
            display={"flex"}
            flexWrap={"wrap"}
            justifyContent={"flex-end"}
            gap={1.5}
            sx={{ background: "#fff", paddingBottom: 2, px: 2 }}
          >
            <Box>
              <Button
                variant="text"
                sx={{
                  borderRadius: "6px",
                  border: "1px solid #E6E7E7 ",
                  background: "#fff",
                  color: "#000",
                  textTransform: "none",
                  mt: 3,
                  py: "8px",
                  // px: 2,
                  width: "5rem",
                  // width: "100%",
                }}
                onClick={handleCloseDateFilter}
              >
                Cancel
              </Button>
            </Box>
            <Box>
              <Button
                variant="text"
                sx={{
                  borderRadius: "6px",
                  background: "#0B494B",
                  color: "#fff",
                  textTransform: "none",
                  mt: 3,
                  py: "8px",
                  // px: 2,
                  width: "5rem",
                  // width: "100%",
                }}
                onClick={handleSubmitDateFilter}
              >
                Apply
              </Button>
            </Box>
          </Box>
        </Box>
      </StyledMenu>
    </>
  );
};

export default DateRangePickerFilter;
