import {
  Avatar,
  Box,
  Button,
  Divider,
  Menu,
  MenuItem,
  MenuProps,
  Typography,
  useMediaQuery,
} from "@mui/material";
import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file
import { IoMdCalendar } from "react-icons/io";
import { MdKeyboardArrowDown } from "react-icons/md";
import { Theme as MuiTheme, styled } from "@mui/material/styles";
import FilterIcon from "../../../assets/images/filter.svg";
import FilterDarkIcon from "../../../assets/images/filterDark.svg";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import AppSelect from "../../../components/select";
import {
  classification,
  countryOfExpert,
  countryOfOrigin,
  dutySpendData,
  entryNo,
  importerNo,
  tradeFlow,
} from "../../../components/filters/data";
import { DateRangePicker } from "react-date-range";
import {
  addDays,
  endOfMonth,
  endOfWeek,
  startOfMonth,
  startOfWeek,
} from "date-fns";
import { IKeyValue } from "../../../@types/GlobalMessageContext";
import moment from "moment";
import DateRangePickerWrapper from "../../../components/date-picker/DateRangePickerWrapper";

const StyledMenu = styled((props: MenuProps) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 12,
    background: "#F7F8F8",
    marginTop: theme.spacing(1),
    minWidth: 180,
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "4px 0",
    },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      // "&:active": {
      //   backgroundColor: alpha(
      //     theme.palette.primary.main,
      //     theme.palette.action.selectedOpacity
      //   ),
      // },
    },
  },
}));

type FiltersInput = {
  dutySpend: string;
  dateRange: string;
  importerNo: string;
  classification: string;
  entryNo: string;
  tradeFlow: string;
  countryOfOrigin: string;
  countryOfExpert: string;
};
const defaultFilterValues = {
  dutySpend: "",
  dateRange: "",
  importerNo: "",
  classification: "",
  entryNo: "",
  tradeFlow: "",
  countryOfOrigin: "",
  countryOfExpert: "",
};

const AnalyticsFilter = () => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const [openDateFilter, setOpenDateFilter] = useState<null | HTMLElement>(
    null
  );
  const isOpenDateFilter = Boolean(openDateFilter);
  const [state, setState] = useState<IKeyValue[]>([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: "selection",
    },
  ]);
  const [label, setLabel] = useState<string>("Today");

  const lgUp = useMediaQuery((theme: MuiTheme) => theme.breakpoints.up("lg"), {
    defaultMatches: true,
    noSsr: false,
  });
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleDateFilterClick = (
    event?: React.MouseEvent<HTMLElement> | any
  ) => {
    setOpenDateFilter(event!.currentTarget);
  };
  const methods = useForm<FiltersInput>({ defaultValues: defaultFilterValues });

  const { control, reset } = methods;

  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleCloseDateFilter = () => {
    setOpenDateFilter(null);
  };

  const handleReset = () => {
    reset(defaultFilterValues);
    handleDateFilterClick({ currentTarget: false });
  };

  const handleSelect = (ranges: any) => {
    const selectedRange = ranges.selection;

    setState([selectedRange]);

    const matchedRange = customStaticRanges.find(
      (range) =>
        range.range().startDate.getTime() ===
          selectedRange.startDate.getTime() &&
        range.range().endDate.getTime() === selectedRange.endDate.getTime()
    );

    if (matchedRange) {
      setLabel(matchedRange.label);
    }
    return;
  };

  const customStaticRanges = [
    {
      label: "Today",
      range: () => ({
        startDate: new Date(),
        endDate: new Date(),
      }),
      isSelected: (range: any) =>
        range.startDate.getTime() === new Date().getTime() &&
        range.endDate.getTime() === new Date().getTime(),
    },
    {
      label: "Yesterday",
      range: () => ({
        startDate: addDays(new Date(), -1),
        endDate: addDays(new Date(), -1),
      }),
      isSelected: (range: any) =>
        range.startDate.getTime() === addDays(new Date(), -1).getTime() &&
        range.endDate.getTime() === addDays(new Date(), -1).getTime(),
    },
    {
      label: "This Week",
      range: () => ({
        startDate: startOfWeek(new Date()),
        endDate: endOfWeek(new Date()),
      }),
      isSelected: (range: any) =>
        range.startDate.getTime() === startOfWeek(new Date()).getTime() &&
        range.endDate.getTime() === endOfWeek(new Date()).getTime(),
    },
    {
      label: "Last Week",
      range: () => ({
        startDate: startOfWeek(addDays(new Date(), -7)),
        endDate: endOfWeek(addDays(new Date(), -7)),
      }),
      isSelected: (range: any) =>
        range.startDate.getTime() ===
          startOfWeek(addDays(new Date(), -7)).getTime() &&
        range.endDate.getTime() ===
          endOfWeek(addDays(new Date(), -7)).getTime(),
    },
    {
      label: "This Month",
      range: () => ({
        startDate: startOfMonth(new Date()),
        endDate: endOfMonth(new Date()),
      }),
      isSelected: (range: any) =>
        range.startDate.getTime() === startOfMonth(new Date()).getTime() &&
        range.endDate.getTime() === endOfMonth(new Date()).getTime(),
    },
    {
      label: "Last Month",
      range: () => ({
        startDate: startOfMonth(addDays(new Date(), -30)),
        endDate: endOfMonth(addDays(new Date(), -30)),
      }),
      isSelected: (range: any) =>
        range.startDate.getTime() ===
          startOfMonth(addDays(new Date(), -30)).getTime() &&
        range.endDate.getTime() ===
          endOfMonth(addDays(new Date(), -30)).getTime(),
    },
  ];

  return (
    <Box
      display="flex"
      gap={2}
      flexWrap={"wrap"}
      alignItems={"center"}
      justifyContent={"flex-end"}
    >
      <Box {...(!lgUp && { width: "100%" })}>
        <Button
          variant="contained"
          sx={{
            background: "#fff",
            textTransform: "none",
            padding: "12px 10px",
            display: "flex",
            alignItems: "center",
            gap: 1,
          }}
          onClick={handleDateFilterClick}
        >
          <IoMdCalendar color="#8E9494" size={"16px"} />{" "}
          <Typography color="#8E9494" fontSize={14}>
            {" "}
            Show :{" "}
          </Typography>{" "}
          <Typography color={"#000"} fontSize={14} fontWeight={500}>
            {label}
          </Typography>{" "}
          <MdKeyboardArrowDown color="#8E9494" size={"20px"} />
        </Button>

        <StyledMenu
          id="demo-customized-menu-new"
          MenuListProps={{
            "aria-labelledby": "demo-customized-button-new",
          }}
          keepMounted
          anchorEl={openDateFilter}
          open={isOpenDateFilter}
          onClose={handleCloseDateFilter}
          PaperProps={{ sx: { background: "#fff" } }}
        >
          <Box sx={{ background: "#fff", width: "100%" }}>
            <DateRangePickerWrapper
              onChange={handleSelect}
              moveRangeOnFirstSelection={false}
              months={2}
              ranges={state}
              direction="horizontal"
              preventSnapRefocus={true}
              calendarFocus="backwards"
              showMonthAndYearPickers
              rangeColors={["#052223"]}
              staticRanges={customStaticRanges}
              inputRanges={[]}
            />
            <Box
              display={"flex"}
              flexWrap={"wrap"}
              justifyContent={"flex-end"}
              gap={1.5}
              sx={{ background: "#fff", paddingBottom: 2, px: 2 }}
            >
              <Box>
                <Button
                  variant="text"
                  sx={{
                    borderRadius: "6px",
                    border: "1px solid #E6E7E7 ",
                    background: "#fff",
                    color: "#000",
                    textTransform: "none",
                    mt: 3,
                    py: "8px",
                    width: "5rem",
                  }}
                  onClick={handleCloseDateFilter}
                >
                  Cancel
                </Button>
              </Box>
              <Box>
                <Button
                  variant="text"
                  sx={{
                    borderRadius: "6px",
                    background: "#0B494B",
                    color: "#fff",
                    textTransform: "none",
                    mt: 3,
                    py: "8px",
                    width: "5rem",
                  }}
                >
                  Apply
                </Button>
              </Box>
            </Box>
          </Box>
        </StyledMenu>
      </Box>
      <Box {...(!lgUp && { width: "100%" })}>
        <Button
          variant="contained"
          id="demo-customized-button"
          aria-controls={open ? "demo-customized-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
          disableElevation
          sx={{
            background: "#0B494B",
            textTransform: "none",
            padding: "12px",
            display: "flex",
            alignItems: "center",
            gap: 1,
          }}
          onClick={handleClick}
        >
          <img src={FilterIcon} alt="filter" />
          <Typography color="#fff" fontSize={14}>
            Advance Filter
          </Typography>{" "}
          {anchorEl ? (
            <Avatar
              sx={{
                width: "22px",
                height: "22px",
                background: "rgba(255, 255, 255, .3)",
              }}
            >
              <Typography color="#fff" fontWeight={600} fontSize={11}>
                5{" "}
              </Typography>
            </Avatar>
          ) : (
            ""
          )}
          <MdKeyboardArrowDown color="#fff" size={"20px"} />
        </Button>
        <StyledMenu
          id="demo-customized-menu"
          MenuListProps={{
            "aria-labelledby": "demo-customized-button",
          }}
          keepMounted
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          PaperProps={{ sx: { width: "350px", height: "70vh" } }}
        >
          <Box sx={{ background: "#fff" }}>
            <MenuItem disableRipple>
              <Box
                display={"flex"}
                alignItems={"flex-end"}
                justifyContent={"space-between"}
                width="100%"
              >
                <Box display={"flex"} alignItems={"center"} width="70%" gap={1}>
                  <img src={FilterDarkIcon} alt="filter" />
                  <Typography width="50%" color="#000" fontWeight={600}>
                    Advance Filter
                  </Typography>
                  <Avatar
                    sx={{
                      width: "21px",
                      height: "21px",
                      background: "#EA4334",
                    }}
                  >
                    <Typography color="#fff" fontWeight={600} fontSize={11}>
                      5{" "}
                    </Typography>
                  </Avatar>
                </Box>
                <Typography
                  color="#116B6F"
                  fontSize="12px"
                  sx={{ textDecoration: "underline", fontWeight: 500 }}
                  onClick={handleReset}
                >
                  Clear All
                </Typography>
              </Box>
            </MenuItem>
            <Divider />
            <Box sx={{ px: 2.5, pb: 2.5, pt: 1 }}>
              <Box marginBottom={2.5}>
                <AppSelect
                  label="Duty Spend"
                  placeholder="Select Duty Spend"
                  data={dutySpendData}
                  name="dutySpend"
                  control={control}
                  style={{
                    isBorder: true,
                    labelFontWeight: true,
                    labelFontSize: true,
                    labelColor: true,
                    iconOpacity: true,
                    iconFontSize: true,
                    iconMarginTop: true,
                    iconColor: true,
                    inputBorderColor: true,
                    inputFontSize: true,
                    txtFontWeight: true,
                    txtColor: true,
                    placeholderColor: true,
                    placeholderOpacity: true,
                  }}
                />
              </Box>
              <Box marginBottom={2.5}>
                <AppSelect
                  label="Importer No."
                  placeholder="Select Importer No"
                  data={importerNo}
                  name="importerNo"
                  control={control}
                  style={{
                    isBorder: true,
                    labelFontWeight: true,
                    labelFontSize: true,
                    labelColor: true,
                    iconOpacity: true,
                    iconFontSize: true,
                    iconMarginTop: true,
                    iconColor: true,
                    inputBorderColor: true,
                    inputFontSize: true,
                    txtFontWeight: true,
                    txtColor: true,
                    placeholderColor: true,
                    placeholderOpacity: true,
                  }}
                />
              </Box>
              <Box marginBottom={2.5}>
                <AppSelect
                  label="Classification"
                  placeholder="Select Classification"
                  data={classification}
                  name="classification"
                  control={control}
                  style={{
                    isBorder: true,
                    labelFontWeight: true,
                    labelFontSize: true,
                    labelColor: true,
                    iconOpacity: true,
                    iconFontSize: true,
                    iconMarginTop: true,
                    iconColor: true,
                    inputBorderColor: true,
                    inputFontSize: true,
                    txtFontWeight: true,
                    txtColor: true,
                    placeholderColor: true,
                    placeholderOpacity: true,
                  }}
                />
              </Box>
              <Box marginBottom={2.5}>
                <AppSelect
                  label="Trade Flow"
                  placeholder="Select Trade Flow"
                  data={tradeFlow}
                  name="tradeFlow"
                  control={control}
                  style={{
                    isBorder: true,
                    labelFontWeight: true,
                    labelFontSize: true,
                    labelColor: true,
                    iconOpacity: true,
                    iconFontSize: true,
                    iconMarginTop: true,
                    iconColor: true,
                    inputBorderColor: true,
                    inputFontSize: true,
                    txtFontWeight: true,
                    txtColor: true,
                    placeholderColor: true,
                    placeholderOpacity: true,
                  }}
                />
              </Box>
              <Box marginBottom={2.5}>
                <AppSelect
                  label="Entry No."
                  placeholder="Select Entry No."
                  data={entryNo}
                  name="entryNo"
                  control={control}
                  style={{
                    isBorder: true,
                    labelFontWeight: true,
                    labelFontSize: true,
                    labelColor: true,
                    iconOpacity: true,
                    iconFontSize: true,
                    iconMarginTop: true,
                    iconColor: true,
                    inputBorderColor: true,
                    inputFontSize: true,
                    txtFontWeight: true,
                    txtColor: true,
                    placeholderColor: true,
                    placeholderOpacity: true,
                  }}
                />
              </Box>
              <Box marginBottom={2.5}>
                <AppSelect
                  label="Country of Origin"
                  placeholder="Select Country of Origin"
                  data={countryOfOrigin}
                  name="countryOfOrigin"
                  control={control}
                  style={{
                    isBorder: true,
                    labelFontWeight: true,
                    labelFontSize: true,
                    labelColor: true,
                    iconOpacity: true,
                    iconFontSize: true,
                    iconMarginTop: true,
                    iconColor: true,
                    inputBorderColor: true,
                    inputFontSize: true,
                    txtFontWeight: true,
                    txtColor: true,
                    placeholderColor: true,
                    placeholderOpacity: true,
                  }}
                />
              </Box>
              <Box marginBottom={2.5}>
                <AppSelect
                  label="Country of Export"
                  placeholder="Select Country of Export"
                  data={countryOfExpert}
                  name="countryOfExpert"
                  control={control}
                  style={{
                    isBorder: true,
                    labelFontWeight: true,
                    labelFontSize: true,
                    labelColor: true,
                    iconOpacity: true,
                    iconFontSize: true,
                    iconMarginTop: true,
                    iconColor: true,
                    inputBorderColor: true,
                    inputFontSize: true,
                    txtFontWeight: true,
                    txtColor: true,
                    placeholderColor: true,
                    placeholderOpacity: true,
                  }}
                />
              </Box>
            </Box>
          </Box>

          <Divider />

          <Box
            width="100%"
            display={"flex"}
            flexWrap={"wrap"}
            justifyContent={"center"}
            gap={1.5}
            sx={{ background: "#F7F8F8", paddingBottom: 2 }}
          >
            <Box width={"43%"}>
              <Button
                variant="text"
                sx={{
                  borderRadius: "6px",
                  border: "1px solid #E6E7E7 ",
                  background: "#fff",
                  color: "#000",
                  textTransform: "none",
                  mt: 3,
                  py: "10px",
                  px: "12px",
                  width: "100%",
                }}
                onClick={handleClose}
              >
                Cancel
              </Button>
            </Box>
            <Box width={"43%"}>
              <Button
                variant="text"
                sx={{
                  borderRadius: "6px",
                  background: "#0B494B",
                  color: "#fff",
                  textTransform: "none",
                  mt: 3,
                  py: "10px",
                  px: "12px",
                  width: "100%",
                }}
              >
                Apply
              </Button>
            </Box>
          </Box>

          {/* </MenuItem> */}
        </StyledMenu>
      </Box>
    </Box>
  );
};

export default AnalyticsFilter;
