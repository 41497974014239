import React, { useState } from "react";
import {
  Box,
  CircularProgress,
  Grid,
  LinearProgress,
  Stack,
  Typography,
} from "@mui/material";
import { DataTableProps } from "../../@types/Data";
import { DataGrid, GridColDef, GridPaginationModel } from "@mui/x-data-grid";
import DataHelpers from "./DataHelpers";
import Upload from "../upload/UploadFile";
import Download from "../download/Download";
import UserFilter from "../filter/UserFilter";
import NoDataImage from "../../assets/images/no-data.png";

const styles = () => ({
  title: {
    margin: "0px 0px 4px 0px",
  },
  header: {
    margin: "0px 0px 16px 0px",
  },
  loader: { width: "100%", marginTop: 10, textAlign: "center" },
});
const DataTable: React.FC<DataTableProps> = ({
  data,
  page,
  totalData,
  dataPerPage,
  fetchData,
  isLoading,
  isAdmin,
  isSuperAdmin,
}) => {
  const classes = styles();
  const [paginationModel, setPaginationModel] = useState({
    page: page - 1,
    pageSize: dataPerPage,
  });

  const handlePaginationModelChange = (newModel: GridPaginationModel) => {
    setPaginationModel(newModel);
    fetchData({ offset: newModel.page + 1 });
  };

  // Remove this line
  // const handleLoading = (value: boolean) => setLoading(value);

  const columns: GridColDef[] = DataHelpers({ isAdmin: isAdmin || false }).createColumns();

  const rows =
    data?.map((row: any) => {
      return {
        id: row["_id"]["$oid"],
        ...row,
      };
    }) ?? [];

  return (
    <>
      <Stack
        direction={{ sm: "column", md: "row" }}
        alignItems={"center"}
        justifyContent={"space-between"}
        sx={{ mb: 3 }}
      >
        <Typography
          color="#4E535A"
          fontWeight={500}
          fontSize={26}
          mb={{ xs: 2, md: 0 }}
        >
          Trade Data
        </Typography>
        <Stack
          direction={{ xs: "column", md: "row" }}
          alignItems={"center"}
          justifyContent={"space-between"}
          spacing={1}
        >
          {isSuperAdmin && <UserFilter fetchData={fetchData} />}
          <Download disabled={!data || data?.length === 0} />
          <Upload fetchData={fetchData} />
        </Stack>
      </Stack>

      {isLoading ? (
        <Box sx={classes.loader}>
          <CircularProgress />
        </Box>
      ) : rows?.length > 0 ? (
        <Box style={{ height: 111 + 52 * (rows?.length + 1), width: "100%" }}>
          <DataGrid
            rows={rows}
            rowCount={totalData}
            loading={isLoading}
            paginationModel={paginationModel}
            onPaginationModelChange={handlePaginationModelChange}
            paginationMode="server"
            disableRowSelectionOnClick
            columns={columns}
            slots={{
              noRowsOverlay: () => (
                <Stack
                  height="100%"
                  alignItems="center"
                  justifyContent="center"
                >
                  <img src={NoDataImage} alt="" width="200px" />
                  <Typography
                    color="#4E535A"
                    fontSize={32}
                    fontWeight={500}
                    mt={2}
                  >
                    No Rows Found!
                  </Typography>
                </Stack>
              ),
              noResultsOverlay: () => (
                <Stack
                  height="100%"
                  alignItems="center"
                  justifyContent="center"
                >
                  <img src={NoDataImage} alt="" width="200px" />
                  <Typography
                    color="#4E535A"
                    fontSize={32}
                    fontWeight={500}
                    mt={2}
                  >
                    No Result Found!
                  </Typography>
                </Stack>
              ),
            }}
            sx={{
              border: "1px solid #F4F4F4",
              borderRadius: "10px 10px 0px 0px",
              ".MuiDataGrid-columnSeparator": {
                display: "none",
              },
              ".MuiDataGrid-columnHeader": {
                background: "rgba(246, 246, 248, 0.4)",
                fontWeight: 500,
                color: "#4E535A",
                fontSize: 14,
                px: 2.5,
              },
              ".MuiDataGrid-cell": {
                borderBottom: "1px solid #F4F4F4",
                fontSize: 14,
                color: "#4E535A",
                fontWeight: 400,
                px: 2.5,
              },
              ".MuiDataGrid-columnHeaders": {
                borderBottom: "1px solid #F4F4F4",
              },
              ".MuiDataGrid-footerContainer": {
                borderTop: "none",
              },
              ".MuiTablePagination-displayedRows": {
                fontSize: 14,
                color: "#4E535A",
                fontWeight: 400,
              },
            }}
          />
        </Box>
      ) : (
        <Stack
          height="100%"
          alignItems="center"
          justifyContent="center"
          sx={{ border: "1px solid #F4F4F4", py: 3, borderRadius: "10px" }}
        >
          <img src={NoDataImage} alt="" width="200px" />
          <Typography color="#4E535A" fontSize={32} fontWeight={500} mt={2}>
            No Rows Found!
          </Typography>
        </Stack>
      )}
    </>
  );
};

export default DataTable;
