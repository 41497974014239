import React, { useState, useMemo } from 'react';
import { ComposableMap, Geographies, Geography, Marker, ZoomableGroup } from 'react-simple-maps';
import { Box, Typography } from '@mui/material';
import { ftzCoordinates } from './FTZCoordinates';
import { usPorts } from './PortCoordinates';

const geoUrl = "https://cdn.jsdelivr.net/npm/us-atlas@3/states-10m.json";

interface FTZMapProps {
  selectedPorts: string[];
}

const FTZMap: React.FC<FTZMapProps> = ({ selectedPorts }) => {
  const [tooltipContent, setTooltipContent] = useState("");
  const [tooltipPosition, setTooltipPosition] = useState({ x: 0, y: 0 });
  const [position, setPosition] = useState({ coordinates: [-96, 38] as [number, number], zoom: 1 });

  const calculateDistance = (coord1: [number, number], coord2: [number, number]): number => {
    const [x1, y1] = coord1;
    const [x2, y2] = coord2;
    return Math.sqrt(Math.pow(x2 - x1, 2) + Math.pow(y2 - y1, 2));
  };

  const portCoordinates = useMemo(() => {
    return usPorts.reduce((acc: { [key: string]: { name: string; coordinates: [number, number] } }, port: { portCode: string; city: string; coordinates: { lat: number; lng: number } }) => {
      acc[port.portCode] = {
        name: port.city,
        coordinates: [port.coordinates.lng, port.coordinates.lat] as [number, number]
      };
      return acc;
    }, {});
  }, []);

  const handleMouseMove = (event: React.MouseEvent<SVGSVGElement>) => {
    setTooltipPosition({ x: event.clientX, y: event.clientY });
  };

  const handleZoomEnd = (event: any) => {
    setPosition(event);
  };

  const getMarkerSize = (isPort: boolean, count: number = 1) => {
    const baseSize = isPort ? 6 : 3; // Reduced base sizes for both ports and FTZ zones
    const zoomFactor = Math.max(1, position.zoom);
    return (baseSize * Math.sqrt(count)) / Math.sqrt(zoomFactor);
  };

  const getStrokeWidth = (isPort: boolean, count: number = 1) => {
    const baseWidth = isPort ? 1 : 0.5;
    const zoomFactor = Math.max(1, position.zoom);
    return (baseWidth * Math.sqrt(count)) / Math.sqrt(zoomFactor);
  };

  const groupPorts = (ports: Array<{ name: string; coordinates: [number, number] }>, threshold: number) => {
    const groups: Array<{ center: [number, number]; ports: typeof ports }> = [];

    ports.forEach(port => {
      let added = false;
      for (const group of groups) {
        if (calculateDistance(group.center, port.coordinates) < threshold) {
          group.ports.push(port);
          group.center = [
            (group.center[0] * (group.ports.length - 1) + port.coordinates[0]) / group.ports.length,
            (group.center[1] * (group.ports.length - 1) + port.coordinates[1]) / group.ports.length
          ];
          added = true;
          break;
        }
      }
      if (!added) {
        groups.push({ center: port.coordinates, ports: [port] });
      }
    });

    return groups;
  };

  const renderPorts = useMemo(() => {
    const portList = selectedPorts
      .map(port => portCoordinates[port])
      .filter(port => port !== undefined);

    const threshold = 2 / Math.pow(position.zoom, 2); // Reduced threshold for grouping
    const groups = groupPorts(portList, threshold);

    return groups.map((group, index) => {
      const count = group.ports.length;
      return (
        <Marker
          key={`port-group-${index}`}
          coordinates={group.center}
          onMouseEnter={() => setTooltipContent(count === 1 
            ? `Port: ${group.ports[0].name}` 
            : `${count} ports: ${group.ports.map(p => p.name).join(', ')}`)}
          onMouseLeave={() => setTooltipContent("")}
        >
          <circle 
            r={getMarkerSize(true, count)} 
            fill="#F00" 
            stroke="#fff" 
            strokeWidth={getStrokeWidth(true, count)} 
          />
          {count > 1 && (
            <text
              textAnchor="middle"
              y={2}
              style={{
                fill: "#fff",
                fontSize: 8,
                fontWeight: "bold",
              }}
            >
              {count}
            </text>
          )}
        </Marker>
      );
    });
  }, [selectedPorts, portCoordinates, position.zoom]);

  const closestFTZs = useMemo(() => {
    return selectedPorts.flatMap(port => {
      const portInfo = portCoordinates[port];
      if (!portInfo) {
        console.warn(`Port not found: ${port}`);
        return [];
      }

      const ftzDistances = Object.entries(ftzCoordinates).map(([ftzNumber, ftz]) => ({
        ftzNumber,
        ftz,
        distance: calculateDistance(portInfo.coordinates, ftz.coordinates)
      }));

      ftzDistances.sort((a, b) => a.distance - b.distance);

      return ftzDistances.slice(0, 8).map(({ ftzNumber, ftz }) => ({
        ftzNumber,
        ...ftz,
        portCode: port
      }));
    });
  }, [selectedPorts, portCoordinates]);

  return (
    <Box sx={{ position: 'relative', width: '100%', height: '100%', overflow: 'hidden' }}>
      <ComposableMap 
        projection="geoAlbersUsa"
        projectionConfig={{ scale: 1000 }}
        style={{ width: '100%', height: '100%' }}
        onMouseMove={handleMouseMove}
      >
        <ZoomableGroup
          center={position.coordinates as [number, number]}
          zoom={position.zoom}
          onMoveEnd={handleZoomEnd}
        >
          <Geographies geography={geoUrl}>
            {({ geographies }) =>
              geographies.map(geo => (
                <Geography
                  key={geo.rsmKey}
                  geography={geo}
                  fill="#EAEAEC"
                  stroke="#D6D6DA"
                  style={{
                    default: { outline: 'none' },
                    hover: { outline: 'none' },
                    pressed: { outline: 'none' },
                  }}
                />
              ))
            }
          </Geographies>
          {renderPorts}
          {closestFTZs.map(({ ftzNumber, name, coordinates, portCode }) => (
            <Marker 
              key={`ftz-${ftzNumber}-${portCode}`} 
              coordinates={coordinates}
              onMouseEnter={() => setTooltipContent(`FTZ ${ftzNumber}: ${name}`)}
              onMouseLeave={() => setTooltipContent("")}
            >
              <circle 
                r={getMarkerSize(false)} 
                fill="#800080" 
                stroke="#fff" 
                strokeWidth={getStrokeWidth(false)} 
              />
            </Marker>
          ))}
        </ZoomableGroup>
      </ComposableMap>
      <Box sx={{ position: 'absolute', bottom: 10, left: 10, backgroundColor: 'rgba(255,255,255,0.8)', padding: 1, borderRadius: 1 }}>
        <Typography variant="body2"><span style={{ color: '#F00', marginRight: 5 }}>●</span> Ports</Typography>
        <Typography variant="body2"><span style={{ color: '#800080', marginRight: 5 }}>●</span> FTZ Zones</Typography>
      </Box>
      {tooltipContent && (
        <Box
          sx={{
            position: 'fixed',
            left: tooltipPosition.x + 10,
            top: tooltipPosition.y + 10,
            backgroundColor: 'rgba(0,0,0,0.8)',
            color: 'white',
            padding: '5px 10px',
            borderRadius: '4px',
            fontSize: '14px',
            pointerEvents: 'none',
          }}
        >
          {tooltipContent}
        </Box>
      )}
    </Box>
  );
};

export default FTZMap;