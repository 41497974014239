import React from 'react';
import { DateRangePicker, DateRangePickerProps } from 'react-date-range';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';

// @ts-ignore
const DateRangePickerWrapper = (props: DateRangePickerProps) => {
  return (
    <div className="date-range-picker-wrapper">
      {/* @ts-ignore */}
      <DateRangePicker {...props} />
    </div>
  );
};

export default DateRangePickerWrapper; 