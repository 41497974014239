import axios from "axios";
import { useContext, useEffect, useState } from "react";
import { IKeyValue } from "../../../@types/GlobalMessageContext";
import { UserContextType } from "../../../@types/UserContext";
import FilterHeader from "../../../components/header/filter-header";
import ScrollableTabs from "../../../components/tabs";
import { ENDPOINT } from "../../../config/config";
import UserContext from "../../../context/user/UserContext";
import FirstSaleTab from "./FirstSaleTab";
import PreferentialOriginTab from "./PreferentialOriginTab";
import FTZTab from './FTZTab';
import ExclusionsTab from './ExclusionsTab';
import ReclassificationTab from './ReclassificationTab';
import MTBTab from './MTBTab';

const Potential = () => {
  const [tabValue, setTabValue] = useState<number>(0);
  const [lastUpdatedDate, setLastUpdatedDate] = useState<string>("");
  const [state, setState] = useState(false);
  const userContext = useContext(UserContext) as UserContextType;
  const [dutySpendFilter, setDutySpendFilter] = useState<string>("");
  const [dates, setDates] = useState<null | IKeyValue>(null);
  const [filterLabel, setFilterLabel] = useState<string>("Today");
  const [page, setPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(5);

  const handleSubFiltersDisplay = () => {
    setState(!state);
  };

  useEffect(() => {
    const fetchData = async () => {
      const promise = await axios.get(`${ENDPOINT}/data/last-update`, {
        headers: {
          authorization: `Bearer ${userContext?.user?.token}`,
        },
      });
      setLastUpdatedDate(promise?.data?.lastUpdatedAt);
    };
    fetchData();
  }, []);

  const handleFilter = (value: IKeyValue) => {
    setDutySpendFilter(value?.dutySpend);
  };
  const handleFilterReset = () => {
    setDutySpendFilter("");
    setDates(null);
    setFilterLabel("Today");
  };
  const handleDateFilter = (value: IKeyValue) => {
    setDates(value);
  };
  const handleFilterLabel = (value: string) => {
    setFilterLabel(value);
  };

  const generateHeadingTxt = () => {
    switch (tabValue) {
      case 0:
        return "First Sale";
      case 1:
        return "Preferential Origin";
      case 2:
        return "Foreign Trade Zone";
      case 3:
        return "Section 301 Exclusions";
      case 4:
        return "MTB";
      case 5:
        return "Reclassification";
      case 6:
        return "Value Unbundling";
      case 7:
        return "Prototype Classification";
      default:
        return "First Sale";
    }
  };

  return (
    <>
      <ScrollableTabs tabValue={tabValue} setTabValue={setTabValue} />
      <FilterHeader
        onDutySpendChange={handleFilter}
        dutySpendFilter={dutySpendFilter}
        handleDateFilter={handleDateFilter}
        handleFilterReset={handleFilterReset}
        handleFilterLabel={handleFilterLabel}
        headingTxt={generateHeadingTxt()}
        subHeadingTxt={lastUpdatedDate}
      />
      {tabValue === 0 ? (
        <FirstSaleTab
          dates={dates}
          page={page}
          dutySpendFilter={dutySpendFilter}
          pageSize={pageSize}
          setPage={setPage}
        />
      ) : tabValue === 1 ? (
        <PreferentialOriginTab
          dates={dates}
          page={page}
          dutySpendFilter={dutySpendFilter}
          pageSize={pageSize}
          setPage={setPage}
        />
      ) : tabValue === 2 ? (
        <FTZTab
          dates={dates}
          page={page}
          dutySpendFilter={dutySpendFilter}
          pageSize={pageSize}
          setPage={setPage}
        />
      ) : tabValue === 3 ? (
        <ExclusionsTab
          dates={dates}
          page={page}
          dutySpendFilter={dutySpendFilter}
          pageSize={pageSize}
          setPage={setPage}
        />
      ) : tabValue === 4 ? (
        <MTBTab
          dates={dates}
          page={page}
          dutySpendFilter={dutySpendFilter}
          pageSize={pageSize}
          setPage={setPage}
        />
      ) : tabValue === 5 ? (
        <ReclassificationTab
          dates={dates}
          page={page}
          dutySpendFilter={dutySpendFilter}
          pageSize={pageSize}
          setPage={setPage}
        />
      ) : tabValue === 6 ? (
        <div>Value Unbundling Coming Soon</div>
      ) : tabValue === 7 ? (
        <div>Prototype Classification Coming Soon</div>
      ) : (
        <FirstSaleTab
          dates={dates}
          page={page}
          dutySpendFilter={dutySpendFilter}
          pageSize={pageSize}
          setPage={setPage}
        />
      )}
    </>
  );
};

export default Potential;
