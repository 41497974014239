import * as React from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { styled } from "@mui/material";

interface StyledTabProps {
  label: string;
}

interface IProps {
  tabValue: number;
  setTabValue: (tabValue: number) => void;
}

const StyledTab = styled((props: StyledTabProps & { active: boolean }) => (
  <Tab disableRipple {...props} />
))(({ theme, active }) => ({
  textTransform: "none",
  fontWeight: 500,
  fontSize: theme.typography.pxToRem(15),
  marginRight: theme.spacing(1),
  background: active ? "#0B494B" : "#fff",
  borderRadius: "32px",
  color: active ? "#fff !important" : "#000 !important",
}));

export default function ScrollableTabs({ tabValue, setTabValue }: IProps) {
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  return (
    <Tabs
      value={tabValue}
      onChange={handleChange}
      variant="scrollable"
      scrollButtons="auto"
      aria-label="scrollable"
      TabIndicatorProps={{
        style: { display: "none" },
      }}
      sx={{ mb: 4 }}
    >
      <StyledTab label="First Sale" active={tabValue === 0} />
      <StyledTab label="Preferential Origin" active={tabValue === 1} />
      <StyledTab label="Foreign Trade Zone" active={tabValue === 2} />
      <StyledTab label="Section 301 Exclusions" active={tabValue === 3} />
      <StyledTab label="MTB" active={tabValue === 4} />
      <StyledTab label="Reclassification" active={tabValue === 5} />
      <StyledTab label="Value Unbundling" active={tabValue === 6} />
      <StyledTab label="Prototype Classification" active={tabValue === 7} />
    </Tabs>
  );
}
