import React, { useState, useEffect, useContext } from 'react';
import { Box, Grid, Typography, Paper, TextField, Button, Select, MenuItem, FormControl, InputLabel, Chip } from '@mui/material';
import FTZMap from './FTZMap';
import UserContext from '../../../context/user/UserContext';
import { UserContextType } from '../../../@types/UserContext';
import { Entry } from '../../../@types/Entry';
import { SelectChangeEvent } from '@mui/material/Select';
import { usPorts } from './PortCoordinates';
import axios from "axios";
import { ENDPOINT } from "../../../config/config";

interface Savings {
  oldEntryCost: number;
  newEntryCost: number;
  entryCostSavings: number;
  oldMPF: number;
  newMPF: number;
  mpfSavings: number;
}

interface Metrics {
  entryLines: number;
  ports: number;
  weeks: number;
  oldEntrySummaries: number;
  newEntrySummaries: number;
}

interface State {
  oldCostPerEntry: number;
  ftzCostPerEntry: number;
  loading: boolean;
  entries: Entry[];
  filteredEntries: Entry[]; // Added to hold filtered entries
  metrics: Metrics;
  savings: Savings;
  optimizationCriteria: 'entryCost' | 'timeValue' | 'mpfReduction';
  selectedPorts: string[]; // Change this from string to string[]
  lineRange: { min: number; max: number };
  dutySpendRange: { min: number; max: number };
  mpfSpendRange: { min: number; max: number };
  maxValues: {
    lineCount: number;
    totalDuties: number;
    mpf: number;
  };
  currentSort: 'lines' | 'mpf' | 'duties';
}

interface FTZTabProps {
  dates: any; // Change this to the correct type if known, or use 'any' if uncertain
  page: number;
  dutySpendFilter: string;
  pageSize: number;
  setPage: React.Dispatch<React.SetStateAction<number>>;
}

const getPortLabel = (portCode: string) => {
  const port = usPorts.find(p => p.portCode === portCode);
  return port ? `${portCode} - ${port.city}` : portCode;
};

const formatCurrency = (value: number): string => {
  return `$${Math.round(value).toLocaleString('en-US')}`;
};

const getPortStats = (portCode: string, entries: Entry[], sortType: 'lines' | 'mpf' | 'duties') => {
  let total = 0;
  let portTotal = 0;

  switch (sortType) {
    case 'mpf':
      total = entries.reduce((sum, entry) => sum + entry.mpf, 0);
      portTotal = entries
        .filter(entry => entry.portOfEntry === portCode)
        .reduce((sum, entry) => sum + entry.mpf, 0);
      break;
    case 'duties':
      total = entries.reduce((sum, entry) => sum + entry.totalDuties, 0);
      portTotal = entries
        .filter(entry => entry.portOfEntry === portCode)
        .reduce((sum, entry) => sum + entry.totalDuties, 0);
      break;
    default: // 'lines'
      total = entries.reduce((sum, entry) => sum + entry.lineCount, 0);
      portTotal = entries
        .filter(entry => entry.portOfEntry === portCode)
        .reduce((sum, entry) => sum + entry.lineCount, 0);
  }
  
  const percentage = total > 0 ? ((portTotal / total) * 100).toFixed(1) : '0';
  return `${percentage}%`;
};

const sortByEntryLines = (ports: string[], entries: Entry[]) => {
  return ports.sort((a, b) => {
    const aLines = entries
      .filter(entry => entry.portOfEntry === a)
      .reduce((sum, entry) => sum + entry.lineCount, 0);
    const bLines = entries
      .filter(entry => entry.portOfEntry === b)
      .reduce((sum, entry) => sum + entry.lineCount, 0);
    return bLines - aLines;
  });
};

const sortByMPF = (ports: string[], entries: Entry[]) => {
  return ports.sort((a, b) => {
    const aMPF = entries
      .filter(entry => entry.portOfEntry === a)
      .reduce((sum, entry) => sum + entry.mpf, 0);
    const bMPF = entries
      .filter(entry => entry.portOfEntry === b)
      .reduce((sum, entry) => sum + entry.mpf, 0);
    return bMPF - aMPF;
  });
};

const sortByDuties = (ports: string[], entries: Entry[]) => {
  return ports.sort((a, b) => {
    const aDuties = entries
      .filter(entry => entry.portOfEntry === a)
      .reduce((sum, entry) => sum + entry.totalDuties, 0);
    const bDuties = entries
      .filter(entry => entry.portOfEntry === b)
      .reduce((sum, entry) => sum + entry.totalDuties, 0);
    return bDuties - aDuties;
  });
};

const FTZTab: React.FC<FTZTabProps> = ({ dates, page, dutySpendFilter, pageSize, setPage }) => {
  const [state, setState] = useState<State>({
    oldCostPerEntry: 100,
    ftzCostPerEntry: 1500,
    loading: false,
    entries: [],
    filteredEntries: [], // Initialize with an empty array
    metrics: {
      entryLines: 0,
      ports: 0,
      weeks: 0,
      oldEntrySummaries: 0,
      newEntrySummaries: 0,
    },
    savings: {
      oldEntryCost: 0,
      newEntryCost: 0,
      entryCostSavings: 0,
      oldMPF: 0,
      newMPF: 0,
      mpfSavings: 0,
    },
    optimizationCriteria: 'entryCost',
    selectedPorts: [], // Initialize as an empty array
    lineRange: { min: 0, max: 0 },
    dutySpendRange: { min: 0, max: 0 },
    mpfSpendRange: { min: 0, max: 0 },
    maxValues: {
      lineCount: 0,
      totalDuties: 0,
      mpf: 0,
    },
    currentSort: 'lines',
  });

  const userContext = useContext(UserContext) as UserContextType;

  useEffect(() => {
    fetchEntries();
  }, [dates, page, dutySpendFilter, pageSize]);

  const fetchEntries = async () => {
    try {
      const startDate = dates?.startDate ?? "";
      const endDate = dates?.endDate ?? "";
      setState(prevState => ({ ...prevState, loading: true }));

      const result = await axios.get(
        `${ENDPOINT}/dashboard-potential/savings/ftz?dateFrom=${startDate}&dateTo=${endDate}&pageNo=${page}&pageSize=${pageSize}`,
        {
          headers: {
            authorization: `Bearer ${userContext?.user?.token}`,
          },
        }
      );

      if (result?.data?.data) {
        // Calculate max values
        const maxLineCount = Math.max(...result.data.data.map((e: Entry) => e.lineCount));
        const maxTotalDuties = Math.max(...result.data.data.map((e: Entry) => e.totalDuties));
        const maxMPF = Math.max(...result.data.data.map((e: Entry) => e.mpf));

        // Single state update with all changes
        setState(prevState => ({
          ...prevState,
          entries: result.data.data,
          filteredEntries: result.data.data,
          loading: false,
          lineRange: { min: 0, max: maxLineCount },
          dutySpendRange: { min: 0, max: maxTotalDuties },
          mpfSpendRange: { min: 0, max: maxMPF },
          maxValues: {
            lineCount: maxLineCount,
            totalDuties: maxTotalDuties,
            mpf: maxMPF,
          }
        }));

        // Calculate metrics after state update
        calculateMetricsAndSavings(result.data.data);
      }
    } catch (err) {
      setState(prevState => ({ ...prevState, loading: false }));
      console.error('Error fetching FTZ data:', err);
    }
  };

  const calculateMPFSavings = (entries: Entry[]) => {
    const MIN_MPF = 33.37;
    const MAX_MPF = 575.35;
    const MAX_LINES_PER_SUMMARY = 998;
    let oldMPF = 0;
    let newMPF = 0;
    let currentBundleLines = 0;
    let currentBundleMPF = 0;

    entries.forEach((entry) => {
      // Old MPF is the sum of total MPF (not line MPF)
      oldMPF += entry.mpf;
      
      // Bundle entries for new MPF calculation
      currentBundleLines += entry.lineCount;
      currentBundleMPF += entry.totalLineMPF;

      if (currentBundleLines >= MAX_LINES_PER_SUMMARY) {
        // Add MPF for the current bundle
        newMPF += Math.max(MIN_MPF, Math.min(currentBundleMPF, MAX_MPF));
        // Reset bundle counters
        currentBundleLines = 0;
        currentBundleMPF = 0;
      }
    });

    // Add MPF for any remaining bundle
    if (currentBundleLines > 0) {
      newMPF += Math.max(MIN_MPF, Math.min(currentBundleMPF, MAX_MPF));
    }

    return {
      oldMPF: parseFloat(oldMPF.toFixed(2)),
      newMPF: parseFloat(newMPF.toFixed(2)),
      mpfSavings: parseFloat((oldMPF - newMPF).toFixed(2))
    };
  };

  const calculateMetricsAndSavings = (entries: Entry[]) => {
    const totalLines = entries.reduce((sum, entry) => sum + entry.lineCount, 0);
    const uniquePorts = new Set(entries.map(entry => entry.portOfEntry));
    const oldEntrySummaries = entries.length;
    const newEntrySummaries = Math.ceil(totalLines / 998);
    const mpfCalculations = calculateMPFSavings(entries);

    setState(prevState => ({
      ...prevState,
      metrics: {
        entryLines: totalLines,
        ports: uniquePorts.size,
        weeks: newEntrySummaries,
        oldEntrySummaries: oldEntrySummaries,
        newEntrySummaries: newEntrySummaries,
      },
      savings: {
        oldEntryCost: parseFloat((oldEntrySummaries * prevState.oldCostPerEntry).toFixed(2)),
        newEntryCost: parseFloat((newEntrySummaries * prevState.ftzCostPerEntry).toFixed(2)),
        entryCostSavings: parseFloat((oldEntrySummaries * prevState.oldCostPerEntry - newEntrySummaries * prevState.ftzCostPerEntry).toFixed(2)),
        oldMPF: mpfCalculations.oldMPF,
        newMPF: mpfCalculations.newMPF,
        mpfSavings: mpfCalculations.mpfSavings,
      },
    }));
  };

  const handlePortChange = (event: SelectChangeEvent<string[]>) => {
    const value = event.target.value;
    setState(prevState => ({
      ...prevState,
      selectedPorts: typeof value === 'string' ? value.split(',') : value,
    }));
  };

  const handleRangeChange = (field: 'lineRange' | 'dutySpendRange' | 'mpfSpendRange', subField: 'min' | 'max', value: number) => {
    setState(prevState => ({
      ...prevState,
      [field]: { ...prevState[field], [subField]: value },
    }));
  };

  const handleResetFilters = () => {
    setState(prevState => ({
      ...prevState,
      selectedPorts: [],
      lineRange: { min: 0, max: prevState.maxValues.lineCount },
      dutySpendRange: { min: 0, max: prevState.maxValues.totalDuties },
      mpfSpendRange: { min: 0, max: prevState.maxValues.mpf },
    }));
  };

  const handleApplyFilters = () => {
    const { entries, selectedPorts, lineRange, dutySpendRange, mpfSpendRange } = state;

    const filteredEntries = entries.filter(entry => {
      const portMatch = selectedPorts.length === 0 || selectedPorts.includes(entry.portOfEntry);
      const lineMatch =
        entry.lineCount >= lineRange.min &&
        entry.lineCount <= (lineRange.max || state.maxValues.lineCount);
      const dutyMatch =
        entry.totalDuties >= dutySpendRange.min &&
        entry.totalDuties <= (dutySpendRange.max || state.maxValues.totalDuties);
      const mpfMatch =
        entry.mpf >= mpfSpendRange.min &&
        entry.mpf <= (mpfSpendRange.max || state.maxValues.mpf);

      return portMatch && lineMatch && dutyMatch && mpfMatch;
    });

    setState(prevState => ({
      ...prevState,
      filteredEntries: filteredEntries,
    }));

    calculateMetricsAndSavings(filteredEntries);
  };

  const handleDownloadReport = () => console.log('Downloading report...');

  const handleUpdateCalculations = () => calculateMetricsAndSavings(state.filteredEntries);

  return (
    <Box sx={{ p: 3 }}>
      <Paper sx={{ p: 2, mb: 3 }}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="h6" sx={{ mb: 1 }}>
              Foreign Trade Zone Data Selection
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <FormControl fullWidth size="small">
              <InputLabel id="port-select-label">Ports</InputLabel>
              <Select
                labelId="port-select-label"
                multiple
                value={state.selectedPorts}
                onChange={handlePortChange}
                label="Ports"
                renderValue={(selected) => (
                  <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                    {selected.map((value) => (
                      <Chip key={value} label={getPortLabel(value)} size="small" />
                    ))}
                  </Box>
                )}
              >
                <Box sx={{ 
                  position: 'sticky', 
                  top: 0, 
                  bgcolor: 'background.paper',
                  zIndex: 1,
                  borderBottom: '1px solid',
                  borderColor: 'divider',
                  p: 1,
                  display: 'flex',
                  alignItems: 'center',
                  gap: 1
                }}>
                  <Typography variant="body1" sx={{ fontWeight: 'bold', mr: 2 }}>
                    Sort by:
                  </Typography>
                  <Button
                    size="small"
                    variant={state.currentSort === 'lines' ? 'contained' : 'outlined'}
                    onClick={(e) => {
                      e.stopPropagation();
                      setState(prev => ({
                        ...prev,
                        currentSort: 'lines',
                        entries: [...prev.entries],
                        filteredEntries: sortByEntryLines([...new Set(prev.entries.map(entry => entry.portOfEntry))], prev.entries)
                          .map(port => prev.entries.find(entry => entry.portOfEntry === port))
                          .filter((entry): entry is Entry => entry !== undefined)
                      }));
                    }}
                  >
                    Entry Lines
                  </Button>
                  <Button
                    size="small"
                    variant={state.currentSort === 'mpf' ? 'contained' : 'outlined'}
                    onClick={(e) => {
                      e.stopPropagation();
                      setState(prev => ({
                        ...prev,
                        currentSort: 'mpf',
                        entries: [...prev.entries],
                        filteredEntries: sortByMPF([...new Set(prev.entries.map(entry => entry.portOfEntry))], prev.entries)
                          .map(port => prev.entries.find(entry => entry.portOfEntry === port))
                          .filter((entry): entry is Entry => entry !== undefined)
                      }));
                    }}
                  >
                    MPF
                  </Button>
                  <Button
                    size="small"
                    variant={state.currentSort === 'duties' ? 'contained' : 'outlined'}
                    onClick={(e) => {
                      e.stopPropagation();
                      setState(prev => ({
                        ...prev,
                        currentSort: 'duties',
                        entries: [...prev.entries],
                        filteredEntries: sortByDuties([...new Set(prev.entries.map(entry => entry.portOfEntry))], prev.entries)
                          .map(port => prev.entries.find(entry => entry.portOfEntry === port))
                          .filter((entry): entry is Entry => entry !== undefined)
                      }));
                    }}
                  >
                    Duties
                  </Button>
                </Box>
                {[...new Set(state.entries.map(entry => entry.portOfEntry))]
                  .sort((a, b) => {
                    const aLines = state.entries
                      .filter(entry => entry.portOfEntry === a)
                      .reduce((sum, entry) => sum + entry.lineCount, 0);
                    const bLines = state.entries
                      .filter(entry => entry.portOfEntry === b)
                      .reduce((sum, entry) => sum + entry.lineCount, 0);
                    return bLines - aLines;
                  })
                  .map(port => (
                    <MenuItem value={port} key={port}>
                      {getPortLabel(port)} ({getPortStats(port, state.entries, state.currentSort)} of imports)
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={12}>
            <Box sx={{ pl: 1, mb: 1 }}>
              <Typography variant="body2" color="text.secondary">
                # of Entry Summaries: {state.filteredEntries.length.toLocaleString('en-US')}
              </Typography>
            </Box>
          </Grid>

          <Grid container item spacing={2} xs={12}>
            <Grid item xs={4}>
              <Typography variant="subtitle2" gutterBottom>Lines Range</Typography>
              <Grid container spacing={1}>
                <Grid item xs={6}>
                  <TextField
                    type="number"
                    value={state.lineRange.min}
                    onChange={(e) => handleRangeChange('lineRange', 'min', Number(e.target.value))}
                    fullWidth
                    size="small"
                  />
                  <Typography variant="caption" sx={{ mt: 0.5, display: 'block' }}>Min</Typography>
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    type="number"
                    value={state.lineRange.max}
                    onChange={(e) => handleRangeChange('lineRange', 'max', Number(e.target.value))}
                    fullWidth
                    size="small"
                    placeholder={state.maxValues.lineCount.toString()}
                  />
                  <Typography variant="caption" sx={{ mt: 0.5, display: 'block' }}>Max</Typography>
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={4}>
              <Typography variant="subtitle2" gutterBottom>Duty Spend Range</Typography>
              <Grid container spacing={1}>
                <Grid item xs={6}>
                  <TextField
                    type="number"
                    value={state.dutySpendRange.min}
                    onChange={(e) => handleRangeChange('dutySpendRange', 'min', Number(e.target.value))}
                    fullWidth
                    size="small"
                  />
                  <Typography variant="caption" sx={{ mt: 0.5, display: 'block' }}>Min</Typography>
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    type="number"
                    value={state.dutySpendRange.max}
                    onChange={(e) => handleRangeChange('dutySpendRange', 'max', Number(e.target.value))}
                    fullWidth
                    size="small"
                    placeholder={state.maxValues.totalDuties.toFixed(2)}
                  />
                  <Typography variant="caption" sx={{ mt: 0.5, display: 'block' }}>Max</Typography>
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={4}>
              <Typography variant="subtitle2" gutterBottom>MPF Spend Range</Typography>
              <Grid container spacing={1}>
                <Grid item xs={6}>
                  <TextField
                    type="number"
                    value={state.mpfSpendRange.min}
                    onChange={(e) => handleRangeChange('mpfSpendRange', 'min', Number(e.target.value))}
                    fullWidth
                    size="small"
                  />
                  <Typography variant="caption" sx={{ mt: 0.5, display: 'block' }}>Min</Typography>
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    type="number"
                    value={state.mpfSpendRange.max}
                    onChange={(e) => handleRangeChange('mpfSpendRange', 'max', Number(e.target.value))}
                    fullWidth
                    size="small"
                    placeholder={state.maxValues.mpf.toFixed(2)}
                  />
                  <Typography variant="caption" sx={{ mt: 0.5, display: 'block' }}>Max</Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12} sx={{ display: 'flex', gap: 1, mt: 1 }}>
            <Button
              variant="outlined"
              color="primary"
              onClick={handleResetFilters}
              size="small"
            >
              Reset All Filters
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={handleApplyFilters}
              size="small"
            >
              Apply Filters
            </Button>
          </Grid>
        </Grid>
      </Paper>

      {/* Display the analysis after filters are applied */}
      {state.filteredEntries.length > 0 && (
        <Grid container spacing={3}>
          <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
            <Typography variant="h4">Foreign Trade Zone Savings Analysis</Typography>
            <Button
              variant="contained"
              color="primary"
              onClick={handleDownloadReport}
            >
              Download Report
            </Button>
          </Grid>

          <Grid item xs={12} md={4}>
            <Paper sx={{ p: 3, height: '200px', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
              <Typography variant="h6">Projected Entry Cost Savings</Typography>
              <Typography variant="body1">Old Cost: {formatCurrency(state.savings.oldEntryCost)}</Typography>
              <Typography variant="body1">New Cost: {formatCurrency(state.savings.newEntryCost)}</Typography>
              <Typography variant="h4" color="primary">{formatCurrency(state.savings.entryCostSavings)}</Typography>
            </Paper>
          </Grid>

          <Grid item xs={12} md={4}>
            <Paper sx={{ p: 3, height: '200px', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
              <Typography variant="h6">Projected MPF Savings</Typography>
              <Typography variant="body1">Old MPF: {formatCurrency(state.savings.oldMPF)}</Typography>
              <Typography variant="body1">New MPF: {formatCurrency(state.savings.newMPF)}</Typography>
              <Typography variant="h4" color="primary">{formatCurrency(state.savings.mpfSavings)}</Typography>
            </Paper>
          </Grid>

          <Grid item xs={12} md={4}>
            <Paper sx={{ p: 3, height: '200px', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
              <Typography variant="h6">Duties Deferred</Typography>
              <Typography variant="h4" color="primary">
                {formatCurrency(state.filteredEntries.reduce((sum, entry) => sum + entry.totalDuties, 0))}
              </Typography>
            </Paper>
          </Grid>

          <Grid item xs={12} md={8}>
            <Paper sx={{ p: 3, height: '500px', boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)' }}>
              <Typography variant="h6" gutterBottom>Selected Ports and FTZ Zones</Typography>
              <Box sx={{ height: 'calc(100% - 32px)', width: '100%' }}>
                <FTZMap selectedPorts={[...new Set(state.filteredEntries.map(entry => entry.portOfEntry))]} />
              </Box>
            </Paper>
          </Grid>

          <Grid item xs={12} md={4}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Paper sx={{ 
                  p: 2, 
                  boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
                  height: '244px'  // Added fixed height to match map height with metrics
                }}>
                  <Typography variant="h6" sx={{ mb: 2 }}>
                    Cost Per Entry
                  </Typography>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <TextField
                        label="Old Cost per Entry"
                        type="number"
                        value={state.oldCostPerEntry}
                        onChange={(e) => setState(prevState => ({ ...prevState, oldCostPerEntry: Number(e.target.value) }))}
                        fullWidth
                        size="small"
                        sx={{ mb: 2 }}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        label="FTZ Cost per Entry"
                        type="number"
                        value={state.ftzCostPerEntry}
                        onChange={(e) => setState(prevState => ({ ...prevState, ftzCostPerEntry: Number(e.target.value) }))}
                        fullWidth
                        size="small"
                        sx={{ mb: 2 }}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Button 
                        variant="contained" 
                        color="primary" 
                        fullWidth 
                        onClick={handleUpdateCalculations}
                        size="small"
                      >
                        Update Calculations
                      </Button>
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>

              <Grid item xs={12}>
                <Paper sx={{ p: 3, boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)' }}>
                  <Typography variant="h6" gutterBottom>Metrics</Typography>
                  <Grid container spacing={2}>
                    {[
                      { label: 'Entry Lines', value: state.metrics.entryLines },
                      { label: 'Ports', value: state.metrics.ports },
                      { label: 'Weeks', value: state.metrics.weeks },
                      { label: 'Entry Summaries', value: `${state.metrics.oldEntrySummaries} → ${state.metrics.newEntrySummaries}` },
                    ].map((metric, index) => (
                      <Grid item xs={6} key={index}>
                        <Box sx={{ p: 1, height: '60px', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', bgcolor: '#E7EDED', borderRadius: '4px' }}>
                          <Typography variant="body1" color="primary" fontWeight="bold">{metric.value}</Typography>
                          <Typography variant="body2" color="text.secondary">{metric.label}</Typography>
                        </Box>
                      </Grid>
                    ))}
                  </Grid>
                </Paper>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      )}
    </Box>
  );
};

export default FTZTab;