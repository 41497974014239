export const ftzCoordinates: { [key: string]: { name: string; coordinates: [number, number] } } = {
  '001': { name: "New York", coordinates: [-74.0060, 40.7128] },
  '002': { name: "New Orleans", coordinates: [-90.0715, 29.9511] },
  '003': { name: "San Francisco", coordinates: [-122.4194, 37.7749] },
  '005': { name: "Seattle", coordinates: [-122.3321, 47.6062] },
  '007': { name: "Mayaguez", coordinates: [-67.1401, 18.2011] },
  '008': { name: "Toledo", coordinates: [-83.5379, 41.6528] },
  '009': { name: "Honolulu", coordinates: [-157.8583, 21.3069] },
  '012': { name: "McAllen", coordinates: [-98.2300, 26.2034] },
  '014': { name: "Little Rock", coordinates: [-92.2896, 34.7465] },
  '015': { name: "Kansas City, MO", coordinates: [-94.5786, 39.0997] },
  '016': { name: "Sault Ste. Marie", coordinates: [-84.3453, 46.4953] },
  '017': { name: "Kansas City, KS", coordinates: [-94.6274, 39.1141] },
  '018': { name: "San Jose", coordinates: [-121.8863, 37.3382] },
  '019': { name: "Omaha", coordinates: [-95.9345, 41.2565] },
  '020': { name: "Norfolk", coordinates: [-76.2859, 36.8508] },
  '021': { name: "Dorchester County", coordinates: [-80.3789, 33.0971] },
  '022': { name: "Chicago", coordinates: [-87.6298, 41.8781] },
  '023': { name: "Buffalo", coordinates: [-78.8784, 42.8864] },
  '024': { name: "Pittston", coordinates: [-75.7896, 41.3245] },
  '025': { name: "Broward County", coordinates: [-80.2086, 26.1224] },
  '026': { name: "Atlanta", coordinates: [-84.3880, 33.7490] },
  '027': { name: "Boston", coordinates: [-71.0589, 42.3601] },
  '028': { name: "New Bedford", coordinates: [-70.9342, 41.6362] },
  '029': { name: "Louisville", coordinates: [-85.7585, 38.2527] },
  '030': { name: "Salt Lake City", coordinates: [-111.8910, 40.7608] },
  '031': { name: "Granite City", coordinates: [-90.1487, 38.7020] },
  '032': { name: "Miami", coordinates: [-80.1918, 25.7617] },
  '033': { name: "Pittsburgh", coordinates: [-79.9959, 40.4406] },
  '034': { name: "Niagara County", coordinates: [-78.7890, 43.1094] },
  '035': { name: "Philadelphia", coordinates: [-75.1652, 39.9526] },
  '036': { name: "Galveston", coordinates: [-94.7977, 29.3013] },
  '037': { name: "Orange County, NY", coordinates: [-74.3118, 41.4018] },
  '038': { name: "Spartanburg County", coordinates: [-81.9903, 34.9495] },
  '039': { name: "Dallas/Fort Worth", coordinates: [-97.0403, 32.8998] },
  '040': { name: "Cleveland", coordinates: [-81.6944, 41.4993] },
  '041': { name: "Milwaukee", coordinates: [-87.9065, 43.0389] },
  '042': { name: "Orlando", coordinates: [-81.3792, 28.5383] },
  '043': { name: "Battle Creek", coordinates: [-85.1800, 42.3211] },
  '044': { name: "Mt. Olive, New Jersey", coordinates: [-74.7343, 40.8540] },
  '045': { name: "Portland", coordinates: [-122.6784, 45.5152] },
  '046': { name: "Cincinnati", coordinates: [-84.5120, 39.1031] },
  '047': { name: "Boone County, KY", coordinates: [-84.7269, 38.9713] },
  '049': { name: "Newark/Elizabeth", coordinates: [-74.1724, 40.7357] },
  '050': { name: "Long Beach", coordinates: [-118.1937, 33.7701] },
  '051': { name: "Duluth", coordinates: [-92.1005, 46.7867] },
  '052': { name: "Suffolk County", coordinates: [-72.7854, 40.9849] },
  '053': { name: "Rogers County", coordinates: [-95.6446, 36.3190] },
  '054': { name: "Clinton County", coordinates: [-73.4672, 44.6928] },
  '055': { name: "Burlington", coordinates: [-73.2121, 44.4759] },
  '056': { name: "Oakland", coordinates: [-122.2712, 37.8044] },
  '057': { name: "Mecklenburg County", coordinates: [-80.8307, 35.2469] },
  '058': { name: "Bangor", coordinates: [-68.7712, 44.8016] },
  '059': { name: "Lincoln", coordinates: [-96.7026, 40.8136] },
  '060': { name: "Nogales", coordinates: [-110.9420, 31.3405] },
  '061': { name: "San Juan", coordinates: [-66.1057, 18.4655] },
  '062': { name: "Brownsville", coordinates: [-97.4975, 25.9017] },
  '063': { name: "Prince George's County", coordinates: [-76.8458, 38.7849] },
  '064': { name: "Jacksonville", coordinates: [-81.6557, 30.3322] },
  '065': { name: "Panama City", coordinates: [-85.6602, 30.1588] },
  '068': { name: "El Paso", coordinates: [-106.4850, 31.7619] },
  '070': { name: "Detroit", coordinates: [-83.0458, 42.3314] },
  '071': { name: "Windsor Locks", coordinates: [-72.6437, 41.9245] },
  '072': { name: "Indianapolis", coordinates: [-86.1581, 39.7684] },
  '073': { name: "BWI Airport", coordinates: [-76.6683, 39.1774] },
  '074': { name: "Baltimore", coordinates: [-76.6122, 39.2904] },
  '075': { name: "Phoenix", coordinates: [-112.0740, 33.4484] },
  '076': { name: "Bridgeport", coordinates: [-73.1952, 41.1792] },
  '077': { name: "Memphis", coordinates: [-90.0490, 35.1495] },
  '078': { name: "Nashville", coordinates: [-86.7816, 36.1627] },
  '079': { name: "Tampa", coordinates: [-82.4572, 27.9506] },
  '080': { name: "San Antonio", coordinates: [-98.4936, 29.4241] },
  '081': { name: "Portsmouth", coordinates: [-70.7625, 43.0718] },
  '082': { name: "Mobile", coordinates: [-88.0399, 30.6954] },
  '083': { name: "Huntsville", coordinates: [-86.5861, 34.7304] },
  '084': { name: "Houston", coordinates: [-95.3698, 29.7604] },
  '085': { name: "Everett", coordinates: [-122.2020, 47.9790] },
  '086': { name: "Tacoma", coordinates: [-122.4400, 47.2529] },
  '087': { name: "Lake Charles", coordinates: [-93.2174, 30.2266] },
  '088': { name: "Great Falls", coordinates: [-111.3008, 47.5002] },
  '089': { name: "Clark County", coordinates: [-115.1739, 36.1699] },
  '090': { name: "Onondaga County", coordinates: [-76.1474, 43.0062] },
  '092': { name: "Harrison County", coordinates: [-89.0928, 30.3968] },
  '093': { name: "Raleigh-Durham", coordinates: [-78.7851, 35.8436] },
  '094': { name: "Laredo", coordinates: [-99.5076, 27.5306] },
  '095': { name: "Starr County", coordinates: [-98.7187, 26.5098] },
  '096': { name: "Eagle Pass", coordinates: [-100.4990, 28.7091] },
  '098': { name: "Birmingham", coordinates: [-86.8025, 33.5186] },
  '099': { name: "Wilmington", coordinates: [-75.5398, 39.7447] },
  '100': { name: "Dayton", coordinates: [-84.1916, 39.7589] },
  '101': { name: "Clinton County, OH", coordinates: [-83.8289, 39.4020] },
  '102': { name: "St. Louis", coordinates: [-90.1994, 38.6270] },
  '103': { name: "Grand Forks", coordinates: [-97.0329, 47.9253] },
  '104': { name: "Savannah", coordinates: [-81.0912, 32.0809] },
  '105': { name: "Providence", coordinates: [-71.4128, 41.8240] },
  '106': { name: "Oklahoma City", coordinates: [-97.5164, 35.4676] },
  '107': { name: "Polk County, IA", coordinates: [-93.5697, 41.6805] },
  '108': { name: "Valdez", coordinates: [-146.3483, 61.1308] },
  '109': { name: "Watertown", coordinates: [-75.9108, 43.9748] },
  '110': { name: "Albuquerque", coordinates: [-106.6504, 35.0844] },
  '112': { name: "El Paso County, CO", coordinates: [-104.5242, 38.8339] },
  '113': { name: "Ellis County", coordinates: [-96.7970, 32.3490] },
  '114': { name: "Peoria", coordinates: [-89.5890, 40.6936] },
  '115': { name: "Beaumont", coordinates: [-94.1018, 30.0802] },
  '116': { name: "Port Arthur", coordinates: [-93.9399, 29.8849] },
  '117': { name: "Orange", coordinates: [-93.7366, 30.0929] },
  '118': { name: "Ogdensburg", coordinates: [-75.4863, 44.6945] },
  '119': { name: "Minneapolis-St. Paul", coordinates: [-93.2650, 44.9778] },
  '120': { name: "Cowlitz County", coordinates: [-122.6784, 46.1254] },
  '121': { name: "Albany", coordinates: [-73.7562, 42.6526] },
  '122': { name: "Corpus Christi", coordinates: [-97.3964, 27.8006] },
  '123': { name: "Denver", coordinates: [-104.9903, 39.7392] },
  '124': { name: "Gramercy", coordinates: [-90.6920, 30.0588] },
  '125': { name: "South Bend", coordinates: [-86.2520, 41.6764] },
  '126': { name: "Reno", coordinates: [-119.8138, 39.5296] },
  '127': { name: "West Columbia", coordinates: [-81.0737, 33.9932] },
  '128': { name: "Whatcom County", coordinates: [-122.4787, 48.8547] },
  '129': { name: "Bellingham", coordinates: [-122.4787, 48.7519] },
  '132': { name: "Coos Bay", coordinates: [-124.2178, 43.3665] },
  '133': { name: "Quad Cities", coordinates: [-90.5776, 41.5236] },
  '134': { name: "Chattanooga", coordinates: [-85.3097, 35.0456] },
  '135': { name: "Palm Beach County", coordinates: [-80.0534, 26.7153] },
  '136': { name: "Brevard County", coordinates: [-80.7214, 28.2639] },
  '137': { name: "Washington Dulles", coordinates: [-77.4565, 38.9531] },
  '138': { name: "Franklin County, OH", coordinates: [-83.0007, 39.9612] },
  '139': { name: "Sierra Vista", coordinates: [-110.2772, 31.5455] },
  '140': { name: "Flint", coordinates: [-83.6874, 43.0125] },
  '141': { name: "Rochester", coordinates: [-77.6088, 43.1566] },
  '142': { name: "Salem/Millville", coordinates: [-75.3395, 39.4668] },
  '143': { name: "Sacramento", coordinates: [-121.4944, 38.5816] },
  '144': { name: "Brunswick", coordinates: [-81.4915, 31.1499] },
  '145': { name: "Shreveport", coordinates: [-93.7502, 32.5252] },
  '146': { name: "Lawrence County, IL", coordinates: [-87.7272, 38.7206] },
  '147': { name: "Berks County", coordinates: [-75.9280, 40.4183] },
  '148': { name: "Knoxville", coordinates: [-83.9207, 35.9606] },
  '149': { name: "Freeport", coordinates: [-95.3597, 28.9542] },
  '150': { name: "El Paso", coordinates: [-106.4850, 31.7619] },
  '151': { name: "Findlay", coordinates: [-83.6499, 41.0442] },
  '152': { name: "Burns Harbor", coordinates: [-87.1261, 41.6150] },
  '153': { name: "San Diego", coordinates: [-117.1611, 32.7157] },
  '154': { name: "Baton Rouge", coordinates: [-91.1403, 30.4515] },
  '155': { name: "Calhoun/Victoria Counties", coordinates: [-96.9216, 28.8526] },
  '156': { name: "Hidalgo County", coordinates: [-98.1837, 26.3967] },
  '157': { name: "Casper", coordinates: [-106.3125, 42.8666] },
  '158': { name: "Vicksburg/Jackson", coordinates: [-90.8782, 32.3527] },
  '160': { name: "Anchorage", coordinates: [-149.9003, 61.2181] },
  '161': { name: "Sedgwick County", coordinates: [-97.4131, 37.6872] },
  '162': { name: "New Haven", coordinates: [-72.9279, 41.3083] },
  '163': { name: "Ponce", coordinates: [-66.6141, 18.0111] },
  '164': { name: "Muskogee", coordinates: [-95.3698, 35.7478] },
  '165': { name: "Midland", coordinates: [-102.0779, 31.9973] },
  '166': { name: "Homestead", coordinates: [-80.4776, 25.4687] },
  '167': { name: "Brown County, WI", coordinates: [-88.0133, 44.5133] },
  '168': { name: "Dallas/Fort Worth", coordinates: [-97.0403, 32.8998] },
  '169': { name: "Manatee County", coordinates: [-82.4278, 27.4799] },
  '170': { name: "Clark County, IN", coordinates: [-85.7594, 38.4864] },
  '171': { name: "Liberty County", coordinates: [-81.7665, 31.8161] },
  '172': { name: "Oneida County", coordinates: [-75.4073, 43.2420] },
  '173': { name: "Grays Harbor", coordinates: [-123.8143, 46.9754] },
  '174': { name: "Tucson", coordinates: [-110.9265, 32.2226] },
  '175': { name: "Cedar Rapids", coordinates: [-91.6656, 41.9779] },
  '176': { name: "Rockford", coordinates: [-89.0940, 42.2711] },
  '177': { name: "Evansville", coordinates: [-87.5711, 37.9748] },
  '179': { name: "Madawaska", coordinates: [-68.3289, 47.3556] },
  '181': { name: "Akron/Canton", coordinates: [-81.5185, 41.0814] },
  '182': { name: "Fort Wayne", coordinates: [-85.1394, 41.0793] },
  '183': { name: "Austin", coordinates: [-97.7431, 30.2672] },
  '185': { name: "Culpeper County", coordinates: [-78.0092, 38.4732] },
  '186': { name: "Waterville", coordinates: [-69.6317, 44.5520] },
  '187': { name: "Toole County", coordinates: [-111.6914, 48.6510] },
  '189': { name: "Kent/Ottawa/Muskegon Counties", coordinates: [-85.6681, 43.0331] },
  '191': { name: "Palmdale", coordinates: [-118.1165, 34.5794] },
  '193': { name: "Pinellas County", coordinates: [-82.7379, 27.9064] },
  '195': { name: "Fairbanks", coordinates: [-147.7164, 64.8378] },
  '196': { name: "Fort Worth", coordinates: [-97.3208, 32.7555] },
  '197': { name: "Doña Ana County", coordinates: [-106.7636, 32.3199] },
  '198': { name: "Volusia County", coordinates: [-81.1637, 29.0280] },
  '199': { name: "Texas City", coordinates: [-94.9027, 29.3839] },
  '200': { name: "Mercer County, NJ", coordinates: [-74.6592, 40.2862] },
  '201': { name: "Holyoke", coordinates: [-72.6162, 42.2042] },
  '202': { name: "Los Angeles", coordinates: [-118.2437, 34.0522] },
  '203': { name: "Moses Lake", coordinates: [-119.2780, 47.1301] },
  '204': { name: "Tri-Cities", coordinates: [-82.5618, 36.5487] },
  '205': { name: "Port Hueneme", coordinates: [-119.2026, 34.1478] },
  '207': { name: "Richmond", coordinates: [-77.4360, 37.5407] },
  '208': { name: "New London", coordinates: [-72.0995, 41.3557] },
  '210': { name: "St. Clair County", coordinates: [-82.6707, 42.9619] },
  '212': { name: "Tacoma", coordinates: [-122.4400, 47.2529] },
  '213': { name: "Fort Myers", coordinates: [-81.8723, 26.6406] },
  '214': { name: "Lenoir County", coordinates: [-77.5814, 35.2407] },
  '215': { name: "Sebring", coordinates: [-81.4409, 27.4956] },
  '216': { name: "Olympia", coordinates: [-122.9007, 47.0379] },
  '218': { name: "St. Lucie County", coordinates: [-80.3481, 27.3742] },
  '219': { name: "Yuma", coordinates: [-114.6508, 32.6927] },
  '220': { name: "Sioux Falls", coordinates: [-96.7317, 43.5446] },
  '221': { name: "Mesa", coordinates: [-111.8315, 33.4152] },
  '222': { name: "Montgomery", coordinates: [-86.2996, 32.3668] },
  '223': { name: "Memphis", coordinates: [-90.0490, 35.1495] },
  '224': { name: "Spokane", coordinates: [-117.4260, 47.6588] },
  '225': { name: "Springfield, MO", coordinates: [-93.2920, 37.2090] },
  '226': { name: "Merced", coordinates: [-120.4829, 37.3022] },
  '227': { name: "Durant", coordinates: [-96.3708, 33.9943] },
  '229': { name: "Charleston, WV", coordinates: [-81.6326, 38.3498] },
  '230': { name: "Piedmont Triad Area", coordinates: [-80.0053, 36.0726] },
  '231': { name: "Stockton", coordinates: [-121.2908, 37.9577] },
  '232': { name: "Kodiak", coordinates: [-152.4072, 57.7900] },
  '233': { name: "Dothan", coordinates: [-85.3905, 31.2232] },
  '234': { name: "Gregg County", coordinates: [-94.7690, 32.4280] },
  '235': { name: "Lakewood", coordinates: [-74.2179, 40.0968] },
  '236': { name: "Palm Springs", coordinates: [-116.5453, 33.8303] },
  '238': { name: "Dublin", coordinates: [-80.6848, 37.1713] },
  '240': { name: "Martinsburg", coordinates: [-77.9639, 39.4562] },
  '241': { name: "Fort Lauderdale", coordinates: [-80.1373, 26.1224] },
  '242': { name: "Boundary County", coordinates: [-116.3132, 48.7752] },
  '243': { name: "Victorville", coordinates: [-117.2928, 34.5362] },
  '244': { name: "Riverside County", coordinates: [-116.0765, 33.7405] },
  '245': { name: "Decatur", coordinates: [-88.9548, 39.8403] },
  '246': { name: "Waco", coordinates: [-97.1467, 31.5493] },
  '247': { name: "Erie", coordinates: [-80.0852, 42.1292] },
  '248': { name: "Eureka", coordinates: [-124.1637, 40.8021] },
  '249': { name: "Pensacola", coordinates: [-87.2169, 30.4213] },
  '250': { name: "Seminole County", coordinates: [-81.3124, 28.7131] },
  '252': { name: "Amarillo", coordinates: [-101.8313, 35.2220] },
  '254': { name: "Jefferson County, PA", coordinates: [-78.9959, 41.1292] },
  '255': { name: "Washington County, MD", coordinates: [-77.7199, 39.6418] },
  '257': { name: "Imperial County", coordinates: [-115.5682, 33.0408] },
  '258': { name: "Bowie County", coordinates: [-94.1269, 33.4457] },
  '259': { name: "Koochiching County", coordinates: [-93.6914, 48.2450] },
  '260': { name: "Lubbock", coordinates: [-101.8552, 33.5779] },
  '261': { name: "Alexandria", coordinates: [-92.4451, 31.3113] },
  '262': { name: "Southaven", coordinates: [-90.0023, 34.9890] },
  '265': { name: "Conroe", coordinates: [-95.4556, 30.3119] },
  '266': { name: "Dane County", coordinates: [-89.4012, 43.0731] },
  '267': { name: "Fargo", coordinates: [-96.7898, 46.8772] },
  '268': { name: "Brattleboro", coordinates: [-72.5579, 42.8509] },
  '269': { name: "Athens", coordinates: [-95.8555, 32.2049] },
  '270': { name: "Lawrence County, OH", coordinates: [-82.5154, 38.5962] },
  '271': { name: "Jo-Daviess & Carroll Counties", coordinates: [-90.1640, 42.3614] },
  '272': { name: "Lehigh Valley", coordinates: [-75.4714, 40.6084] },
  '273': { name: "West Memphis", coordinates: [-90.1848, 35.1465] },
  '274': { name: "Butte-Silver Bow", coordinates: [-112.5347, 45.9838] },
  '275': { name: "Lansing", coordinates: [-84.5555, 42.7325] },
  '276': { name: "Kern County", coordinates: [-118.7167, 35.3733] },
  '277': { name: "Western Maricopa County", coordinates: [-112.3916, 33.5387] },
  '279': { name: "Terrebonne Parish", coordinates: [-90.7754, 29.5981] },
  '280': { name: "Caldwell, Idaho", coordinates: [-116.6874, 43.6629] },
  '281': { name: "Miami-Dade County", coordinates: [-80.1918, 25.7617] },
  '283': { name: "West Tennessee Area", coordinates: [-89.3985, 35.6145] },
  '284': { name: "Genesee County", coordinates: [-78.1903, 43.0067] },
  '287': { name: "Tunica County", coordinates: [-90.3829, 34.6515] },
  '288': { name: "Northwest Iowa", coordinates: [-95.6236, 43.0383] },
  '289': { name: "Ontario County", coordinates: [-77.3069, 42.8564] },
  '291': { name: "Cameron Parish", coordinates: [-93.3251, 29.7977] },
  '294': { name: "Western Kentucky", coordinates: [-88.6378, 37.0533] },
  '295': { name: "Central Pennsylvania", coordinates: [-78.3697, 40.9126] },
  '296': { name: "Vancouver, Washington", coordinates: [-122.6615, 45.6387] },
  '297': { name: "Lufkin", coordinates: [-94.7291, 31.3382] },
  '298': { name: "Jefferson County, CO", coordinates: [-105.2002, 39.5858] },
  '299': { name: "Smith County", coordinates: [-95.2610, 32.3513] },
  '300': { name: "Plaquemines Parish", coordinates: [-89.6170, 29.3240] },
  '301': { name: "Western North Carolina", coordinates: [-82.4429, 35.5951] },
  '302': { name: "Socorro", coordinates: [-106.3031, 31.6549] }
};