import React from 'react';
import { Box, Paper, Typography } from '@mui/material';
import ConstructionIcon from '@mui/icons-material/Construction';

interface ReclassificationTabProps {
  dates: any;
  page: number;
  dutySpendFilter: string;
  pageSize: number;
  setPage: React.Dispatch<React.SetStateAction<number>>;
}

const ReclassificationTab: React.FC<ReclassificationTabProps> = () => {
  return (
    <Box sx={{ 
      height: 'calc(100vh - 200px)',
      p: 3,
      display: 'flex',
      flexDirection: 'column'
    }}>
      <Paper sx={{ 
        width: '100%', 
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        gap: 2
      }}>
        <ConstructionIcon sx={{ fontSize: 60, color: 'text.secondary' }} />
        <Typography variant="h5" color="text.secondary">
          Currently in Development
        </Typography>
        <Typography variant="body1" color="text.secondary">
          This feature will be available soon.
        </Typography>
      </Paper>
    </Box>
  );
};

export default ReclassificationTab; 